import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaPhone, FaComments, FaClock } from 'react-icons/fa';
import './EstimateInquiry.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { sendFCMNotification } from '../../services/fcm';
import { trackMetaEvent } from '../../utils/metaPixel';

function EstimateInquiry() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    budget: '',
    address: '',
    question: ''
  });

  const handlePhoneCall = () => {
    window.location.href = 'tel:01086944078';
  };

  const handleSMS = () => {
    window.location.href = 'sms:01086944078';
  };

  const handleEstimate = () => {
    navigate('/estimate', {
      state: { 
        returnPath: location.pathname,
        returnParams: { id }
      },
      replace: true
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.name.trim()) {
      alert('성함을 입력해주세요.');
      return;
    }
    
    if (!formData.phone.trim()) {
      alert('연락처를 입력해주세요.');
      return;
    }
    
    if (!id && !formData.email.trim()) {
      alert('이메일을 입력해주세요.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "estimateInquiries"), {
        ...formData,
        estimateId: id || null,
        status: '신규',
        source: 'react',
        createdAt: serverTimestamp(),
        date: new Date().toISOString()
      });

      await sendFCMNotification({
        title: "인테리어 견적 문의",
        body: `${formData.name}님의 신규 문의가 등록되었습니다.`,
        data: {
          type: "new_inquiry",
          inquiry_id: docRef.id,
          source: "react"
        }
      });

      alert('문의가 접수되었습니다.');
      setFormData({
        name: '',
        email: '',
        phone: '',
        budget: '',
        address: '',
        question: ''
      });
      
      window.location.href = 'https://amaredesign.kr/';

      trackMetaEvent('SubmitInquiry', {
        hasEstimateId: !!id
      });
    } catch {
      alert('문의 접수 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="estimate-inquiry">
      <h1>견적 문의</h1>
      
      <div className="action-buttons">
        <button className="circle-button" onClick={handlePhoneCall}>
          <FaPhone className="button-icon" />
          <span>전화</span>
        </button>
        <button className="circle-button" onClick={handleSMS}>
          <FaComments className="button-icon" />
          <span>문자</span>
        </button>
        <button className="circle-button" onClick={handleEstimate}>
          <FaClock className="button-icon" />
          <span>1분견적</span>
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">성함 *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="성함을 입력해주세요"
            required
          />
        </div>

        {!id && (
          <div className="form-group">
            <label htmlFor="email">이메일 *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="이메일을 입력해주세요"
              required
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="phone">연락처 *</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="연락처를 입력해주세요"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="budget">대략적인 예산</label>
          <input
            type="text"
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            placeholder="예산을 입력해주세요"
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">주소</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="주소를 입력해주세요"
          />
        </div>

        <div className="form-group">
          <label htmlFor="question">궁금하신 점</label>
          <textarea
            id="question"
            name="question"
            value={formData.question}
            onChange={handleChange}
            placeholder="궁금하신 점을 입력해주세요"
            rows="4"
          />
        </div>

        <button type="submit" className="submit-button">
          문의하기
        </button>
      </form>
    </div>
  );
}

export default EstimateInquiry; 