import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, deleteDoc, doc, orderBy, query } from 'firebase/firestore';
import { FaTrash } from 'react-icons/fa';
import './AllTemplatesModal.css';

const desktopProcesses = {
  design: '디자인',
  demolition: '철거폐기물',
  window: '창호금속',
  plumbing: '배관 공사',
  carpentry: '목공사',
  door: '문공사',
  tile: '타일 공사',
  film: '필름공사',
  floor_protection: '바닥 보양 공사',
  wood_flooring: '바닥재 공사',
  floor_tile: '바닥 타일 공사',
  painting: '도자공사',
  wallpaper: '도배공사',
  bathroom: '욕실시공',
  hardware: '하드웨어, 악세서리',
  electrical: '전기공사',
  furniture: '가구공사',
  silicon: '실리콘 공사',
  ac: '에어컨공사',
  supervision_utilities: '현장감리/공과잡비',
  misc_additional: '기타잡비/별도공사'
};

const AllTemplatesModal = ({ onClose }) => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAllTemplates, setShowAllTemplates] = useState(true);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    try {
      setIsLoading(true);
      const templatesQuery = query(
        collection(db, 'ai-templates'),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(templatesQuery);
      const loadedTemplates = [];
      querySnapshot.forEach((doc) => {
        loadedTemplates.push({ id: doc.id, ...doc.data() });
      });
      setTemplates(loadedTemplates);
    } catch (error) {
      console.error('템플릿 로드 중 오류:', error);
      alert('템플릿을 불러오는 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (template) => {
    setSelectedTemplate(template);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, 'ai-templates', selectedTemplate.id));
      setTemplates(templates.filter(t => t.id !== selectedTemplate.id));
      setShowDeleteConfirm(false);
      alert('템플릿이 삭제되었습니다.');
    } catch (error) {
      console.error('템플릿 삭제 중 오류:', error);
      alert('템플릿 삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="all-templates-modal-overlay">
      <div className="all-templates-modal">
        <div className="modal-header">
          <h3>전체 템플릿 관리</h3>
        </div>

        <div className="modal-content">
          {showDeleteConfirm && (
            <div className="delete-confirm">
              <div className="delete-confirm-content">
                <p>정말 삭제하시겠습니까?</p>
                <div className="delete-confirm-buttons">
                  <button 
                    className="cancel-button"
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    취소
                  </button>
                  <button 
                    className="confirm-button"
                    onClick={handleDeleteConfirm}
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="loading">로딩 중...</div>
          ) : (
            <div className="templates-list">
              {templates.map((template) => (
                <div key={template.id} className="template-item">
                  <div className="template-header">
                    <div className="header-left">
                      <span className="process-name">{template.processName}</span>
                      <span className="created-date">
                        {new Date(template.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  
                  <div className="template-details">
                    <div className="process-name">
                      {desktopProcesses[template.processId] || template.processId}
                    </div>
                    <div className="detail-row">
                      <span className="label">요구사항:</span>
                      <span className="value">{template.userInput?.requirement}</span>
                    </div>
                    <div className="detail-row">
                      <span className="label">예산:</span>
                      <span className="value">{template.userInput?.budget}</span>
                    </div>
                    {template.userInput?.keywords && (
                      <div className="keywords">
                        {template.userInput.keywords.split(',').map((keyword, idx) => (
                          <span key={idx} className="keyword-tag">
                            {keyword.trim()}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="template-footer">
                    <span className="use-count">
                      사용 {template.useCount || 0}회
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllTemplatesModal; 