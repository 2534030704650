import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DeleteUserData = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // 여기에 실제 데이터 삭제 로직을 구현하세요
    setMessage('데이터 삭제 요청이 접수되었습니다. 처리 후 이메일로 연락 드리겠습니다.');
    setEmail('');
  };

  return (
    <div className="delete-user-data">
      <h1>사용자 데이터 삭제 요청</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="이메일 주소를 입력하세요"
          required
        />
        <button type="submit">데이터 삭제 요청</button>
      </form>
      {message && <p className="message">{message}</p>}
      <Link to="/">홈으로 돌아가기</Link>
    </div>
  );
};

export default DeleteUserData;
