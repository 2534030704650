import React, { useState, useEffect } from 'react';
import './SelectVersionModal.css';

const SelectVersionModal = ({ versions, onSelectVersion, onNewVersion, onClose }) => {
  const [selectedVersionId, setSelectedVersionId] = useState('');
  const [newVersionName, setNewVersionName] = useState('');
  const [mode, setMode] = useState('select');

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return '날짜 없음';
      }
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return '날짜 없음';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (mode === 'select' && selectedVersionId) {
      const selectedVersion = versions.find(v => v.versionId === selectedVersionId);
      onSelectVersion(selectedVersion);
    } else if (mode === 'new' && newVersionName.trim()) {
      onNewVersion(newVersionName.trim());
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  return (
    <div className="select-version-modal-overlay">
      <div className="select-version-modal">
        <h3>수정 버전 선택</h3>
        
        <div className="version-mode-buttons">
          <button 
            className={`mode-button ${mode === 'select' ? 'active' : ''}`}
            onClick={() => setMode('select')}
          >
            기존 버전 선택
          </button>
          <button 
            className={`mode-button ${mode === 'new' ? 'active' : ''}`}
            onClick={() => setMode('new')}
          >
            새 버전 만들기
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          {mode === 'select' ? (
            <div className="version-list">
              {versions.length > 0 ? (
                versions.map((version) => (
                  <label key={version.versionId} className="version-item">
                    <input
                      type="radio"
                      name="version"
                      value={version.versionId}
                      checked={selectedVersionId === version.versionId}
                      onChange={(e) => setSelectedVersionId(e.target.value)}
                    />
                    <div className="version-info">
                      <span className="version-name">{version.versionName}</span>
                      <span className="version-date">
                        {formatDate(version.createdAt || version.lastModified)}
                      </span>
                    </div>
                  </label>
                ))
              ) : (
                <p className="no-versions">기존 버전이 없습니다.</p>
              )}
            </div>
          ) : (
            <div className="new-version-input">
              <input
                type="text"
                value={newVersionName}
                onChange={(e) => setNewVersionName(e.target.value)}
                placeholder="새 버전명 입력"
                autoFocus
              />
            </div>
          )}

          <div className="modal-buttons">
            <button 
              type="submit" 
              className="confirm-button"
              disabled={mode === 'select' ? !selectedVersionId : !newVersionName.trim()}
            >
              {mode === 'select' ? '선택 완료' : '새 버전 시작'}
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              취소
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SelectVersionModal; 