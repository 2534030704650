import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './OngoingAddressesToggle.css';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';

// 모달 컴포넌트 수정
const Modal = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 1000);  // 2000에서 1000으로 변경

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content" tabIndex="0">
        <p>{message}</p>
      </div>
    </div>
  );
};

const OngoingAddressesToggle = ({ onSelectAddress, onAdminLogin }) => {
  const [completedAddresses, setCompletedAddresses] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompletedAddresses();
  }, []);

  const fetchCompletedAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      // 공사중인 현장만 필터링
      const q = query(addressesCollection, where("isCompleted", "==", true));
      const querySnapshot = await getDocs(q);
      
      // 데이터를 가져와서 배열로 변환
      const addresses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // siteNumber를 기준으로 내림차순 정렬
      const sortedAddresses = addresses.sort((a, b) => {
        // siteNumber가 없는 경우를 대비해 기본값 0 설정
        const siteNumberA = a.siteNumber || 0;
        const siteNumberB = b.siteNumber || 0;
        return siteNumberB - siteNumberA;  // 내림차순 정렬
      });

      setCompletedAddresses(sortedAddresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleAdminLogin = (e) => {
    e.preventDefault();
    if (adminPassword === '1234') {
      setModalMessage('관리자 모드로 전환되었습니다.');
      setShowModal(true);
      if (onAdminLogin) onAdminLogin(true);
    } else {
      setModalMessage('비밀번호가 올바르지 않습니다.');
      setShowModal(true);
    }
    setAdminPassword('');  // 비밀번호 입력 필드 초기화
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAdminLogin(e);
    }
  };

  const handleAddressClick = async (address) => {
    if (selectedAddress && selectedAddress.id === address.id) {
      setSelectedAddress(null);
      setIsExpanded(true);
      sessionStorage.removeItem('selectedAddressId');
      sessionStorage.removeItem('selectedAddressDescription');
      if (onSelectAddress) onSelectAddress(null, null);
    } else {
      setSelectedAddress(address);
      setIsExpanded(false);
      sessionStorage.setItem('selectedAddressId', address.id);
      sessionStorage.setItem('selectedAddressDescription', address.description);

      try {
        const paymentDocRef = doc(db, 'payments', address.id);
        const paymentDocSnap = await getDoc(paymentDocRef);
        
        if (paymentDocSnap.exists()) {
          const paymentData = paymentDocSnap.data();
          if (onSelectAddress) onSelectAddress(address.id, paymentData);
        } else {
          if (onSelectAddress) onSelectAddress(address.id, null);
        }
      } catch (error) {
        console.error("Error fetching payment data: ", error);
        if (onSelectAddress) onSelectAddress(address.id, null);
      }
    }
  };

  return (
    <div className="ongoing-addresses-page">
      <button className="back-button" onClick={handleBack}>
        &#8592; 뒤로가기
      </button>
      <div className="content">
        <form onSubmit={handleAdminLogin}>
          <div className="admin-controls-wrapper">
            <input
              type="password"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="관리자 비밀번호"
              className="admin-password-input"
            />
            <button type="submit" className="admin-mode-button">관리자 모드</button>
          </div>
        </form>
        <div className="ongoing-addresses-container">
          <div className="ongoing-addresses-header" onClick={toggleExpand}>
            <h2>공사중 현장 ({completedAddresses.length})</h2>
            <span className="toggle-icon">{isExpanded ? '▲' : '▼'}</span>
          </div>
        </div>
        {(isExpanded || selectedAddress) && (
          <div className="address-list">
            {completedAddresses.length > 0 ? (
              (isExpanded ? completedAddresses : [selectedAddress]).map((address) => (
                <div 
                  key={address.id} 
                  className={`address-item ${selectedAddress && selectedAddress.id === address.id ? 'selected' : ''}`}
                  onClick={() => handleAddressClick(address)}
                >
                  <span>{address.description}</span>
                </div>
              ))
            ) : (
              <div className="no-addresses">현재 공사중인 현장이 없습니다.</div>
            )}
          </div>
        )}
      </div>
      {showModal && (
        <Modal 
          message={modalMessage} 
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default OngoingAddressesToggle;
