import React from 'react';
import './HideProcessButton.css';

const HideProcessButton = ({ processId, isHidden = false, onToggle }) => {
  return (
    <button 
      className={`hide-process-button ${isHidden ? 'hidden' : ''}`}
      title={isHidden ? '공정 보이기' : '공정 숨기기'}
      onClick={() => onToggle(processId)}
    >
      {isHidden ? '🔍' : '👁️'}
    </button>
  );
};

export default HideProcessButton; 