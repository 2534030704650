import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { saveAreaPrices, getAreaPrices, saveProcesses, getProcesses, getEstimates, deleteEstimates } from '../../firebase';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import './AdminPage.css';
import EstimateDetailModal from './EstimateDetailModal';

const AdminPage = () => {
  const [area, setArea] = useState('20평대');
  const [prices, setPrices] = useState({});
  const [message, setMessage] = useState('');
  const [processes, setProcesses] = useState([]);
  const [newProcess, setNewProcess] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [estimates, setEstimates] = useState([]);
  const [selectedEstimates, setSelectedEstimates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [activeTab, setActiveTab] = useState('process');
  const [processMapping, setProcessMapping] = useState({});
  const [newMappingItem, setNewMappingItem] = useState('');
  const [selectedProcess, setSelectedProcess] = useState('');
  const [selectedDesktopProcess, setSelectedDesktopProcess] = useState('');
  const [editingProcessIndex, setEditingProcessIndex] = useState(null);
  const [editedProcessName, setEditedProcessName] = useState('');
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  const areas = ['20평대', '30평대', '40평대', '50평대'];
  const desktopProcesses = {
    design: '디자인',
    demolition: '철거폐기물',
    window: '창호금속',
    plumbing: '배관 공사',
    carpentry: '목공사',
    door: '문공사',
    tile: '타일 공사',
    film: '필름공사',
    floor_protection: '바닥 보양 공사',
    wood_flooring: '바닥재 공사',
    floor_tile: '바닥 타일 공사',
    painting: '도장공사',
    wallpaper: '도배공사',
    bathroom: '욕실시공',
    hardware: '하드웨어, 악세서리',
    electrical: '전기공사',
    furniture: '가구공사',
    silicon: '실리콘 공사',
    ac: '에어컨공사',
    supervision_utilities: '현장감리/공과잡비',
    misc_additional: '기타잡비/별도공사'
  };

  useEffect(() => {
    loadPrices(area);
    loadProcesses();
    loadEstimates();
    const loadMappings = async () => {
      const mappingDoc = await getDoc(doc(db, 'process-mappings', 'default'));
      if (mappingDoc.exists()) {
        setProcessMapping(mappingDoc.data());
      }
    };
    
    loadMappings();
  }, [area]);

  useEffect(() => {
    const initializeData = async () => {
      const processes = await getProcesses();
      if (!processes || processes.length === 0) {
        // 기본 공정 데이터 설정
        const defaultProcesses = [
          '철거',
          '도배',
          '장판',
          '주방',
          '욕실'
          // ... 기타 기본 공정들
        ];
        await saveProcesses(defaultProcesses);
        setProcesses(defaultProcesses);
      }
    };
    
    initializeData();
  }, []); // 컴포넌트 마운트시 1회만 실행

  useEffect(() => {
    // area-prices 컬렉션의 해당 평수 문서를 실시간으로 감시
    const unsubscribe = onSnapshot(
      doc(db, 'area-prices', area), 
      (snapshot) => {
        if (snapshot.exists()) {
          const areaPrices = snapshot.data();
          const formattedPrices = {};
          Object.entries(areaPrices || {}).forEach(([key, value]) => {
            formattedPrices[key] = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
          });
          setPrices(formattedPrices);
          
          // 변경 감지시 성공 메시지 표시
          setMessage('가격이 업데이트되었습니다.');
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
            setMessage('');
          }, 1500);
        }
      },
      (error) => {
        console.error("실시간 가격 업데이트 중 오류:", error);
      }
    );

    return () => unsubscribe();
  }, [area]);

  const loadPrices = async (selectedArea) => {
    try {
      const areaPrices = await getAreaPrices(selectedArea);
      const formattedPrices = {};
      Object.entries(areaPrices || {}).forEach(([key, value]) => {
        formattedPrices[key] = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
      });
      setPrices(formattedPrices);
    } catch (error) {
      console.error("가격 로딩 중 오류:", error);
    }
  };

  const loadProcesses = async () => {
    try {
      const fetchedProcesses = await getProcesses();
      if (fetchedProcesses && Array.isArray(fetchedProcesses)) {
        setProcesses(fetchedProcesses);
      } else {
        console.error('Processes data is not an array:', fetchedProcesses);
        setProcesses([]); // 유효하지 않은 데이터일 경우 빈 배열로 설정
      }
    } catch (error) {
      console.error('Error loading processes:', error);
      setProcesses([]); // 에러 발생시 빈 배열로 설정
    }
  };

  const loadEstimates = async () => {
    const fetchedEstimates = await getEstimates();
    const sortedEstimates = fetchedEstimates.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setEstimates(sortedEstimates);
  };

  const handlePriceChange = (process, value) => {
    const formattedValue = /^\d+$/.test(value.replace(/,/g, ''))
      ? formatNumber(value.replace(/,/g, ''))
      : value;
    
    setPrices(prev => ({ ...prev, [process]: formattedValue }));
  };

  const formatNumber = (value) => {
    if (!value) return '';
    if (/^\d+$/.test(value.replace(/,/g, ''))) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const processedPrices = {};
      Object.entries(prices).forEach(([key, value]) => {
        const cleanValue = value.replace(/,/g, '');
        processedPrices[key] = /^\d+$/.test(cleanValue) 
          ? parseInt(cleanValue, 10) 
          : value;
      });
      
      await saveAreaPrices(area, processedPrices);
      
      // 저장 성공 메시지 표시
      setMessage('가격이 성공적으로 저장되었습니다.');
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        setMessage('');
      }, 2000); // 2초 후 메시지 사라짐
      
    } catch (error) {
      console.error("가격 저장 중 오류:", error);
      setMessage('가격 저장 중 오류가 발생했습니다.');
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        setMessage('');
      }, 2000);
    }
  };

  const handleAddProcess = async () => {
    if (newProcess && !processes.includes(newProcess)) {
      const updatedProcesses = [...processes, newProcess];
      await saveProcesses(updatedProcesses);
      setProcesses(updatedProcesses);
      setNewProcess('');
    }
  };

  const handleRemoveProcess = async (processToRemove) => {
    if (window.confirm(`정말로 "${processToRemove}" 공정을 삭제하시겠습니까?`)) {
      const updatedProcesses = processes.filter(process => process !== processToRemove);
      await saveProcesses(updatedProcesses);
      setProcesses(updatedProcesses);
    }
  };

  const moveProcess = async (index, direction) => {
    const newProcesses = [...processes];
    const temp = newProcesses[index];
    newProcesses[index] = newProcesses[index + direction];
    newProcesses[index + direction] = temp;
    await saveProcesses(newProcesses);
    setProcesses(newProcesses);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedEstimates(estimates.map(est => est.id));
    } else {
      setSelectedEstimates([]);
    }
  };

  const handleEstimateSelect = (id) => {
    setSelectedEstimates(prev => {
      const newSelected = prev.includes(id)
        ? prev.filter(estId => estId !== id)
        : [...prev, id];
      setSelectAll(newSelected.length === estimates.length);
      return newSelected;
    });
  };

  const handleDeleteSelectedEstimates = async () => {
    if (window.confirm(`선택한 ${selectedEstimates.length}개의 견적서를 삭제하시겠습니까?`)) {
      await deleteEstimates(selectedEstimates);
      setEstimates(prev => prev.filter(est => !selectedEstimates.includes(est.id)));
      setSelectedEstimates([]);
      setMessage('선택한 견적서가 삭제되었습니다.');
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        setMessage('');
      }, 1500);
    }
  };

  const handleAddMapping = async () => {
    try {
      if (!selectedDesktopProcess || !selectedProcess) {
        alert('두 공정을 모두 선택해주세요.');
        return;
      }
      
      const newMapping = {
        ...processMapping,
        [selectedDesktopProcess]: selectedProcess
      };
      
      await setDoc(doc(db, 'process-mappings', 'default'), newMapping);
      setProcessMapping(newMapping);
      setSelectedDesktopProcess('');
      setSelectedProcess('');
      
    } catch (error) {
      console.error('매핑 추가 중 오류:', error);
      alert('매핑 추가 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteMapping = async (item) => {
    try {
      const newMapping = { ...processMapping };
      delete newMapping[item];
      
      await setDoc(doc(db, 'process-mappings', 'default'), newMapping);
      setProcessMapping(newMapping);
      
    } catch (error) {
      console.error('매핑 삭제 중 오류:', error);
      alert('매핑 삭제 중 오류가 발생했습니다.');
    }
  };

  const handleProcessDoubleClick = (index, processName) => {
    setEditingProcessIndex(index);
    setEditedProcessName(processName);
  };

  const handleProcessEditComplete = async (index) => {
    try {
      if (editedProcessName.trim() && editedProcessName !== processes[index]) {
        const updatedProcesses = [...processes];
        updatedProcesses[index] = editedProcessName.trim();
        await saveProcesses(updatedProcesses);
        setProcesses(updatedProcesses);
      }
      setEditingProcessIndex(null);
      setEditedProcessName('');
    } catch (error) {
      console.error('공정명 수정 중 오류:', error);
      alert('공정명 수정 중 오류가 발생했습니다.');
    }
  };

  const handleProcessEditCancel = () => {
    setEditingProcessIndex(null);
    setEditedProcessName('');
  };

  const handleRowClick = (estimate) => {
    setSelectedEstimate(estimate);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsSelectionMode(true);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    setIsSelectionMode(false);
  };

  const handleSelectionModeToggle = () => {
    setIsSelectionMode(!isSelectionMode);
    if (isSelectionMode) {
      // 선택 모드 해제시 선택된 항목들 초기화
      setSelectedEstimates([]);
    }
  };

  return (
    <div className="admin-page">
      <h1 className="admin-title">관리자 페이지</h1>

      <div className="admin-tabs">
        <button 
          className={`tab-button ${activeTab === 'process' ? 'active' : ''}`}
          onClick={() => setActiveTab('process')}
        >
          공정 관리
        </button>
        <button 
          className={`tab-button ${activeTab === 'price' ? 'active' : ''}`}
          onClick={() => setActiveTab('price')}
        >
          가격 관리
        </button>
        <button 
          className={`tab-button ${activeTab === 'estimates' ? 'active' : ''}`}
          onClick={() => setActiveTab('estimates')}
        >
          견적서 관리
        </button>
        <button 
          className={`tab-button ${activeTab === 'mapping' ? 'active' : ''}`}
          onClick={() => setActiveTab('mapping')}
        >
          공정 매핑
        </button>
      </div>

      <div className="admin-content">
        {activeTab === 'process' && (
          <div className="content-card">
            <h2 className="card-title">공정 관리</h2>
            <div className="process-management">
              <div className="input-group">
                <input
                  type="text"
                  value={newProcess}
                  onChange={(e) => setNewProcess(e.target.value)}
                  placeholder="새 공정 이름"
                  className="admin-input"
                />
                <button onClick={handleAddProcess} className="admin-button">
                  공정 추가
                </button>
              </div>
              <ul className="admin-process-list">
                {processes.map((process, index) => (
                  <li key={process} className="admin-process-item">
                    {editingProcessIndex === index ? (
                      <input
                        type="text"
                        value={editedProcessName}
                        onChange={(e) => setEditedProcessName(e.target.value)}
                        onBlur={() => handleProcessEditComplete(index)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleProcessEditComplete(index);
                          } else if (e.key === 'Escape') {
                            handleProcessEditCancel();
                          }
                        }}
                        autoFocus
                        className="admin-input"
                      />
                    ) : (
                      <span onDoubleClick={() => handleProcessDoubleClick(index, process)}>
                        {process}
                      </span>
                    )}
                    <div className="process-controls">
                      {index > 0 && (
                        <button onClick={() => moveProcess(index, -1)} className="control-button">↑</button>
                      )}
                      {index < processes.length - 1 && (
                        <button onClick={() => moveProcess(index, 1)} className="control-button">↓</button>
                      )}
                      <button onClick={() => handleRemoveProcess(process)} className="control-button delete">삭제</button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {activeTab === 'price' && (
          <div className="content-card">
            <h2 className="card-title">가격 관리</h2>
            <div className="select-wrapper">
              <select className="admin-select" value={area} onChange={(e) => setArea(e.target.value)}>
                {areas.map(a => <option key={a} value={a}>{a}</option>)}
              </select>
            </div>
            <form onSubmit={handleSubmit} className="price-form">
              {processes.map(process => (
                <div key={process} className="price-input-group">
                  <label>{process}</label>
                  <input
                    type="text"
                    value={formatNumber(prices[process] || '')}
                    onChange={(e) => handlePriceChange(process, e.target.value)}
                    className="price-input"
                  />
                </div>
              ))}
              <button type="submit" className="submit-button">저장</button>
            </form>
          </div>
        )}
        

        {activeTab === 'estimates' && (
          <div className="content-card">
            <h2 className="card-title">견적서 관리</h2>
            <div className="selection-controls">
              <button 
                className={`control-button ${isSelectionMode ? 'active' : ''}`}
                onClick={handleSelectionModeToggle}
              >
                {isSelectionMode ? '선택 모드 해제' : '선택 모드'}
              </button>
              {isSelectionMode && (
                <>
                  <button 
                    className="control-button select-all"
                    onClick={() => {
                      const allIds = estimates.map(est => est.id);
                      setSelectedEstimates(selectedEstimates.length === estimates.length ? [] : allIds);
                    }}
                  >
                    {selectedEstimates.length === estimates.length ? '전체 선택 해' : '전체 선택'}
                  </button>
                  <button 
                    className="control-button delete"
                    onClick={handleDeleteSelectedEstimates}
                    disabled={selectedEstimates.length === 0}
                  >
                    선택 삭제 ({selectedEstimates.length})
                  </button>
                </>
              )}
            </div>
            <div className="estimates-list">
              {estimates.map((estimate) => (
                <div 
                  key={estimate.id} 
                  className={`estimate-item ${selectedEstimates.includes(estimate.id) ? 'selected' : ''}`}
                  onClick={() => isSelectionMode ? handleEstimateSelect(estimate.id) : handleRowClick(estimate)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  <div className="name">{estimate.userInfo?.name || '정보 없음'}</div>
                  <div className="email">{estimate.userInfo?.email || '정보 없음'}</div>
                  <div className="date">
                    {estimate.createdAt ? 
                      new Date(estimate.createdAt.seconds * 1000).toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                      }).replace(/\./g, '.').replace(',', '') 
                      : '날짜 정보 없음'
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'mapping' && (
          <div className="content-card">
            <h2 className="card-title">공정 매핑 관리</h2>
            <div className="mapping-management">
              <div className="input-group">
                <select
                  value={selectedDesktopProcess}
                  onChange={(e) => setSelectedDesktopProcess(e.target.value)}
                  className="admin-select"
                >
                  <option value="">데스크탑 견적서 공정 선택</option>
                  {Object.entries(desktopProcesses).map(([key, name]) => (
                    <option key={key} value={key}>{name}</option>
                  ))}
                </select>
                <select
                  value={selectedProcess}
                  onChange={(e) => setSelectedProcess(e.target.value)}
                  className="admin-select"
                >
                  <option value="">1분견적 공정 선택</option>
                  {processes.map(process => (
                    <option key={process} value={process}>{process}</option>
                  ))}
                </select>
                <button 
                  onClick={handleAddMapping}
                  className="admin-button"
                  disabled={!selectedDesktopProcess || !selectedProcess}
                >
                  매핑 추가
                </button>
              </div>
              
              <div className="mapping-list">
                {Object.entries(processMapping).map(([desktopProcess, oneMinProcess]) => (
                  <div key={desktopProcess} className="mapping-item">
                    <span>{desktopProcesses[desktopProcess] || desktopProcess}</span>
                    <span>➔</span>
                    <span>{oneMinProcess}</span>
                    <button
                      onClick={() => handleDeleteMapping(desktopProcess)}
                      className="delete-button"
                    >
                      삭제
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {showMessage && (
        <div className="message-overlay">
          <div className="message-box">
            <p>{message}</p>
          </div>
        </div>
      )}

      {selectedEstimate && (
        <EstimateDetailModal 
          estimate={selectedEstimate}
          onClose={() => setSelectedEstimate(null)}
        />
      )}
    </div>
  );
};

export default AdminPage;
