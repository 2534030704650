import React from 'react';
import './EstimateApp.css';

const AreaSelection = ({ onSelect, selectedArea }) => {
  const areas = [20, 30, 40, 50];

  const handleAreaSelect = (area) => {
    onSelect(area);
  };

  return (
    <div className="area-selection">
      <div className="button-list">
        {areas.map(area => (
          <button 
            key={area} 
            onClick={() => handleAreaSelect(area)}
            className={`area-button ${selectedArea === area ? 'selected' : ''}`}
          >
            {area}평
          </button>
        ))}
      </div>
    </div>
  );
};

export default AreaSelection;
