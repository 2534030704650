import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import SavedItemsModal from './saved-items/SavedItemsModal';
import SaveItemMemoModal from './saved-items/SaveItemMemoModal';
import './EditItemModal.css';

const EditItemModal = ({ item, processId, onSave, onClose }) => {
  const [editedItem, setEditedItem] = useState({
    name: item.name || '',
    quantity: item.quantity || '',
    unitPrice: item.unitPrice || '',
    unit: item.unit || '',
    note: item.note || ''
  });

  const [isUndecided, setIsUndecided] = useState(item.note === '가격미정(확인필요)');
  const [isExcluded, setIsExcluded] = useState(item.note === '미포함');
  const [isSeparate, setIsSeparate] = useState(item.note === '별도 가능');
  const [isService, setIsService] = useState(item.note === '서비스');
  const [showSavedItems, setShowSavedItems] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);

  const handleCheckboxChange = (type) => {
    if (type === 'service') {
      setIsService(!isService);
      setIsUndecided(false);
      setIsExcluded(false);
      setIsSeparate(false);
      
      setEditedItem(prev => {
        if (!isService) {
          const originalPrice = prev.unitPrice;
          return {
            ...prev,
            note: `${formatNumber(originalPrice)}원 상당의 서비스`,
            unitPrice: '0',
            _originalPrice: originalPrice
          };
        } else {
          return {
            ...prev,
            note: '',
            unitPrice: prev._originalPrice || ''
          };
        }
      });
    } else {
      if (type === 'undecided') {
        setIsUndecided(!isUndecided);
        setIsExcluded(false);
        setIsSeparate(false);
        setIsService(false);
        
        setEditedItem(prev => {
          if (!isUndecided) {
            const currentNote = prev.note.replace(' (가격미정)', '').trim();
            return {
              ...prev,
              note: currentNote ? `${currentNote} (가격미정)` : '가격미정'
            };
          } else {
            const noteWithoutTag = prev.note.replace(' (가격미정)', '').replace('가격미정', '').trim();
            return {
              ...prev,
              note: noteWithoutTag
            };
          }
        });
      } else if (type === 'excluded') {
        setIsExcluded(!isExcluded);
        setIsUndecided(false);
        setIsSeparate(false);
        setIsService(false);
        setEditedItem(prev => ({
          ...prev,
          note: !isExcluded ? '미포함' : '',
          unitPrice: !isExcluded ? '0' : ''
        }));
      } else if (type === 'separate') {
        setIsSeparate(!isSeparate);
        setIsUndecided(false);
        setIsExcluded(false);
        setIsService(false);
        setEditedItem(prev => ({
          ...prev,
          note: !isSeparate ? '별도 가능' : ''
        }));
      }
    }
  };

  const handleNoteChange = (value) => {
    setEditedItem(prev => ({
      ...prev,
      note: value
    }));
    setIsUndecided(false);
    setIsExcluded(false);
    setIsSeparate(false);
  };

  const handleSave = () => {
    onSave({
      ...editedItem,
      quantity: parseInt(editedItem.quantity) || 0,
      unitPrice: parseInt(editedItem.unitPrice) || 0,
      uniqueId: item.uniqueId
    });
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleUnitPriceChange = (value) => {
    setEditedItem(prev => ({
      ...prev,
      unitPrice: value
    }));

    if (value && isExcluded) {
      setIsExcluded(false);
      setEditedItem(prev => ({
        ...prev,
        unitPrice: value,
        note: ''
      }));
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const handleSaveItem = async () => {
    try {
      if (!editedItem.name.trim() || !editedItem.unitPrice) {
        alert('품목명과 단가 입력해주세요.');
        return;
      }
      setShowMemoModal(true);
    } catch (error) {
      console.error('품목 저장 중 오류:', error);
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSaveWithMemo = async (itemWithMemo) => {
    try {
      console.group('💾 품목 저장');
      console.log('저장할 데이터:', {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      const docRef = await addDoc(collection(db, 'savedItems'), {
        processId,
        name: itemWithMemo.name,
        unit: itemWithMemo.unit,
        unitPrice: parseInt(itemWithMemo.unitPrice) || 0,
        note: itemWithMemo.note,
        memo: itemWithMemo.memo,
        createdAt: new Date().toISOString()
      });

      console.log('✅ 저장 완료. Document ID:', docRef.id);
      console.groupEnd();

      setShowMemoModal(false);
      alert('품목이 저장되었습니다.');
    } catch (error) {
      console.error('❌ 품목 저장 중 오류:', error);
      console.groupEnd();
      alert('품목 저장 중 오류가 발생했습니다.');
    }
  };

  const handleSelectSavedItem = (item) => {
    setEditedItem({
      ...editedItem,
      name: item.name,
      unit: item.unit,
      unitPrice: item.unitPrice.toString(),
      note: item.note || '',
      quantity: editedItem.quantity // 기존 수량 유지
    });
    setShowSavedItems(false);
  };

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <div className="edit-modal-content">
          <h3>항목 수정</h3>
          <div className="edit-field">
            <label>품목명</label>
            <input
              type="text"
              value={editedItem.name}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                name: e.target.value
              }))}
              placeholder="품목명 입력"
            />
          </div>
          <div className="edit-field">
            <label>수량</label>
            <input
              type="number"
              value={editedItem.quantity}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                quantity: e.target.value
              }))}
              placeholder="수량 입력"
            />
          </div>
          <div className="edit-field">
            <label>단위</label>
            <input
              type="text"
              value={editedItem.unit}
              onChange={(e) => setEditedItem(prev => ({
                ...prev,
                unit: e.target.value
              }))}
              placeholder="단위 입력"
            />
          </div>
          <div className="edit-field">
            <label>단가</label>
            <input
              type="number"
              value={editedItem.unitPrice}
              onChange={(e) => handleUnitPriceChange(e.target.value)}
              placeholder="단가 입력"
            />
          </div>
          <div className="checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isUndecided}
                onChange={() => handleCheckboxChange('undecided')}
              />
              가격미정
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isExcluded}
                onChange={() => handleCheckboxChange('excluded')}
              />
              미포함
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isSeparate}
                onChange={() => handleCheckboxChange('separate')}
              />
              별도 가능
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isService}
                onChange={() => handleCheckboxChange('service')}
              />
              서비스
            </label>
          </div>
          <div className="edit-field">
            <label>비고</label>
            <input
              type="text"
              value={editedItem.note}
              onChange={(e) => handleNoteChange(e.target.value)}
              placeholder="비고 입력"
            />
          </div>
          <div className="total-price">
            <label>합계:</label>
            <span>
              {formatNumber((parseInt(editedItem.quantity) || 0) * (parseInt(editedItem.unitPrice) || 0))}원
            </span>
          </div>
          <div className="item-management-buttons">
            <button onClick={() => setShowSavedItems(true)}>
              저장된 품목 검색
            </button>
            <button onClick={handleSaveItem}>
              현재 품목 저장
            </button>
          </div>
          <div className="edit-buttons">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>

      {showMemoModal && (
        <SaveItemMemoModal
          item={editedItem}
          onSave={handleSaveWithMemo}
          onClose={() => setShowMemoModal(false)}
        />
      )}

      {showSavedItems && (
        <SavedItemsModal
          processId={processId}
          onSelect={handleSelectSavedItem}
          onClose={() => setShowSavedItems(false)}
        />
      )}
    </div>
  );
};

export default EditItemModal; 