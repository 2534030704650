import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, addDoc, getDocs, query, where, updateDoc, doc, orderBy, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { saveAreaPrices, getAreaPrices } from '../../../firebase';
import AIPromptInput from './AIPromptInput';
import AIService from '../services/AIService';
import './AITemplateModal.css';
import AllTemplatesModal from './AllTemplatesModal';
import { useNavigate } from 'react-router-dom';

const AITemplateModal = ({ 
  processId, 
  processName, 
  currentItems, 
  onSave, 
  onClose 
}) => {
  const [templates, setTemplates] = useState([]);
  const [userInput, setUserInput] = useState({
    requirement: '',
    size: '',
    keywords: '',
    specialNotes: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [isItemsExpanded, setIsItemsExpanded] = useState(false);
  const [showAllTemplates, setShowAllTemplates] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [aiSearchQuery, setAiSearchQuery] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        if (showAllTemplates) {
          event.preventDefault();
          event.stopPropagation();
          setShowAllTemplates(false);
        }
      }
    };

    window.addEventListener('keydown', handleEscKey, true);

    return () => {
      window.removeEventListener('keydown', handleEscKey, true);
    };
  }, [showAllTemplates]);

  useEffect(() => {
    loadTemplates().then(templates => {
      setTemplates(templates);
    });
  }, []);

  const handleSearchInputChange = async (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    
    try {
      setIsLoading(true);
      const searchTemplates = await loadTemplates();
      
      if (!newSearchQuery.trim()) {
        setTemplates(searchTemplates);
        return;
      }

      const searchResults = searchTemplates.filter(template => {
        const searchLower = newSearchQuery.toLowerCase();
        return (
          template.processName?.toLowerCase().includes(searchLower) ||
          template.userInput?.requirement?.toLowerCase().includes(searchLower) ||
          template.userInput?.keywords?.toLowerCase().includes(searchLower) ||
          template.userInput?.specialNotes?.toLowerCase().includes(searchLower)
        );
      });

      setTemplates(searchResults);
    } catch (error) {
      console.error('검색 중 오류:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyTemplate = async (template) => {
    try {
      if (!template || !template.items) {
        alert('템플릿 데이터가 올바르지 않습니다.');
        return;
      }

      const isConfirmed = window.confirm(
        '템플릿을 적용하면 현재 입력된 데이터가 모두 초기화됩니다. 계속하시겠습니까?'
      );

      if (!isConfirmed) return;

      setIsLoading(true);

      const updatedItems = template.items.map(item => ({
        ...item,
        uniqueId: `${item.name}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
      }));

      onSave({
        processId,
        processName,
        items: updatedItems,
        templateId: template.id,
        userInput: template.userInput || {
          requirement: '',
          size: '',
          keywords: '',
          specialNotes: ''
        }
      }, 'replace');

      alert('템플릿이 적용되었습니다.');
      onClose();

    } catch (error) {
      console.error('템플릿 적용 중 오류:', error);
      alert('템플릿 적용 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadTemplates = async () => {
    try {
      console.log('Loading templates for processId:', processId);
      const templatesQuery = query(
        collection(db, 'ai-templates'),
        where('processId', '==', processId),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(templatesQuery);
      const loadedTemplates = [];
      querySnapshot.forEach((doc) => {
        loadedTemplates.push({ id: doc.id, ...doc.data() });
      });
      setTemplates(loadedTemplates);
      return loadedTemplates;
    } catch (error) {
      console.error('❌ 템플릿 로드 중 오류:', error);
      return [];
    }
  };

  const handleSaveTemplate = async () => {
    try {
      setIsLoading(true);
      
      const templateData = {
        processId,
        processName,
        items: currentItems,
        userInput,
        createdAt: new Date().toISOString(),
        useCount: 0
      };

      const docRef = await addDoc(collection(db, 'ai-templates'), templateData);
      
      const newTemplate = {
        id: docRef.id,
        ...templateData
      };
      
      setTemplates(prevTemplates => [newTemplate, ...prevTemplates]);
      
      onSave({ id: docRef.id, ...templateData }, 'save');
      
      alert('템플릿이 저장되었습니다.');
      
    } catch (error) {
      console.error('템플릿 저장 중 오류:', error);
      alert('저장 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAiSearch = async () => {
    try {
      if (!aiSearchQuery.trim()) {
        alert('AI 검색어를 입력해주세요.');
        return;
      }

      setIsLoading(true);
      const searchTemplates = await loadTemplates();

      const searchResults = await AIService.searchTemplatesWithAI(
        aiSearchQuery,
        searchTemplates
      );

      if (searchResults.length > 0) {
        setTemplates(searchResults);
      } else {
        alert('검색 결과가 없습니다.');
        setTemplates([]);
      }
    } catch (error) {
      console.error('AI 검색 오류:', error);
      alert('검색 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (template) => {
    setSelectedTemplate(template);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (!selectedTemplate) return;
      
      await deleteDoc(doc(db, 'ai-templates', selectedTemplate.id));
      setTemplates(templates.filter(t => t.id !== selectedTemplate.id));
      setShowDeleteConfirm(false);
      setSelectedTemplate(null);
      alert('템플릿이 삭제되었습니다.');
    } catch (error) {
      console.error('템플릿 삭제 중 오류:', error);
      alert('템플릿 삭제 중 오류가 발생했습니다.');
    }
  };

  const convertToAreaRange = (size) => {
    if (!size) return null;
    // "55평" 형식에서 숫자만 추출
    const numericSize = parseInt(size.replace(/[^0-9]/g, ''));
    if (isNaN(numericSize)) return null;
    
    // 10단위로 내림하여 평대 계산
    const range = Math.floor(numericSize / 10) * 10;
    return `${range}평대`;
  };

  const handleOneMinEstimate = async (template) => {
    try {
      // 1. 템플릿의 size 값 확인 및 변환
      const templateSize = template.userInput?.size;
      const areaRange = convertToAreaRange(templateSize);
      
      if (!areaRange) {
        alert('템플릿에 평수 정보가 없거나 올바르지 않습니다.');
        return;
      }

      // 2. 템플릿 아이템들의 총 가격 계산
      const totalPrice = template.items.reduce((sum, item) => {
        return sum + (parseInt(item.quantity) * parseInt(item.unitPrice));
      }, 0);

      // 3. process-mappings에서 매핑 정보 가져오기
      const mappingDoc = await getDoc(doc(db, 'process-mappings', 'default'));
      if (!mappingDoc.exists()) {
        alert('공정 매핑 정보가 없습니다.');
        return;
      }

      const mappings = mappingDoc.data();
      const oneMinProcess = mappings[template.processId];
      
      if (!oneMinProcess) {
        alert('이 공정에 대한 1분견적 매핑이 없습니다.');
        return;
      }

      // 4. 해당 평대의 가격 정보 가져오기
      const areaPrices = await getAreaPrices(areaRange);

      // 5. 새 가격으로 업데이트
      const updatedPrices = {
        ...areaPrices,
        [oneMinProcess]: totalPrice.toString()
      };

      // 6. Firebase에 저장
      await saveAreaPrices(areaRange, updatedPrices);

      alert(`1분견적 ${areaRange}의 ${oneMinProcess} 공정 가격이 ${totalPrice.toLocaleString()}원으로 업데이트되었습니다.`);

    } catch (error) {
      console.error('1분견적 적용 중 오류:', error);
      alert('1분견적 적용 중 오류가 발생했습니다.');
    }
  };

  // 외부 클릭 처리 함수 추가
  const handleOverlayClick = (e) => {
    // overlay 클래스를 가진 요소를 클릭했을 때만 모달 닫기
    if (e.target.className === 'ai-template-modal-overlay') {
      onClose();
    }
  };

  return (
    <div 
      className="ai-template-modal-overlay"
      onClick={handleOverlayClick}  // 클릭 이벤트 추가
    >
      <div className="ai-template-modal">
        <div className="modal-header">
          <h3>{processName} AI 추천</h3>
        </div>

        <div className="modal-content">
          {showDeleteConfirm && (
            <div className="delete-confirm">
              <div className="delete-confirm-content">
                <p>정말 삭제하시겠습니까?</p>
                <div className="delete-confirm-buttons">
                  <button 
                    className="cancel-button"
                    onClick={() => {
                      setShowDeleteConfirm(false);
                      setSelectedTemplate(null);
                    }}
                  >
                    취소
                  </button>
                  <button 
                    className="confirm-button"
                    onClick={handleDeleteConfirm}
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          )}
          
          <div className="current-items-section">
            <div className="section-header" onClick={() => setIsItemsExpanded(!isItemsExpanded)}>
              <h4 className="section-title">현재 품목 목록</h4>
              <button className="toggle-button">
                {isItemsExpanded ? '접기 ▼' : '펼치기 ▶'}
              </button>
            </div>
            
            {isItemsExpanded && (
              <div className="current-items-list">
                <div className="items-header">
                  <span className="col-name">품목명</span>
                  <span className="col-quantity">수량</span>
                  <span className="col-price">단가</span>
                </div>
                {currentItems.map((item, index) => (
                  <div key={index} className="current-item-row">
                    <span className="col-name">{item.name}</span>
                    <span className="col-quantity">{item.quantity} {item.unit}</span>
                    <span className="col-price">{item.unitPrice.toLocaleString()}원</span>
                  </div>
                ))}
                <div className="items-footer">
                  <div className="footer-left">
                    <span>총 {currentItems.length}개 품목</span>
                  </div>
                  <div className="footer-right">
                    <span>합계: {currentItems.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0).toLocaleString()}원</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="ai-input-section">
            <div className="section-header">
              <h4 className="section-title">템플릿 검색</h4>
              <button 
                className="view-all-button"
                onClick={() => setShowAllTemplates(true)}
              >
                전체 보기
              </button>
            </div>

            <div className="prompt-search">
              <div className="prompt-search-container">
                <input
                  type="text"
                  placeholder="키워드로 검색 (예: 30평, 200만원, 도배)"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  className="prompt-search-input"
                />
                {isLoading && <span className="searching-indicator">검색 중...</span>}
              </div>
            </div>

            <div className="ai-search">
              <div className="ai-search-container">
                <input
                  type="text"
                  placeholder="자연어로 검색 (예: 30평 아파트 친환경 도배 사)"
                  value={aiSearchQuery}
                  onChange={(e) => setAiSearchQuery(e.target.value)}
                  className="ai-search-input"
                />
                <button 
                  className="ai-search-button"
                  onClick={handleAiSearch}
                  disabled={isLoading}
                >
                  {isLoading ? '검색 중...' : 'AI 검색'}
                </button>
              </div>
            </div>

            <AIPromptInput
              value={userInput}
              onChange={setUserInput}
              currentItems={currentItems}
              onSearch={handleSearchInputChange}
              onApplyTemplate={handleApplyTemplate}
              templates={templates}
            />
          </div>

          {templates.length > 0 && (
            <div className="template-section">
              <h4 className="section-title">템플릿 추천</h4>
              <div className="template-list">
                {templates.map((template, index) => (
                  <div key={index} className="template-preview">
                    <div className="template-header">
                      <span className="template-name">{template.processName}</span>
                      <span className="template-date">
                        {new Date(template.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="template-details">
                      <div className="template-info-row">
                        <span className="info-label">특이사항:</span>
                        <span className="info-value">{template.userInput.specialNotes}</span>
                      </div>
                      <div className="template-info-row">
                        <span className="info-label">요구사항:</span>
                        <span className="info-value">{template.userInput.requirement}</span>
                      </div>
                      <div className="template-info-row">
                        <span className="info-label">평수:</span>
                        <span className="info-value">{template.userInput.size}평</span>
                      </div>
                      <div className="template-info-row total-price">
                        <span className="info-label">총 공사금액:</span>
                        <span className="info-value price">
                          {template.items?.reduce((sum, item) => 
                            sum + (parseInt(item.quantity) * parseInt(item.unitPrice)), 0
                          ).toLocaleString()}원
                        </span>
                      </div>
                      {template.userInput.keywords && (
                        <div className="template-keywords">
                          {template.userInput.keywords.split(',').map((keyword, idx) => (
                            <span key={idx} className="keyword-tag">
                              {keyword.trim()}
                            </span>
                          ))}
                        </div>
                      )}
                      {template.items && (
                        <div className="template-items">
                          <span className="info-label">포함 품목:</span>
                          <div className="items-tags">
                            {template.items.slice(0, 3).map((item, idx) => (
                              <span key={idx} className="item-tag">
                                {item.name}
                              </span>
                            ))}
                            {template.items.length > 3 && (
                              <span className="item-tag more">
                                +{template.items.length - 3}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="template-footer">
                      <span className="template-use-count">
                        사용 {template.useCount || 0}회
                      </span>
                      <div className="template-buttons">
                        <button 
                          className="template-apply-button"
                          onClick={() => handleApplyTemplate(template)}
                        >
                          적용
                        </button>
                        <button 
                          className="template-one-min-button"
                          onClick={() => handleOneMinEstimate(template)}
                        >
                          1분
                        </button>
                        <button 
                          className="template-delete-button"
                          onClick={() => handleDeleteClick(template)}
                        >
                          삭제
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button 
            className="template-save-button"
            onClick={handleSaveTemplate}
            disabled={isLoading}
          >
            템플릿 저장
          </button>
        </div>
      </div>

      {showAllTemplates && (
        <AllTemplatesModal
          onClose={() => setShowAllTemplates(false)}
        />
      )}
    </div>
  );
};

export default AITemplateModal; 