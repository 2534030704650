import React, { useEffect, useState } from 'react';
import './VersionHistory.css';

const VersionHistory = ({ versions = [], onClose, onRestore, onReset, onDeleteVersion, getProcessName }) => {
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {  // 유효하지 않은 날짜인 경우
        console.error('Invalid date string:', dateString);
        return '날짜 없음';
      }
      return date.toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // 24시간제로 표시
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return '날짜 없음';
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        if (showDetailModal) {
          setShowDetailModal(false);
        } else {
          onClose();
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose, showDetailModal]);

  const formatNumber = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderDetailModal = (version) => {
    if (!showDetailModal || !version) return null;

    return (
      <div className="detail-modal">
        <div className="detail-modal-content">
          <div className="detail-modal-header">
            <h3>수정 상세 내역</h3>
            <button className="detail-close-button" onClick={() => setShowDetailModal(false)}>×</button>
          </div>
          {version.filters && (
            <div className="filters-info">
              {Object.entries(version.filters).map(([key, value]) => 
                value && (
                  <div key={key} className="filter-item">
                    <span className="filter-label">{key}:</span>
                    <span className="filter-value">{value}</span>
                  </div>
                )
              )}
            </div>
          )}
          
          <table className="changes-table">
            <thead>
              <tr>
                <th>항목</th>
                <th>변경내용</th>
              </tr>
            </thead>
            <tbody>
              {renderModificationDetails(version.modifications)}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderModificationDetails = (modifications) => {
    if (!modifications) return null;

    return Object.entries(modifications).map(([key, modification]) => {
      if (!modification) return null;

      // AI 템플릿 적용 케이스 처리
      if (modification.type === 'template_apply') {
        return (
          <tr key={key}>
            <td>{modification.description || '알 수 없음'}</td>
            <td>
              {modification.changeDescription}
              <br />
              <br />
              <strong>적용된 품목:</strong>
              <br />
              {modification.items.map((item, index) => (
                <div key={index}>
                  • {item.name}: {item.quantity}{item.unit}, {formatNumber(item.unitPrice)}원
                </div>
              ))}
            </td>
          </tr>
        );
      }

      // 기존 케이스들 처리
      if (modification.type === 'add') {
        return (
          <tr key={key}>
            <td>품목 추가</td>
            <td>
              {getProcessName(modification.processId)}에 새로운 품목이 추가되었습니다.
              <br />
              품목명: {modification.newData?.name}
              <br />
              수량: {modification.newData?.quantity} {modification.newData?.unit}
              <br />
              단가: {formatNumber(modification.newData?.unitPrice)}원
              <br />
              합계: {formatNumber(modification.newData?.totalPrice)}원
              {modification.newData?.note && (
                <>
                  <br />
                  비고: {modification.newData.note}
                </>
              )}
            </td>
          </tr>
        );
      }

      if (modification.type === 'move') {
        return (
          <tr key={key}>
            <td>항목 이동</td>
            <td>{modification.description}</td>
          </tr>
        );
      }

      if (modification.type === 'delete') {
        return (
          <tr key={key} data-type="delete">
            <td>{modification.originalData?.name || '알 수 없음'}</td>
            <td>
              항목 삭제됨
              <br />
              수량: {modification.originalData?.quantity || 0}
              <br />
              단가: {formatNumber(modification.originalData?.unitPrice || 0)}
              <br />
              비고: {modification.originalData?.note || '-'}
            </td>
          </tr>
        );
      }

      if (modification.type === 'filter') {
        return (
          <tr key={key}>
            <td>필터 변경</td>
            <td>
              {modification.field}: {modification.originalValue || '-'} → {modification.modifiedValue || '-'}
            </td>
          </tr>
        );
      }

      if (modification.type === 'note') {
        return (
          <tr key={key}>
            <td>비고 변경</td>
            <td>
              {modification.originalValue || '(없음)'} → {modification.modifiedValue || '(없음)'}
            </td>
          </tr>
        );
      }

      const changes = [];
      const originalData = modification.originalData || {};
      const modifiedData = modification.modifiedData || {};

      if (originalData.quantity !== modifiedData.quantity) {
        changes.push(`수량: ${originalData.quantity || 0} → ${modifiedData.quantity || 0}`);
      }
      if (originalData.unitPrice !== modifiedData.unitPrice) {
        changes.push(`단가: ${formatNumber(originalData.unitPrice || 0)} → ${formatNumber(modifiedData.unitPrice || 0)}`);
      }
      if (originalData.note !== modifiedData.note) {
        changes.push(`비고: ${originalData.note || '-'} → ${modifiedData.note || '-'}`);
      }

      return (
        <tr key={key}>
          <td>{originalData.name || '알 수 없음'}</td>
          <td>{changes.join(', ') || '변경사항 없음'}</td>
        </tr>
      );
    }).filter(Boolean);
  };

  useEffect(() => {
    console.log('Versions timestamps:', versions.map(v => v.timestamp));
  }, [versions]);

  if (!Array.isArray(versions)) {
    console.warn('versions is not an array:', versions);
    return (
      <div className="version-history-modal">
        <div className="version-history-content">
          <div className="version-history-header">
            <div className="header-title-group">
              <h2>수정 이력</h2>
              <button 
                className="reset-history-button"
                onClick={() => {
                  if (window.confirm('모든 수정 이력이 초기화됩니다. 계속하시겠습니까?')) {
                    onReset();
                  }
                }}
              >
                초기화
              </button>
            </div>
            <button className="version-history-close" onClick={onClose}>×</button>
          </div>
          <div>수정 이력이 없습니다.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="version-history-modal">
      <div className="version-history-content">
        <div className="version-history-header">
          <div className="header-title-group">
            <h2>수정 이력</h2>
            <button 
              className="reset-history-button"
              onClick={() => {
                if (window.confirm('모든 수정 이력이 초기화됩니다. 계속하시겠습니까?')) {
                  onReset();
                }
              }}
            >
              초기화
            </button>
          </div>
          <button className="version-history-close" onClick={onClose}>×</button>
        </div>
        
        <div className="version-list">
          {[...versions].reverse().map((version, index) => (
            <div key={version.versionId} className="version-item">
              <div className="version-header">
                <div 
                  className="version-number"
                  title={version.versionName}
                  data-full-name={version.versionName}
                >
                  {version.versionName?.split(' + ')[0] || version.versionName}
                </div>
                <span className="version-timestamp">
                  {formatDate(version.createdAt || version.timestamp)}
                </span>
              </div>
              <div className="version-actions">
                <button 
                  className="detail-button"
                  onClick={() => {
                    setSelectedVersion(version);
                    setShowDetailModal(true);
                  }}
                >
                  상세보기
                </button>
                <button 
                  className="restore-button"
                  onClick={() => onRestore(version.versionId)}
                >
                  복원
                </button>
                <button 
                  className="delete-button"
                  onClick={() => {
                    if (window.confirm('이 버전을 삭제하시겠습니까?')) {
                      onDeleteVersion(version.versionId);
                    }
                  }}
                >
                  삭제
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedVersion && renderDetailModal(selectedVersion)}
    </div>
  );
};

export default VersionHistory; 