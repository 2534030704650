import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import './EstimateInquiryList.css';
import EstimateDetailModal from './EstimateDetailModal';

function EstimateInquiryList() {
  const [inquiries, setInquiries] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [activeTab, setActiveTab] = useState('inquiries');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // 견적 문의 데이터 가져오기
        const inquiriesQuery = query(
          collection(db, 'estimateInquiries'), 
          orderBy('createdAt', 'desc')
        );
        const inquiriesSnapshot = await getDocs(inquiriesQuery);
        const inquiriesData = inquiriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'inquiry'
        }));
        setInquiries(inquiriesData);

        // 견적서 데이터 가져오기
        const estimatesQuery = query(
          collection(db, 'estimates'),
          orderBy('createdAt', 'desc')
        );
        const estimatesSnapshot = await getDocs(estimatesQuery);
        const estimatesData = estimatesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: 'estimate'
        }));
        setEstimates(estimatesData);

      } catch (error) {
        console.error('데이터 로드 중 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedItems([]);
  };

  const toggleSelectItem = (id) => {
    setSelectedItems(prev => 
      prev.includes(id) 
        ? prev.filter(itemId => itemId !== id)
        : [...prev, id]
    );
  };

  const toggleSelectAll = () => {
    const currentItems = activeTab === 'inquiries' ? inquiries : estimates;
    if (selectedItems.length === currentItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(currentItems.map(item => item.id));
    }
  };

  const deleteSelectedItems = async () => {
    if (window.confirm(`선택��� ${selectedItems.length}개의 항목을 삭제하시겠습니까?`)) {
      try {
        for (const itemId of selectedItems) {
          const collectionName = activeTab === 'inquiries' ? 'estimateInquiries' : 'estimates';
          await deleteDoc(doc(db, collectionName, itemId));
        }
        
        if (activeTab === 'inquiries') {
          setInquiries(prev => prev.filter(item => !selectedItems.includes(item.id)));
        } else {
          setEstimates(prev => prev.filter(item => !selectedItems.includes(item.id)));
        }
        setSelectedItems([]);
      } catch (error) {
        console.error('삭제 중 오류 발생:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  if (loading) return <div className="loading">로딩중...</div>;

  const currentItems = activeTab === 'inquiries' ? inquiries : estimates;

  return (
    <div className="estimate-inquiry-list">
      <h1>견적/문의 내역</h1>
      
      <div className="tab-buttons">
        <button 
          className={`tab-button ${activeTab === 'inquiries' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('inquiries');
            setSelectedItems([]);
            setIsSelectMode(false);
          }}
        >
          일반 문의 ({inquiries.length})
        </button>
        <button 
          className={`tab-button ${activeTab === 'estimates' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('estimates');
            setSelectedItems([]);
            setIsSelectMode(false);
          }}
        >
          1분 견적 ({estimates.length})
        </button>
      </div>
      
      <div className="selection-controls">
        <button 
          className={`control-button ${isSelectMode ? 'active' : ''}`}
          onClick={toggleSelectMode}
        >
          {isSelectMode ? '선택 취소' : '선택하기'}
        </button>
        {isSelectMode && (
          <>
            <button 
              className="control-button select-all"
              onClick={toggleSelectAll}
            >
              {selectedItems.length === currentItems.length ? '전체 선택 해제' : '전체 선택'}
            </button>
            <button 
              className="control-button delete"
              onClick={deleteSelectedItems}
              disabled={selectedItems.length === 0}
            >
              선택 삭제 ({selectedItems.length})
            </button>
          </>
        )}
      </div>

      <div className="inquiry-list">
        {currentItems.map((item) => (
          <div 
            key={item.id} 
            className={`inquiry-item ${selectedItems.includes(item.id) ? 'selected' : ''}`}
            onClick={() => isSelectMode && toggleSelectItem(item.id)}
          >
            {isSelectMode && (
              <input
                type="checkbox"
                className="select-checkbox"
                checked={selectedItems.includes(item.id)}
                onChange={(e) => {
                  e.stopPropagation();
                  toggleSelectItem(item.id);
                }}
              />
            )}
            {activeTab === 'inquiries' ? (
              // 일반 문의 내용 렌더링
              <>
                <div className="inquiry-header">
                  <span className="name">{item.name}</span>
                  <span className="date">
                    {item.createdAt?.toDate().toLocaleDateString()}
                  </span>
                </div>
                <div className="inquiry-content">
                  <div className="info-row">
                    <span className="label">연락처:</span>
                    <span className="value">{item.phone}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">예산:</span>
                    <span className="value">{item.budget}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">주소:</span>
                    <span className="value">{item.address}</span>
                  </div>
                  <div className="question">
                    <div className="label">문의내용:</div>
                    <div className="value">{item.question}</div>
                  </div>
                </div>
                <div className="status-badge" data-status={item.status || '신규'}>
                  {item.status || '신규'}
                </div>
              </>
            ) : (
              // 견적서 내용 렌더링
              <>
                <div className="inquiry-header">
                  <span className="name">{item.userInfo?.name || '정보 없음'}</span>
                  <span className="date">
                    {item.createdAt?.toDate().toLocaleDateString()}
                  </span>
                </div>
                <div className="estimate-content">
                  <div className="info-row">
                    <span className="label">평수:</span>
                    <span className="value">{item.selectedArea}평</span>
                  </div>
                  <div className="info-row">
                    <span className="label">이메일:</span>
                    <span className="value">{item.userInfo?.email}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">총액:</span>
                    <span className="value">{item.totalPrice?.toLocaleString()}원</span>
                  </div>
                  <button 
                    className="view-estimate-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedEstimate(item);
                    }}
                  >
                    견적서 보기
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {selectedEstimate && (
        <EstimateDetailModal 
          estimate={selectedEstimate}
          onClose={() => setSelectedEstimate(null)}
        />
      )}
    </div>
  );
}

export default EstimateInquiryList; 