import React, { useState, useEffect } from 'react';
import './VersionSaveModal.css';

const VersionSaveModal = ({ onSave, onClose, currentVersion }) => {
  const [newVersionName, setNewVersionName] = useState('');
  const [baseVersionName, setBaseVersionName] = useState('');

  useEffect(() => {
    if (currentVersion) {
      setBaseVersionName(currentVersion.versionName);
    }
  }, [currentVersion]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newVersionName.trim()) {
      alert('버전 이름을 입력해주세요.');
      return;
    }
    const fullVersionName = baseVersionName 
      ? `${newVersionName.trim()} + ${baseVersionName}`
      : newVersionName.trim();
      
    onSave(fullVersionName);
    onClose();
  };

  return (
    <div className="version-save-modal-overlay">
      <div className="version-save-modal">
        <h2>버전 저장</h2>
        
        {baseVersionName && (
          <div className="base-version-info">
            <span className="base-version-label">기존 버전:</span>
            <span className="base-version-name" title={baseVersionName}>
              {baseVersionName}
            </span>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>새 버전 이름:</label>
            <input
              type="text"
              value={newVersionName}
              onChange={(e) => setNewVersionName(e.target.value)}
              placeholder="새로운 버전 이름을 입력하세요"
              autoFocus
            />
            {baseVersionName && (
              <div className="preview">
                <span className="preview-label">저장될 버전명:</span>
                <span className="preview-value">
                  {newVersionName ? `${newVersionName} + ${baseVersionName}` : ''}
                </span>
              </div>
            )}
          </div>
          
          <div className="button-group">
            <button type="submit" className="save-button">
              저장
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>
              취소
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VersionSaveModal; 