import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { getProcesses } from '../../firebase';
import LandingPage from './LandingPage';
import AreaSelection from './AreaSelection';
import ProcessSelection from './ProcessSelection';
import UserInfoInput from './UserInfoInput';
import EstimateResult from './EstimateResult';
import EmailTemplateModal from './EmailTemplateModal';
import './EstimateApp.css';
import './EstimateResult.css';
import { trackMetaEvent } from '../../utils/metaPixel';

// generateEstimateId 함수 추가
const generateEstimateId = async (db) => {
  const now = new Date();
  const year = now.getFullYear().toString().slice(-2);
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  
  return `${year}${month}${day}-${random}`;
};

const EstimateProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // URL에서 견적서 ID를 가져옴

  const [step, setStep] = useState(0);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [prices, setPrices] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [titleClickCount, setTitleClickCount] = useState(0);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isProcessesLoading, setIsProcessesLoading] = useState(true);
  const [processes, setProcesses] = useState([]);
  const [estimateId, setEstimateId] = useState(null);

  // 견적서 ID가 있는 경우 데이터 로드
  useEffect(() => {
    const loadEstimateData = async () => {
      if (id) {
        try {
          setLoading(true);
          const estimateDoc = await getDoc(doc(db, 'estimates', id));
          
          if (estimateDoc.exists()) {
            const data = estimateDoc.data();
            setSelectedArea(data.selectedArea);
            setSelectedProcesses(data.selectedProcesses.map(p => p.name));
            setUserInfo(data.userInfo);
            setPrices(data.selectedProcesses.reduce((acc, p) => ({
              ...acc,
              [p.name]: p.price
            }), {}));
            setTotalPrice(data.totalPrice);
            setStep(4);
          } else {
            console.error('견적서를 찾을 수 없습니다 - ID:', id);
            navigate('/');
          }
        } catch (error) {
          console.error('견적서 로드 중 오류:', error);
          navigate('/');
        } finally {
          setLoading(false);
        }
      } else {
        console.log('ID가 없음 - 일반 모드로 진행');
        setLoading(false);
      }
    };

    loadEstimateData();
  }, [id, navigate]);

  // ESC 키 핸들러 추가
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleBack();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [step]);

  // 뒤로가기 핸들러 함수
  const handleBack = () => {
    // 견적서 상세 보기일 경우 처리하지 않음
    if (id) return;
    
    // returnPath가 있는 경우 해당 경로로 이동
    if (location.state?.returnPath) {
      navigate(location.state.returnPath.replace(':id', location.state.returnParams?.id || ''));
      return;
    }

    // 일반적인 뒤로가기 처리
    if (step === 0) {
      navigate('/');
    } else {
      setStep(prev => prev - 1);
    }
  };

  // 기존 popstate 이벤트 핸들러 수정
  useEffect(() => {
    const handlePopState = () => {
      handleBack();
    };

    window.addEventListener('popstate', handlePopState);
    
    // returnPath가 없을 때만 history stack에 상태 추가
    if (!id && !location.state?.returnPath) {
      window.history.pushState(null, '', window.location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [step, navigate, id, location.state]);

  // 가격 계산을 위한 useEffect 추가
  useEffect(() => {
    const calculatePrices = async () => {
      if (selectedArea && selectedProcesses.length > 0) {
        try {
          // settings 컬렉션의 areaPrices 문서에서 가격 데이터 조회
          const settingsDocRef = doc(db, 'settings', 'areaPrices');
          const settingsDocSnap = await getDoc(settingsDocRef);

          if (settingsDocSnap.exists()) {
            const areaPricesData = settingsDocSnap.data();
            // 선택된 평수의 가격 데이터 가져오기
            const areaPrices = areaPricesData[`${selectedArea}평대`] || {};
            
            // 가격 매핑
            const mappedPrices = {};
            selectedProcesses.forEach(process => {
              mappedPrices[process] = areaPrices[process];
            });
            
            setPrices(mappedPrices);
            
            // 총액 계산
            const total = selectedProcesses.reduce((sum, process) => {
              const price = parseInt(areaPrices[process]) || 0;
              return sum + price;
            }, 0);
            
            setTotalPrice(total);
          } else {
            console.error('가격 데이터를 찾을 수 없습니다.');
          }
        } catch (error) {
          console.error('가격 계산 중 오류:', error);
        }
      }
    };

    calculatePrices();
  }, [selectedArea, selectedProcesses]);

  // 공정 데이터 미리 로드
  useEffect(() => {
    const preloadProcesses = async () => {
      try {
        setIsProcessesLoading(true);
        const fetchedProcesses = await getProcesses();
        if (fetchedProcesses) {
          setProcesses(fetchedProcesses);
        }
      } catch (error) {
        console.error('공정 데이터 로드 중 오류:', error);
      } finally {
        setIsProcessesLoading(false);
      }
    };

    preloadProcesses();
  }, []);

  const handleStart = () => {
    setStep(1);
  };

  const handleAreaSelect = (area) => {
    trackMetaEvent('SelectArea', { area });
    setSelectedArea(area);
    setStep(2);
  };

  const handleProcessSelect = (processes) => {
    setSelectedProcesses(processes);
    setShowWarning(false);
  };

  const handleComplete = () => {
    if (selectedProcesses.length >= 4) {
      trackMetaEvent('CompleteProcessSelection', {
        processes: selectedProcesses,
        count: selectedProcesses.length
      });
      setStep(3);
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const handleSubmit = async (name, email) => {
    try {
      // 견적서 저장
      const processDetails = selectedProcesses.map(process => ({
        name: process,
        price: prices[process] || 0
      }));

      const docRef = await addDoc(collection(db, "estimates"), {
        userInfo: { name, email },
        selectedArea: selectedArea,
        selectedProcesses: processDetails,
        totalPrice: totalPrice,
        createdAt: new Date()
      });

      // 상태 업데이트
      setUserInfo({ name, email });
      setEstimateId(docRef.id); // estimateId 상태 업데이트
      setStep(4);
      
      // URL 업데이트 - navigate 사용
      navigate(`/estimate/${docRef.id}`, { replace: true });

      trackMetaEvent('CompleteEstimate', {
        area: selectedArea,
        processCount: selectedProcesses.length,
        totalPrice: totalPrice
      });

    } catch (error) {
      alert('견적서 저장 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const handleGoHome = () => {
    setStep(0);
    setSelectedArea(null);
    setSelectedProcesses([]);
    setShowWarning(false);
    setUserInfo(null);
  };

  const handleTitleClick = () => {
    const newCount = titleClickCount + 1;
    setTitleClickCount(newCount);
    
    if (newCount === 4) {
      setShowEmailTemplate(true);
      setTitleClickCount(0);
    }
  };

  if (loading) {
    return <div className="estimate-app">로딩 중...</div>;
  }

  return (
    <div className="estimate-app">
      <div className="content">
        {step === 0 && <LandingPage onStart={handleStart} />}
        {step > 0 && step < 4 && (
          <div className="header">
            <h2 className="title" onClick={handleTitleClick}>
              {step === 1 ? '평수를 선택해주세요' : 
               step === 2 ? (
                 <>
                   필요한 공정을 4개 이상 선택해주세요
                   <div style={{ height: '30px' }}></div>
                 </>
               ) :
               step === 3 ? '수고 많으셨습니다.' : ''}
            </h2>
            {step === 1 && (
              <p className="area-info"><br />
                확장된 평수 기준 입니다.<br />
                60평대 이상은 따로 문의주세요!
              </p>
            )}
            {step === 2 && !isProcessesLoading && (
              <>
                <p className="process-info">
                  인테리어 자재는 유명 브랜드 제품 기준 입니다.<br />
                  인터폰, 도어락은 미포함 입니다.<br /><br />
                </p>
                <ProcessSelection 
                  onSelect={handleProcessSelect} 
                  selectedProcesses={selectedProcesses}
                  processes={processes}
                />
                <button 
                  className={`complete-button ${selectedProcesses.length >= 4 ? 'active' : ''}`} 
                  onClick={handleComplete}
                >
                  선택이 끝났으면 클릭하세요
                </button>
                {showWarning && <p className="warning-message">공정을 4개 이상 선택해주세요</p>}
              </>
            )}
            {step === 3 && (
              <p className="user-info-description"><br />
                아래 사항을 입력해주시면<br />
                가 견적서를 즉시 확인할 수 있습니다. 😊
              </p>
            )}
          </div>
        )}
        {step === 1 && <AreaSelection onSelect={handleAreaSelect} selectedArea={selectedArea} />}
        {step === 3 && (
          <UserInfoInput 
            onSubmit={handleSubmit} 
            selectedProcesses={selectedProcesses}
            selectedArea={selectedArea}
            prices={prices}
            totalPrice={totalPrice}
          />
        )}
        {step === 4 && (
          <EstimateResult 
            userInfo={userInfo} 
            selectedProcesses={selectedProcesses} 
            selectedArea={selectedArea} 
            onGoHome={handleGoHome}
            prices={prices}
            totalPrice={totalPrice}
            estimateId={id || estimateId}
          />
        )}
        {showEmailTemplate && (
          <EmailTemplateModal onClose={() => setShowEmailTemplate(false)} />
        )}
      </div>
    </div>
  );
};

export default EstimateProcess; 