import React from 'react';
import { useNavigate } from 'react-router-dom';

const Contract = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="page-container">
      <button className="back-button" onClick={handleBack}>
        &#8592; 뒤로가기
      </button>
      <div className="page-content">
        <h1 className="page-title">공사 계약서</h1>
        <p className="page-description">공사 계약서를 작성하고 관리할 수 있습니다.</p>
        <button className="start-button">계약서 작성하기</button>
      </div>
    </div>
  );
};

export default Contract;
