import React from 'react';

const ConstructionGuide = () => {
  return (
    <div className="page-container">
      <div className="page-content">
        <h1 className="page-title">공사 안내문</h1>
        <p className="page-description">공사에 대한 상세한 안내를 확인할 수 있습니다.</p>
        <button className="start-button">안내문 보기</button>
      </div>
    </div>
  );
};

export default ConstructionGuide;
