import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../firebase';
import { collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import './EstimateApp.css';
import { FaPhone, FaComments, FaFileAlt } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateEstimateId } from '../../utils/estimateUtils';
import { trackMetaEvent } from '../../utils/metaPixel';

// PDF 스타일 정의
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff'
  },
  header: {
    marginBottom: 20,
    textAlign: 'center'
  },
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  // ... 기존 스타일 유지
});

// PDF 문서 컴포넌트
const EstimatePdfDocument = ({ selectedProcesses, userInfo }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>AMARE DESIGN</Text>
      </View>
      {/* 나머지 PDF 내용 */}
    </Page>
  </Document>
);

function EstimateResult({ 
  selectedProcesses, 
  selectedArea, 
  userInfo, 
  onGoHome, 
  isModalView,
  prices: initialPrices,
  totalPrice: initialTotal,
  estimateId: propEstimateId
}) {
  const [prices, setPrices] = useState(initialPrices || {});
  const [totalPrice, setTotalPrice] = useState(initialTotal || 0);
  const [orderedProcesses, setOrderedProcesses] = useState([]);
  const [isMessageBtnHighlighted, setIsMessageBtnHighlighted] = useState(false);
  const [currentEstimateId, setCurrentEstimateId] = useState(propEstimateId);
  const navigate = useNavigate();
  const location = useLocation();
  const [template, setTemplate] = useState(null);

  // URL에서 견적서 ID 추출 및 상태 업데이트
  useEffect(() => {
    const path = location.pathname;
    const match = path.match(/\/estimate\/(.+)$/);
    if (match) {
      setCurrentEstimateId(match[1]);
    } else if (propEstimateId) {
      setCurrentEstimateId(propEstimateId);
    }
  }, [location.pathname, propEstimateId]);

  // 공정 순서 로드 및 정렬
  useEffect(() => {
    const loadProcessOrder = async () => {
      try {
        const processesDoc = await getDoc(doc(db, 'settings', 'processes'));
        
        if (processesDoc.exists() && processesDoc.data().list?.length > 0) {
          const orderedProcessList = processesDoc.data().list;
          
          // 선택된 공정들을 관리자가 설정한 순서대로 정렬
          const ordered = orderedProcessList
            .filter(process => selectedProcesses.includes(process));
          
          setOrderedProcesses(ordered);
        } else {
          setOrderedProcesses(selectedProcesses);
        }
      } catch (error) {
        setOrderedProcesses(selectedProcesses);
      }
    };

    if (selectedProcesses.length > 0) {
      loadProcessOrder();
    }
  }, [selectedProcesses]);

  // props로 받은 가격이 업데이트될 때마다 state 업데이트
  useEffect(() => {
    if (initialPrices) {
      setPrices(initialPrices);
    }
    if (initialTotal) {
      setTotalPrice(initialTotal);
    }
  }, [initialPrices, initialTotal]);

  // 뒤로가기 이벤트 처리
  useEffect(() => {
    const handlePopState = (event) => {
      if (!isModalView) {
        // 견적서 세 페이지가 아닐 때만 처리
        if (location.pathname.includes('/estimate/')) {
          window.history.pushState(null, '', '/estimate');
          if (onGoHome) {
            onGoHome();
          }
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    
    // 현재 페이지를 history에 추가
    if (!isModalView) {
      window.history.pushState(null, '', location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isModalView, location.pathname, onGoHome]);

  // 주기적으로 애니메이션 실행
  useEffect(() => {
    const interval = setInterval(() => {
      setIsMessageBtnHighlighted(prev => !prev);
    }, 2000); // 2초마다 토글

    return () => clearInterval(interval);
  }, []);

  // 템플릿 데이터 로드
  useEffect(() => {
    const loadTemplate = async () => {
      try {
        const docRef = doc(db, 'emailTemplates', '1mintemplates');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTemplate(docSnap.data());
        }
      } catch (error) {
        // console.error('템플릿 로딩 중 오류:', error);
      }
    };
    
    loadTemplate();
  }, []);

  // 설명 메시지 생성 함수
  const getDescriptionMessage = () => {
    const defaultMessage = `안녕하세요. ${userInfo?.name || ''}님,\n`;
    return defaultMessage + (template?.estimateDescription || '');
  };

  const formatPrice = (price) => {
    if (!price) return '0';
    return parseInt(price).toLocaleString();
  };

  const getProcessPrice = (process) => {
    const price = prices[process];
    
    // 가격이 문자열이고 숫자가 아닌 경우 (예: "방문실측필요")
    if (typeof price === 'string' && isNaN(parseInt(price))) {
      return <span className="price-value">{price}</span>;
    }
    
    // 숫자인 경우 포맷팅하여 "원" 추가
    return <span className="price-value">{formatPrice(price)}원</span>;
  };

  const formatProcessName = (process) => {
    const processMapping = {
      '제작 가구(싱크대, 붙박이)': '제작 가구\n(싱크대,붙박이)',
      '철거 및 폐기물 처리': '철거\n폐기물리',
      '배란다 탄성코트': '탄성코트'
    };
    return processMapping[process] || process;
  };

  const handlePhoneCall = () => {
    trackMetaEvent('Contact', { method: 'phone' });
    window.location.href = 'tel:01086944078';
  };

  const handleSMS = () => {
    trackMetaEvent('Contact', { method: 'sms' });
    window.location.href = 'sms:01086944078';
  };

  const handleEstimateInquiry = () => {
    if (currentEstimateId) {
      trackMetaEvent('Contact', { 
        method: 'inquiry',
        estimateId: currentEstimateId 
      });
      navigate(`/estimate-inquiry/${currentEstimateId}`);
    } else {
      alert('견적서 ID를 찾을 수 없습니다. 페이지를 새로고침해주세요.');
    }
  };

  // 처음으로 돌아가기 버튼 핸들러 수정
  const handleGoHome = () => {
    navigate('/', { replace: true });
    if (onGoHome) {
      onGoHome();
    }
  };

  return (
    <div className="estimate-result">
      {!isModalView && (
        <div className="button-container">
          <button className="go-home-button" onClick={handleGoHome}>
            처음으로 돌아가기
          </button>
          <div className="action-buttons">
            <button className="circle-button" onClick={handleEstimateInquiry}>
              <FaFileAlt className="button-icon" />
              <span>견적문의</span>
            </button>
            <button className="circle-button" onClick={handlePhoneCall}>
              <FaPhone className="button-icon" />
              <span>전화</span>
            </button>
            <button 
              className={`circle-button sms-button ${isMessageBtnHighlighted ? 'active' : ''}`} 
              onClick={handleSMS}
            >
              <FaComments className="button-icon" />
              <span>문자</span>
            </button>
          </div>

          <div className="estimate-description">
            <p>{getDescriptionMessage()}</p>
          </div>
        </div>
      )}
      
      <div className="title-container">
        <h1 className="main-title">AMARE DESIGN</h1>
      </div>
      
      <div className="estimate-header">
        <h3 className="sub-title">
          {userInfo?.name}님의 {selectedArea}평 견적서
        </h3>
        <p className="estimate-date">{new Date().toLocaleDateString()}</p>
      </div>

      <div className="process-list">
        <div className="process-header">
          <span>공정</span>
          <span>금액</span>
        </div>
        
        {/* 디버깅을 위한 조건부 렌더링 */}
        {orderedProcesses.length === 0 ? (
          <div>로딩 중...</div>
        ) : (
          orderedProcesses.map((process, index) => (
            <div key={index} className="process-item">
              <span className="process-name" style={{ whiteSpace: 'pre-line' }}>
                {formatProcessName(process)}
              </span>
              {getProcessPrice(process)}
            </div>
          ))
        )}

        <div className="process-item special">
          <span className="process-name">공과잡비</span>
          <span className="visit-required">미정</span>
        </div>
      </div>

      <div className="total-price">
        <span className="total-label">총 견적 금액</span>
        <span className="total-value">{formatPrice(totalPrice)}원</span>
      </div>

      <div className="estimate-footer">
        <p className="note" style={{ whiteSpace: 'pre-line' }}>
          {'※ 상기 금액은 예상 견적가로\n현장 상황에 따라 변동될 수 있습니다.'}
        </p>
      </div>
    </div>
  );
}

export default EstimateResult;
