import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import DesktopEstimate from './DesktopEstimate';
import './DetailedEstimate.css';

const ViewTypeSelection = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="view-type-selection">
      <button className="back-button" onClick={handleBack}>
        &#8592; 뒤로가기
      </button>
      <h1>상세 견적서 목록</h1>
      <div className="view-type-buttons">
        <button 
          className="view-type-button mobile"
          onClick={() => navigate('/detailed-estimate/mobile')}
        >
          <span className="icon">📱</span>
          <span>모바일 버전</span>
        </button>
        <button 
          className="view-type-button desktop"
          onClick={() => navigate('/detailed-estimate/desktop')}
        >
          <span className="icon">💻</span>
          <span>데스크탑 버전</span>
        </button>
      </div>
    </div>
  );
};

const DetailedEstimate = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewTypeSelection />} />
      <Route path="/desktop" element={<DesktopEstimate />} />
      <Route path="/mobile" element={<div>모바일 버전</div>} />
    </Routes>
  );
};

export default DetailedEstimate; 