import React, { useState, useEffect, useCallback } from 'react';
import OngoingAddressesToggle from './OngoingAddressesToggle';
import './DesktopEstimate.css';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import DatabaseManager from './DatabaseManager';
import EditItemModal from './estimate-version/EditItemModal';
import VersionHistory from './estimate-version/VersionHistory';
import VersionSaveModal from './estimate-version/VersionSaveModal';
import EditStartModal from './estimate-version/EditStartModal';
import SelectVersionModal from './estimate-version/SelectVersionModal';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import EmailPdfModal from './EmailPdfModal';
import AddItemModal from './estimate-version/AddItemModal';
import AutoSaveVersion from './estimate-version/AutoSaveVersion';
import DragHandleItemList from './estimate-version/DragHandleItemList';
import GradeFilter from './filters/components/GradeFilter';
import SizeFilter from './filters/components/SizeFilter';
import ExtraFilter from './filters/components/ExtraFilter';
import WindowFilter from './filters/components/WindowFilter';
import PaintingFilter from './filters/components/PaintingFilter';
import FurnitureFilter from './filters/components/FurnitureFilter';
import ACFilter from './filters/components/ACFilter';
import DesignFilter from './filters/components/DesignFilter';
import FloorTileFilter from './filters/components/FloorTileFilter';
import AISuggestButton from './estimate-ai/AISuggestButton';
import AITemplateModal from './estimate-ai/components/AITemplateModal';
import HideProcessButton from './process-hide/HideProcessButton';
import HiddenProcessList from './process-hide/HiddenProcessList';
import { useNavigate } from 'react-router-dom';

// 한글 폰트 등록 (Pretendard 폰트 사용)
Font.register({
  family: 'Pretendard',
  src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff',
  fontStyle: 'normal',
  fontWeight: 'normal'
});

Font.register({
  family: 'Pretendard',
  src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff',
  fontStyle: 'normal',
  fontWeight: 'bold'
});

const DesktopEstimate = () => {
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [processData, setProcessData] = useState([]);
  const [selectedSize, setSelectedSize] = useState('평');
  const [selectedGrade, setSelectedGrade] = useState('등급');
  const [editingNote, setEditingNote] = useState(null);
  const [notes, setNotes] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [showDatabaseManager, setShowDatabaseManager] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAIPromptModal, setShowAIPromptModal] = useState(false);
  const [selectedExtra, setSelectedExtra] = useState('별도');
  const [selectedWindow, setSelectedWindow] = useState('없음');
  const [selectedPainting, setSelectedPainting] = useState('없음');
  const [selectedFurniture, setSelectedFurniture] = useState('상담필요');
  const [selectedAC, setSelectedAC] = useState('없음');
  
  const [pendingModifications, setPendingModifications] = useState({});
  const [modificationCount, setModificationCount] = useState(0);
  const [modifications, setModifications] = useState({});
  const [versionHistory, setVersionHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [showEditStartModal, setShowEditStartModal] = useState(false);
  const [pendingEditItem, setPendingEditItem] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [showSelectVersionModal, setShowSelectVersionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  // 새로운 state 추가
  const [newVersionName, setNewVersionName] = useState(null);

  // 상태 추가 (기존 state 선언부에 추가)
  const [selectedDesign, setSelectedDesign] = useState('디자함');
  const [selectedFloorTile, setSelectedFloorTile] = useState('바닥타일미포함');

  // 상태 추가 (다른 state 선언부에 추가)
  const [showAITemplateModal, setShowAITemplateModal] = useState(false);
  const [selectedProcessForAI, setSelectedProcessForAI] = useState(null);

  // 상단에 state 추가
  const [hiddenProcesses, setHiddenProcesses] = useState({});
  const [showHiddenProcessModal, setShowHiddenProcessModal] = useState(false);

  // 상태 추가
  const [skipRestore, setSkipRestore] = useState(false);

  // PDF 스타일 정의
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 25,
      fontFamily: 'Pretendard'
    },
    header: {
      backgroundColor: 'white',
      borderRadius: 6,
      padding: 15,
      marginBottom: 15,
      borderWidth: 0.5,
      borderColor: '#E1D8D8',
      height: 180
    },
    headerTop: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
      paddingBottom: 15,
      borderBottomWidth: 0.5,
      borderColor: '#E1D8D8',
      height: 60
    },
    headerTitle: {
      flex: 1
    },
    title: {
      fontSize: 20,
      marginBottom: 4,
      fontWeight: 'bold'
    },
    subtitle: {
      fontSize: 12
    },
    logo: {
      width: 45,
      height: 45,
      borderRadius: 22.5
    },
    headerBottom: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
      height: 85,
      alignItems: 'flex-start'
    },
    infoSection: {
      flex: 1,
      height: '100%',
      justifyContent: 'flex-start'
    },
    infoLabel: {
      fontSize: 10,
      marginBottom: 6,
      color: '#946A6A',
      fontWeight: 'medium'
    },
    infoText: {
      fontSize: 8,
      marginBottom: 3,
      lineHeight: 1.4
    },
    totalSection: {
      width: '25%',
      alignItems: 'flex-end',
      height: '100%',
      justifyContent: 'flex-start'
    },
    totalAmount: {
      fontSize: 16,
      marginBottom: 2,
      fontWeight: 'bold'
    },
    vatNotice: {
      fontSize: 8
    },
    
    // 공정별 합계 테이블 스타일
    summaryTable: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      borderTop: 0.5,
      borderBottom: 0.5,
      borderColor: '#E1D8D8'
    },
    tableSectionTitle: {
      fontSize: 12,
      marginBottom: 10,
      marginTop: 0
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#946A6A',
      borderBottomWidth: 0.5,
      borderBottomColor: '#E1D8D8'
    },
    processCol: { 
      width: '33.33%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    priceCol: { 
      width: '33.33%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    noteCol: { 
      width: '33.34%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    tableHeaderCell: {
      color: 'white',
      fontSize: 9,
      padding: 8,
      textAlign: 'center'
    },
    tableCell: {
      fontSize: 8,
      padding: 6,
      textAlign: 'center',
      width: '100%'
    },
    tableCellProcess: {
      color: '#333333',
      fontWeight: 'medium',
      textAlign: 'center'
    },
    tableCellPrice: {
      color: '#946A6A',
      fontWeight: 'medium',
      textAlign: 'center'
    },
    tableCellNote: {
      color: '#666666',
      textAlign: 'center'
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 0.5,
      borderBottomColor: '#E1D8D8',
      minHeight: 28,
      alignItems: 'center'
    },
    summaryTotal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#F7F7F6',
      borderBottomWidth: 0.5,
      borderBottomColor: '#E1D8D8',
      marginTop: -0.5
    },
    summaryTotalLabel: {
      fontSize: 8,
      marginRight: 8
    },
    summaryTotalAmount: {
      fontSize: 9,
      fontWeight: 'bold'
    },
    // 데이 행의 컬럼 스
    dataProcessCol: {
      width: '33.33%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    dataPriceCol: {
      width: '33.33%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    dataNoteCol: {
      width: '33.34%',  // 변경
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    // 상세 견적서 스타일
    detailSection: {
      marginTop: 20
    },
    sectionTitle: {
      fontSize: 12,
      color: '#333333',
      fontWeight: 'bold',
      marginBottom: 15,
      fontFamily: 'Pretendard'
    },
    processSection: {
      marginBottom: 20,  // 공정 섹션 아래 여백 추가
    },
    processName: {
      backgroundColor: '#F7F7F6',
      padding: 8,
      fontSize: 9,
      color: '#946A6A',
      fontWeight: 'bold',
      textAlign: 'center',
      borderTopWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: '#E1D8D8'
    },
    detailTable: {
      borderTop: 0.5,
      borderBottom: 0.5,
      borderColor: '#E1D8D8'
    },
    detailTableHeader: {
      flexDirection: 'row',
      backgroundColor: '#946A6A'
    },
    detailHeaderCell: {
      color: 'white',
      fontSize: 8,
      padding: 6,
      textAlign: 'center',
      fontFamily: 'Pretendard'
    },
    detailRow: {
      flexDirection: 'row',
      borderBottomWidth: 0.5,
      borderBottomColor: '#E1D8D8',
      minHeight: 24
    },
    detailCell: {
      fontSize: 7,
      padding: 4,
      textAlign: 'center'
    },
    // 상세 견적서 컬럼 스타일
    detailItemCol: {
      width: '20%',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    detailQuantityCol: {
      width: '8%',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    detailUnitCol: {
      width: '7%',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    detailUnitPriceCol: {
      width: '15%',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    detailAmountCol: {
      width: '15%',
      borderRightWidth: 0.5,
      borderRightColor: '#E1D8D8'
    },
    detailNoteCol: {
      width: '35%'
    },
    // 소계 스타일
    processTotal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#F7F7F6',
      borderBottomWidth: 0.5,
      borderColor: '#E1D8D8',
      marginBottom: 15  // 소계 아래 여백 추가
    },
    processTotalLabel: {
      fontSize: 8,
      marginRight: 8,
      color: '#333333'
    },
    processTotalAmount: {
      fontSize: 9,
      fontWeight: 'bold',
      color: '#946A6A'
    }
  });

  // 상세 견적서 스타일 정의
  const detailStyles = StyleSheet.create({
    page: {
      padding: 40,
      fontFamily: 'Pretendard'
    },
    sectionTitle: {
      fontSize: 12,
      color: '#333333',
      fontWeight: 'bold',
      marginBottom: 15
    },
    processSection: {
      marginBottom: 20
    },
    processName: {
      backgroundColor: '#F7F7F6',
      padding: 8,
      fontSize: 9,
      color: '#946A6A',
      fontWeight: 'bold',
      textAlign: 'center',
      borderTop: 0.5,
      borderBottom: 0.5,
      borderColor: '#E1D8D8'
    },
    table: {
      width: '100%',
      borderColor: '#E1D8D8'
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#946A6A',
      borderBottom: 0.5,
      borderColor: '#E1D8D8',
      minHeight: 28,
      alignItems: 'center'
    },
    tableRow: {
      flexDirection: 'row',
      borderBottom: 0.5,
      borderColor: '#E1D8D8',
      minHeight: 26,
      alignItems: 'center'
    },
    headerCell: {
      color: 'white',
      fontSize: 8,
      padding: 6,
      textAlign: 'center'
    },
    cell: {
      fontSize: 7,
      padding: 4,
      textAlign: 'center'
    },
    // 컬럼 스타일
    colItem: { 
      width: '20%', 
      borderRight: 0.5, 
      borderColor: '#E1D8D8',
      height: '100%',
      justifyContent: 'center'
    },
    colQuantity: { 
      width: '8%', 
      borderRight: 0.5, 
      borderColor: '#E1D8D8',
      height: '100%',
      justifyContent: 'center'
    },
    colUnit: { 
      width: '7%', 
      borderRight: 0.5, 
      borderColor: '#E1D8D8',
      height: '100%',
      justifyContent: 'center'
    },
    colUnitPrice: { 
      width: '15%', 
      borderRight: 0.5, 
      borderColor: '#E1D8D8',
      height: '100%',
      justifyContent: 'center'
    },
    colAmount: { 
      width: '15%', 
      borderRight: 0.5, 
      borderColor: '#E1D8D8',
      height: '100%',
      justifyContent: 'center'
    },
    colNote: { 
      width: '35%',
      height: '100%',
      justifyContent: 'center'
    },
    processTotal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#F7F7F6',
      borderBottom: 0.5,
      borderColor: '#E1D8D8'
    },
    processTotalLabel: {
      fontSize: 8,
      marginRight: 8,
      color: '#333333'
    },
    processTotalAmount: {
      fontSize: 9,
      fontWeight: 'bold',
      color: '#946A6A'
    }
  });

  useEffect(() => {
    fetchProcessData();
  }, [selectedSize, selectedGrade, selectedExtra, selectedWindow, selectedPainting, 
      selectedFurniture, selectedAC, selectedDesign, selectedFloorTile]);

  useEffect(() => {
    if (selectedAddress) {
      loadModifications(selectedAddress);
    }
  }, [selectedAddress]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        // 모달 닫기 우선순위 설정
        if (showAITemplateModal) {
          setShowAITemplateModal(false);
          setSelectedProcessForAI(null);
        } else if (showSelectVersionModal) {
          setShowSelectVersionModal(false);
          setPendingEditItem(null);
          setPendingModifications({});
          setIsEditingMode(false);
        } else if (showHistoryModal) {
          setShowHistoryModal(false);
          setIsEditingMode(false);
        } else if (showDatabaseManager) {
          setShowDatabaseManager(false);
        } else if (showUploadModal) {
          setShowUploadModal(false);
        } else if (showAIPromptModal) {
          setShowAIPromptModal(false);
        } else if (modalType) {
          setModalType(null);
          setEditingItem(null);
        } else if (showEditStartModal) {
          setShowEditStartModal(false);
          setPendingEditItem(null);
          setIsEditingMode(false);
        } else if (showEmailModal) {
          setShowEmailModal(false);
        } else if (showAddItemModal) {
          setShowAddItemModal(false);
          setSelectedProcessId(null);
        } else if (isEditingMode) {
          // 다른 모달����������������������� 모두 닫혀있을 때만 수정 모드 취소 확인
          if (window.confirm('진행 중인 수정사항이 모두 취��됩니다. 계속하시겠습니까?')) {
            setIsEditingMode(false);
            setPendingModifications({});
            setModificationCount(0);
            loadModifications();
          }
        }

        return;
      }
    };

    document.addEventListener('keydown', handleEscKey, true);
    return () => {
      document.removeEventListener('keydown', handleEscKey, true);
    };
  }, [
    showAITemplateModal,  // AI 템플릿 모달 상태 추가
    showSelectVersionModal, 
    showHistoryModal, 
    showDatabaseManager, 
    showUploadModal, 
    showAIPromptModal, 
    modalType, 
    showEditStartModal, 
    isEditingMode, 
    showEmailModal,
    showAddItemModal
  ]);

  useEffect(() => {
    const loadDataWithModifications = async () => {
      if (!selectedAddress) {
        return;
      }
      
      try {
        let currentOrder = null;
        if (versionHistory.length > 0) {
          const latestVersion = versionHistory[versionHistory.length - 1];
          currentOrder = latestVersion.processOrder;
        }

        await fetchProcessData();
        
        if (Object.keys(modifications).length > 0) {
          await applyModifications(modifications);
        }

        if (currentOrder) {
          setProcessData(prevData => {
            const orderedData = prevData.map(process => {
              const savedOrder = currentOrder[process.id];
              if (!savedOrder) return process;

              const orderedItems = [...process.items].sort((a, b) => {
                const indexA = savedOrder.indexOf(a.uniqueId);
                const indexB = savedOrder.indexOf(b.uniqueId);
                if (indexA === -1) return 1;
                if (indexB === -1) return -1;
                return indexA - indexB;
              });

              return {
                ...process,
                items: orderedItems
              };
            });
            return orderedData;
          });
        }

      } catch (error) {
        console.error('데이터 로드 중 오류:', error);
      }
    };

    // 디바운싱 적용
    const timeoutId = setTimeout(() => {
      loadDataWithModifications();
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [
    selectedSize, selectedGrade, selectedExtra, selectedWindow, 
    selectedPainting, selectedFurniture, selectedAC, 
    selectedDesign, selectedFloorTile, 
    selectedAddress, modifications, versionHistory // 의존성 배열에 필요한 값들 추가
  ]);

  const fetchProcessData = async () => {
    try {
      const categoryPricesRef = collection(db, 'categoryPrices');
      const q = query(categoryPricesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      const data = [];

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const prices = docData.prices || [];

        // 디자인 공정 처리
        if (doc.id === 'design') {
          if (selectedDesign === '디자인포함') {
            const designPrices = prices.filter(price => {
              const sizeMatch = selectedSize === '평' || 
                              price.size === selectedSize || 
                              price.size === '전체' ||
                              !price.size;
              
              const gradeMatch = selectedGrade === '등급' || 
                              price.grade === selectedGrade || 
                              price.grade === '전체' ||
                              !price.grade;

              return sizeMatch && gradeMatch;
            });

            if (designPrices.length > 0) {
              const processTotal = designPrices.reduce((sum, item) => {
                const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
                return sum + itemTotal;
              }, 0);

              data.push({
                id: doc.id,
                processOrder: docData.processOrder || 1,
                total: processTotal,
                items: designPrices.map(item => ({
                  ...item,
                  totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
                })),
                note: notes[doc.id] || ''
              });
            }
          }
        }
        // 바닥타일 공정 처리
        else if (doc.id === 'floor_tile') {
          if (selectedFloorTile === '바닥타일포함') {
            const floorTilePrices = prices.filter(price => {
              const sizeMatch = selectedSize === '평' || 
                              price.size === selectedSize || 
                              price.size === '전체' ||
                              !price.size;
              
              const gradeMatch = selectedGrade === '등급' || 
                              price.grade === selectedGrade || 
                              price.grade === '전체' ||
                              !price.grade;

              return sizeMatch && gradeMatch;
            });

            if (floorTilePrices.length > 0) {
              const processTotal = floorTilePrices.reduce((sum, item) => {
                const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
                return sum + itemTotal;
              }, 0);

              data.push({
                id: doc.id,
                processOrder: docData.processOrder || 11,
                total: processTotal,
                items: floorTilePrices.map(item => ({
                  ...item,
                  totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
                })),
                note: notes[doc.id] || ''
              });
            }
          }
        }
        else if (doc.id === 'window') {
          const windowPrices = prices.filter(price => {
            if (selectedWindow === '없음') return false;
            return price.option === selectedWindow;
          });
          
          if (windowPrices.length > 0) {
            const processTotal = windowPrices.reduce((sum, item) => {
              const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
              return sum + itemTotal;
            }, 0);

            data.push({
              id: doc.id,
              processOrder: docData.processOrder || 3,
              total: processTotal,
              items: windowPrices.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
              })),
              note: notes[doc.id] || ''
            });
          }
        }
        else if (doc.id === 'painting') {
          const paintingPrices = prices.filter(price => {
            if (selectedPainting === '없음') return false;
            return price.option === selectedPainting;
          });
          
          if (paintingPrices.length > 0) {
            const processTotal = paintingPrices.reduce((sum, item) => {
              const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
              return sum + itemTotal;
            }, 0);

            data.push({
              id: doc.id,
              processOrder: docData.processOrder || 9,
              total: processTotal,
              items: paintingPrices.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
              })),
              note: notes[doc.id] || ''
            });
          }
        }
        else if (doc.id === 'furniture') {
          const furniturePrices = prices.filter(price => {
            if (selectedFurniture === '상담필요') {
              return price.option === '상담필요';
            } else {
              return price.option === '기본';
            }
          });
          
          if (furniturePrices.length > 0) {
            const processTotal = furniturePrices.reduce((sum, item) => {
              const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
              return sum + itemTotal;
            }, 0);

            data.push({
              id: doc.id,
              processOrder: docData.processOrder || 13,
              total: processTotal,
              items: furniturePrices.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
              })),
              note: notes[doc.id] || ''
            });
          }
        }
        else if (doc.id === 'ac') {
          const acPrices = prices.filter(price => {
            if (selectedAC === '없음') return false;
            return price.option === selectedAC;
          });
          
          if (selectedAC !== '없음' && acPrices.length > 0) {
            const processTotal = acPrices.reduce((sum, item) => {
              const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
              return sum + itemTotal;
            }, 0);

            data.push({
              id: doc.id,
              processOrder: docData.processOrder || 15,
              total: processTotal,
              items: acPrices.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0)
              })),
              note: notes[doc.id] || ''
            });
          }
        }
        else {
          const filteredPrices = prices.filter(price => {
            if (!price) return false;

            const sizeMatch = selectedSize === '평' || 
                             price.size === selectedSize || 
                             price.size === '전체' ||
                             !price.size;
            
            const gradeMatch = selectedGrade === '등급' || 
                              price.grade === selectedGrade || 
                              price.grade === '전체' ||
                              !price.grade;

            const statusMatch = price.required === true || 
                              (selectedExtra === '별도' && price.status === '별도');

            return sizeMatch && gradeMatch && statusMatch;
          });

          if (filteredPrices.length > 0) {
            const processTotal = filteredPrices.reduce((sum, item) => {
              const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
              return sum + itemTotal;
            }, 0);

            data.push({
              id: doc.id,
              processOrder: docData.processOrder || 999,
              total: processTotal,
              items: filteredPrices.map(item => {
                const itemTotal = parseInt(item.quantity || 0) * parseInt(item.unitPrice || 0);
                return {
                  ...item,
                  name: (!item.required && item.status === '별도' && selectedExtra === '별도') ? 
                        `${item.name} (별도)` : 
                        item.name,
                  totalPrice: itemTotal,
                  uniqueId: item.uniqueId || `${doc.id}_${item.name}_${Date.now()}`
                };
              }),
              note: notes[doc.id] || ''
            });
          }
        }
      });

      data.sort((a, b) => a.processOrder - b.processOrder);
      setProcessData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // loadModifications 함수 수정
  const loadModifications = async (address) => {
    try {
      console.group('📂 수정이력 로드');
      
      const targetAddress = address || selectedAddress;
      console.log('대상 주소:', targetAddress);
      
      if (!targetAddress) {
        console.warn('주소가 선택되지 않음');
        console.groupEnd();
        return;
      }

      const docRef = doc(db, 'estimateModifications', targetAddress);
      const docSnap = await getDoc(docRef);
      
      console.log('Firebase 문서 존재 여부:', docSnap.exists());
      
      if (docSnap.exists()) {
        const data = docSnap.data();  // 여기서 data 변수 정의
        console.log('로드된 데이터:', data);
        
        // ���김 상태 로드
        if (data.hiddenProcesses) {
          setHiddenProcesses(data.hiddenProcesses);
        }
        
        if (data.versions && data.versions.length > 0) {
          // 최신 버전을 찾기 위해 lastModified 기준으로 정렬
          const sortedVersions = [...data.versions].sort((a, b) => {
            const timeA = a.lastModified || a.createdAt;
            const timeB = b.lastModified || b.createdAt;
            return new Date(timeB) - new Date(timeA);
          });

          const latestVersion = sortedVersions[0];
          console.log('최신 버전:', latestVersion);
          
          // 버전 이력 업데이트를 먼저 수행
          setVersionHistory(sortedVersions);

          // 필터 상태 업데이트
          if (latestVersion.filters) {
            console.log('필터 업데이트:', latestVersion.filters);
            await updateFilters(latestVersion.filters);
            // 필터 업데이트 후 잠시 대기
            await new Promise(resolve => setTimeout(resolve, 100));
          }

          // 노트 업데이트
          if (latestVersion.notes) {
            console.log('노트 업데이트:', latestVersion.notes);
            setNotes(latestVersion.notes);
          }
          
          // 수정사항 적용
          if (latestVersion.modifications) {
            console.log('수정사항 적용 시작');
            setModifications(latestVersion.modifications);
            await applyModifications(latestVersion.modifications);
            console.log('수정사항 적용 완료');
          }

          // 프로세스 순서 정보가 있으면 마지막에 적용
          if (latestVersion.processOrder) {
            console.log('프로세스 순서 적용:', latestVersion.processOrder);
            setProcessData(prevData => applyOrder(prevData, latestVersion.processOrder));
          }

          // 현재 버�� 설정
          setCurrentVersion(latestVersion);
        } else {
          console.log('버전 정보 없음');
          setVersionHistory([]);
          setModifications({});
          setCurrentVersion(null);
        }
      } else {
        console.log('수정이력 문서 없음');
        setVersionHistory([]);
        setModifications({});
        setCurrentVersion(null);
      }

      console.log('✅ 수정이력 로드 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 수정이력 로드 중 오류:', error);
      console.groupEnd();
    }
  };

  const updateFilters = async (filters) => {
    try {
      // 모든 필터 상태를 한 번에 업데이트
      await new Promise((resolve) => {
        setSelectedSize(filters?.size || '평');
        setSelectedGrade(filters?.grade || '등급');
        setSelectedExtra(filters?.extra || '별도');
        setSelectedWindow(filters?.window || '없음');
        setSelectedPainting(filters?.painting || '없음');
        setSelectedFurniture(filters?.furniture || '���담필요');
        setSelectedAC(filters?.ac || '없음');
        setSelectedDesign(filters?.design || '디자인포��');
        setSelectedFloorTile(filters?.floor_tile || '바닥타��미포함');
        setTimeout(resolve, 200); // 시간 증가
      });

      // 필터 업데이트 후 데이터 로드
      await fetchProcessData();
      
      // 데이터 로드 후 추가 대기
      await new Promise(resolve => setTimeout(resolve, 200));
    } catch (error) {
      console.error('필터 업데이트 중 오류:', error);
    }
  };

  const applyModifications = async (modifications) => {
    try {
      await new Promise((resolve) => {
        setProcessData(prevData => {
          if (!prevData) return [];

          const updatedData = prevData.map(process => {
            if (!process || !process.items) return null;

            const processModifications = modifications ? Object.values(modifications)
              .filter(mod => mod && 
                (mod.processId === process.id || 
                 (mod.type === 'add' && mod.newData?.processId === process.id))
              ) : [];
            
            if (processModifications.length === 0) return process;

            let updatedItems = [...process.items];

            const templateMods = processModifications.filter(mod => mod.type === 'template_apply');
            if (templateMods.length > 0) {
              templateMods.forEach(mod => {
                updatedItems = mod.items.map(item => ({
                  ...item,
                  totalPrice: parseInt(item.quantity) * parseInt(item.unitPrice)
                }));
              });
            }

            const deletedItemIds = Object.values(modifications)
              .filter(mod => mod.type === 'delete')
              .map(mod => mod.itemId);

            updatedItems = updatedItems.filter(item => !deletedItemIds.includes(item.uniqueId));

            updatedItems = updatedItems.map(item => {
              const modification = processModifications.find(
                mod => mod && mod.type !== 'delete' && mod.itemId === item.uniqueId
              );
              
              if (!modification || !modification.modifiedData) return item;
              
              return {
                ...item,
                ...modification.modifiedData,
                uniqueId: item.uniqueId
              };
            });

            const addedItems = processModifications
              .filter(mod => mod.type === 'add' && !deletedItemIds.includes(mod.itemId))
              .map(mod => ({
                ...mod.newData,
                uniqueId: mod.itemId
              }));

            addedItems.forEach(newItem => {
              if (!updatedItems.some(item => item.uniqueId === newItem.uniqueId)) {
                updatedItems.push(newItem);
              }
            });

            return {
              ...process,
              items: updatedItems,
              total: updatedItems.reduce((sum, item) => {
                const itemTotal = parseInt(item.totalPrice) || 0;
                return sum + itemTotal;
              }, 0)
            };
          }).filter(Boolean);

          return updatedData;
        });
        
        setTimeout(resolve, 100);
      });

    } catch (error) {
      alert('수정사항 적용 중 오류가 발생했습니다.');
    }
  };

  // 1. 현재 순서 수집 함수 추가
  const collectCurrentOrder = () => {
    return processData.reduce((acc, process) => {
      acc[process.id] = process.items.map(item => item.uniqueId);
      return acc;
    }, {});
  };

  // 2. 순서 적용 함수 수정
  const applyOrder = (data, orderData) => {
    if (!orderData) return data;

    return data.map(process => {
      const savedOrder = orderData[process.id];
      if (!savedOrder) return process;

      // 저장된 순서에 따라 items 재정��
      const orderedItems = [...process.items].sort((a, b) => {
        const indexA = savedOrder.indexOf(a.uniqueId);
        const indexB = savedOrder.indexOf(b.uniqueId);
        return indexA - indexB;
      });

      return {
        ...process,
        items: orderedItems
      };
    });
  };

  // 3. 주소 선택 시 데이터 로드 함�� 수정
  const handleSelectAddress = async (addressId, paymentData) => {
    try {
      if (selectedAddress === addressId) return;

      await startLoadingProcess(async () => {
        const addressDescription = sessionStorage.getItem('selectedAddressDescription');
        const finalAddress = addressDescription || addressId;
        
        setSelectedAddress(finalAddress);
        setPaymentData(paymentData);

        const docRef = doc(db, 'estimateModifications', finalAddress);
        const docSnap = await getDoc(docRef);
        
        if (!docSnap.exists()) {
          await fetchProcessData();
          return;
        }

        const data = docSnap.data();
        if (!data.versions || data.versions.length === 0) {
          await fetchProcessData();
          return;
        }

        const latestVersion = data.versions[data.versions.length - 1];

        if (latestVersion.filters) {
          await updateFilters(latestVersion.filters);
        }

        await fetchProcessData();
        if (latestVersion.modifications) {
          await applyModifications(latestVersion.modifications);
        }

        if (latestVersion.processOrder) {
          setProcessData(prevData => applyOrder(prevData, latestVersion.processOrder));
        }

        setVersionHistory(data.versions);
      });
    } catch (error) {
      alert('데이터를 불러오는 중 오류가 발생했습니다.');
    }
  };

  const handleAdminLogin = (isAdmin) => {
    setIsEditMode(isAdmin);
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getProcessName = (processId) => {
    const processNames = {
      design: '디자인',
      demolition: '철거폐기물',
      window: '창호금속',
      plumbing: '배관 공사',
      carpentry: '목공사',
      door: '문공사',
      tile: '타일 공사',
      film: '필름공사',
      floor_protection: '바닥 보양 공사',
      wood_flooring: '바닥재 공사',
      floor_tile: '바닥 타일 공사',
      painting: '도장공사',
      wallpaper: '도배공사',
      bathroom: '욕실시공',
      hardware: '하드웨어, 악세서리',
      electrical: '전기공사',
      furniture: '가구공사',
      silicon: '실리콘 공사',
      ac: '에어컨공사',
      supervision_utilities: '현장감리/공과잡비',
      misc_additional: '기타잡비/별도공사'
    };
    return processNames[processId] || processId;
  };

  const handleGradeChange = (grade) => {
    const newGrade = grade === '등급' ? '전' : grade;
    
    if (!isEditingMode && selectedAddress) {
      setShowEditStartModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'grade',
          originalValue: selectedGrade,
          modifiedValue: newGrade,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    setSelectedGrade(newGrade);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'grade',
          originalValue: selectedGrade,
          modifiedValue: newGrade,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }
    
    fetchProcessData();
  };

  const getDisplayName = (item) => {
    if (!item.required && item.status === '별도') {
      return `${item.name} (별도)`;
    }
    return item.name;
  };

  const handleItemEdit = (process, item) => {
    if (!isEditMode) {
      alert('관리자 모드가 아닙니다. 수정하시려면 관리자 로그인이 필요합니다.');
      return;
    }
    
    if (!selectedAddress) {
      if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으로 이동하시겠습니까?')) {
        const addressComponent = document.querySelector('.desktop-ongoing-container');
        if (addressComponent) {
          addressComponent.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
      return;
    }
    
    if (!isEditingMode) {
      setPendingEditItem({ process, item });
      setShowSelectVersionModal(true);
      return;
    }

    setModalType('edit');
    setEditingItem({ processId: process.id, item });
  };

  const handleEditStart = () => {
    setIsEditingMode(true);
    setPendingModifications({});
    setModificationCount(0);
    setShowEditStartModal(false);

    if (pendingEditItem) {
      setModalType('edit');
      setEditingItem({ 
        processId: pendingEditItem.process.id, 
        item: pendingEditItem.item 
      });
      setPendingEditItem(null);
    }
  };

  const saveModification = async (processId, itemId, changes) => {
    try {
      console.group('💾 수정사항 저장 프로���스');
      console.log('저장 전 상태:', {
        processId,
        itemId,
        changes
      });

      if (!selectedAddress || !isEditMode) {
        console.warn('수정 ���가:', { hasAddress: !!selectedAddress, isEditMode });
        console.groupEnd();
        return;
      }

      const process = processData.find(p => p.id === processId);
      const item = process?.items.find(i => i.uniqueId === changes.uniqueId);

      if (!item) {
        console.error('항목을 찾을 수 없음:', changes.uniqueId);
        console.groupEnd();
        return;
      }

      console.log('수정할 항목:', {
        processId,
        uniqueId: changes.uniqueId,
        before: item,
        after: changes
      });

      const newItemTotal = parseInt(changes.quantity) * parseInt(changes.unitPrice);

      const existingModification = Object.entries(pendingModifications)
        .find(([key, mod]) => mod.itemId === changes.uniqueId);

      if (existingModification) {
        if (changes.quantity === item.quantity && 
            changes.unitPrice === item.unitPrice && 
            changes.note === item.note &&
            changes.name === item.name &&    // 품목명 비교 추가
            changes.unit === item.unit) {    // 단위 비��� 추가
          setPendingModifications(prev => {
            const newMods = { ...prev };
            delete newMods[existingModification[0]];
            return newMods;
          });
          setModificationCount(prev => prev - 1);
        }
      } else {
        if (changes.quantity !== item.quantity || 
            changes.unitPrice !== item.unitPrice || 
            changes.note !== item.note ||
            changes.name !== item.name ||    // 품명 비교 추가
            changes.unit !== item.unit) {    // 단위 비교 추가
          setPendingModifications(prev => ({
            ...prev,
            [changes.uniqueId]: {
              processId,
              itemId: changes.uniqueId,
              originalData: {
                name: item.name,
                quantity: item.quantity,
                unitPrice: item.unitPrice,
                unit: item.unit,
                note: item.note || ''
              },
              modifiedData: {
                name: changes.name,          // 품목명 추가
                quantity: parseInt(changes.quantity),
                unitPrice: parseInt(changes.unitPrice),
                unit: changes.unit,          // 단위 추가
                note: changes.note || '',
                totalPrice: newItemTotal
              },
              modifiedAt: new Date().toISOString()
            }
          }));
          setModificationCount(prev => prev + 1);
        }
      }

      // UI 업데이트
      setProcessData(prevData => {
        return prevData.map(p => {
          if (p.id !== processId) return p;

          const updatedItems = p.items.map(i => {
            if (i.uniqueId !== changes.uniqueId) return i;
            
            return {
              ...i,
              name: changes.name,            // 품목명 업데이트
              quantity: parseInt(changes.quantity),
              unitPrice: parseInt(changes.unitPrice),
              unit: changes.unit,          // 단위 업데이트
              note: changes.note || '',
              totalPrice: newItemTotal
            };
          });

          return {
            ...p,
            items: updatedItems,
            total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
          };
        });
      });

      console.log('✅ 수정사항 저장 료');
      console.groupEnd();
    } catch (error) {
      console.error('저장 중 오류:', error);
      console.groupEnd();
    }
  };

  const EditModal = ({ item, onSave, onClose }) => {
    const [editedItem, setEditedItem] = useState({
      name: item.name || '',
      quantity: item.quantity || 0,
      unitPrice: item.unitPrice || 0,
      note: item.note || ''
    });

    const handleSave = () => {
      if (!editedItem.quantity || !editedItem.unitPrice) {
        alert('수과 단가를 입력해주세요.');
        return;
      }
      onSave(editedItem);
    };

    return (
      <div className="edit-modal">
        <div className="edit-modal-content">
          <h3>항목 수정</h3>
          <div className="edit-field">
            <label>품목</label>
            <input
              type="text"
              value={editedItem.name}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                name: e.target.value
              }))}
              placeholder="품목명"
            />
          </div>
          <div className="edit-field">
            <label>수량</label>
            <input
              type="number"
              value={editedItem.quantity}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                quantity: parseInt(e.target.value) || 0
              }))}
              placeholder="수량 입력"
            />
          </div>
          <div className="edit-field">
            <label>단가</label>
            <input
              type="number"
              value={editedItem.unitPrice}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                unitPrice: parseInt(e.target.value) || 0
              }))}
              placeholder="단 입력"
            />
          </div>
          <div className="edit-field">
            <label>비고</label>
            <input
              type="text"
              value={editedItem.note}
              onChange={e => setEditedItem(prev => ({
                ...prev,
                note: e.target.value
              }))}
              placeholder="비고 력"
            />
          </div>
          <div className="total-price">
            <label>합계:</label>
            <span>{formatNumber(editedItem.quantity * editedItem.unitPrice)}</span>
          </div>
          <div className="edit-buttons">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>
    );
  };

  const loadModificationHistory = async () => {
    if (!selectedAddress) {
      return;
    }
    
    const docRef = doc(db, 'estimateModifications', selectedAddress);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      setVersionHistory(data.versions || []);
    }
  };

  const restoreVersion = async (versionId) => {
    try {
      const version = versionHistory.find(v => v.versionId === versionId);
      if (!version) return;

      // 필터 업데이트
      await updateFilters(version.filters);

      // 수사항 적용
      if (version.modifications) {
        await applyModifications(version.modifications);
      }

      // 순서 정보 복원
      if (version.processOrder) {
        setProcessData(prevData => {
          return prevData.map(process => {
            const savedOrder = version.processOrder[process.id];
            if (!savedOrder) return process;

            // 저장된 순서에 따라 items 재정
            const orderedItems = [...process.items].sort((a, b) => {
              const indexA = savedOrder.indexOf(a.uniqueId);
              const indexB = savedOrder.indexOf(b.uniqueId);
              return indexA - indexB;
            });

            return {
              ...process,
              items: orderedItems
            };
          });
        });
      }

      // Firebase 업데이트
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      await updateDoc(docRef, {
        currentVersion: versionId,
        lastModified: new Date().toISOString()
      });

      setShowHistoryModal(false);
      alert('선���한 버전으로 복원되었습다.');
    } catch (error) {
      console.error('버전 복원 중 오류:', error);
      alert('버전 복원 중 오류가 발생했습니다.');
    }
  };

  const closeModal = () => {
    setModalType(null);
    setEditingItem(null);
  };

  const resetModifications = async () => {
    try {
      if (!selectedAddress) {
        alert('주소를 먼�� 선택해주세요.');
        return;
      }

      // Firebase에서 수정 이력 기화
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      await setDoc(docRef, {
        versions: [],
        currentVersion: null,
        lastModified: new Date().toISOString()
      });

      // 상태 초기화
      setVersionHistory([]);
      setModifications({});
      setPendingModifications({});
      setModificationCount(0);

      // 데터 다시 불러오기
      await fetchProcessData();
      
      // 모달 닫기
      setShowHistoryModal(false);
      
      alert('수��� 이력이 초기되었습니다.');
    } catch (error) {
      console.error('초기화 중 오:', error);
      alert('초기화 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteVersion = async (versionId) => {
    try {
      console.group('���️ 버전 삭제 시작');
      console.log('삭할 버전 ID:', versionId);
      
      if (!selectedAddress) {
        console.warn('소 선택되지 않음');
        alert('주소를 먼저 선해주세요.');
        console.groupEnd();
        return;
      }

      // 현재 문의 버전 목 져오기
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        console.warn('문서가 재하지 않음');
        console.groupEnd();
        return;
      }

      const currentData = docSnap.data();
      const updatedVersions = currentData.versions.filter(
        version => version.versionId !== versionId
      );

      // Firebase 업데이트
      await updateDoc(docRef, {
        versions: updatedVersions,
        lastModified: new Date().toISOString()
      });

      // UI 업데이트
      setVersionHistory(updatedVersions);
      
      console.log('✅ 버전 삭제 완료');
      alert('버전이 삭제되습니다.');
      
      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 삭제 중 오류:', error);
      alert('버전 삭제  오류가 발했습니다.');
      console.groupEnd();
    }
  };

  //  합계 계산 함수 추가
  const calculateTotal = (processData) => {
    if (!Array.isArray(processData)) return 0;
    
    return processData.reduce((sum, process) => {
      if (!process || !Array.isArray(process.items)) return sum;
      
      const processTotal = process.items.reduce((itemSum, item) => {
        if (!item) return itemSum;
        const itemTotal = (parseInt(item.totalPrice) || 0);
        return itemSum + itemTotal;
      }, 0);
      
      return sum + processTotal;
    }, 0);
  };

  // VAT 포함 금액 계 함수 추가
  const calculateTotalWithVAT = (total) => {
    return total * 1.1; // VAT 10% 추가
  };

  const handleStartEdit = () => {
    if (!selectedAddress) {
      alert('주소 먼저 선택해주세요.');
      return;
    }
    setShowSelectVersionModal(true);
  };

  // 5. 버전 선택 시 처 함수 수정
  const handleVersionSelect = async (selectedVersion) => {
    try {
      console.group(' 버��� 선택 및 상태 복원');
      
      // 1. 현재 버전 설정
      setCurrentVersion(selectedVersion);
      
      // 2. 버전 상태 복원
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        const currentState = data.currentState;

        if (currentState) {
          // 필터 상태 복원
          await updateFilters(selectedVersion.filters);
          
          // 노트 복원
          setNotes(selectedVersion.notes || {});
          
          // 프로세스 데이터 복원
          if (currentState.processData) {
            setProcessData(currentState.processData);
          }
          
          // 수정사항 적용
          if (selectedVersion.modifications) {
            await applyModifications(selectedVersion.modifications);
          }
          
          // 프로세스 순서 복원
          if (selectedVersion.processOrder) {
            setProcessData(prevData => applyOrder(prevData, selectedVersion.processOrder));
          }
        }
      }

      // 3. 수정 모드 설정
      setIsEditingMode(true);
      setPendingModifications({});
      setModificationCount(0);
      
      // 4. 모달 닫기
      setShowSelectVersionModal(false);

      console.log('✅ 버전 선택 및 상태 복원 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 선택 중 오류:', error);
      alert('버전을 불러오는 중 오류가 발생했습니다.');
      console.groupEnd();
    }
  };

  // handleNewVersion 함수 수정
  const handleNewVersion = (versionName) => {
    setNewVersionName(versionName);
    setIsEditingMode(true);
    setPendingModifications({});
    setModificationCount(0);
    setCurrentVersion(null);
    setShowSelectVersionModal(false);
  };

  // filterProcessesForPDF 함수 추가 (MyDocument 컴포넌트 위에)
  const filterProcessesForPDF = (processes, hiddenProcesses) => {
    return processes.filter(process => {
      const hasNonZeroTotal = process.total > 0;
      const isVisible = !hiddenProcesses[process.id]?.hidden;
      return hasNonZeroTotal && isVisible;
    });
  };

  // MyDocument 컴포넌트 수정
  const MyDocument = () => {
    // 필터링된 프로세스 데이터 생성
    const filteredProcessesForPDF = filterProcessesForPDF(processData, hiddenProcesses);

    // 첫 페이지 (공정별 합계)
    const firstPage = (
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerTop}>
            <View style={styles.headerTitle}>
              <Text style={styles.title}>견적서</Text>
              <Text style={styles.subtitle}>Estimate</Text>
            </View>
            <Image src="/images/logo.png" style={styles.logo} />
          </View>
          <View style={styles.headerBottom}>
            <View style={styles.infoSection}>
              <Text style={styles.infoLabel}>업체정보</Text>
              <Text style={styles.infoText}>Amare design, 아마레디자인</Text>
              <Text style={styles.infoText}>712.17.01551</Text>
              <Text style={styles.infoText}>수성구 들안로 215</Text>
              <Text style={styles.infoText}>010. 8694.4078</Text>
              <Text style={styles.infoText}>대표 권경대</Text>
            </View>
            <View style={styles.infoSection}>
              <Text style={styles.infoLabel}>주소</Text>
              <Text style={styles.infoText}>{selectedAddress || '주소를 선택해주세요'}</Text>
            </View>
            <View style={styles.totalSection}>
              <Text style={styles.infoLabel}>TOTAL</Text>
              <Text style={styles.totalAmount}>
                {formatNumber(calculateTotal(filteredProcessesForPDF))}원
              </Text>
              <Text style={styles.vatNotice}>(VAT 별도)</Text>
            </View>
          </View>
        </View>

        {/* 공정별 합계 테이블 수정 */}
        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <View style={styles.processCol}>
              <Text style={styles.tableHeaderCell}>공정</Text>
            </View>
            <View style={styles.priceCol}>
              <Text style={styles.tableHeaderCell}>가격</Text>
            </View>
            <View style={styles.noteCol}>
              <Text style={styles.tableHeaderCell}>비고</Text>
            </View>
          </View>

          {filteredProcessesForPDF.map((process, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.dataProcessCol}>
                <Text style={[styles.tableCell, styles.tableCellProcess]}>
                  {getProcessName(process.id)}
                </Text>
              </View>
              <View style={styles.dataPriceCol}>
                <Text style={[styles.tableCell, styles.tableCellPrice]}>
                  {formatNumber(process.total)}원
                </Text>
              </View>
              <View style={styles.dataNoteCol}>
                <Text style={[styles.tableCell, styles.tableCellNote]}>
                  {notes[process.id] || ''}
                </Text>
              </View>
            </View>
          ))}

          <View style={styles.summaryTotal}>
            <Text style={styles.summaryTotalLabel}>총 합계 :</Text>
            <Text style={styles.summaryTotalAmount}>
              {formatNumber(calculateTotal(filteredProcessesForPDF))}원
            </Text>
          </View>
        </View>
      </Page>
    );

    // 페이지 높이 계산을 위한 상수
    const PAGE_HEIGHT = 842; // A4 높이 (포인트)
    const PAGE_PADDING = 40;
    const USABLE_HEIGHT = PAGE_HEIGHT - (PAGE_PADDING * 2);
    const SECTION_TITLE_HEIGHT = 30;
    const PROCESS_HEADER_HEIGHT = 35;
    const TABLE_HEADER_HEIGHT = 30;
    const ROW_HEIGHT = 26;
    const PROCESS_TOTAL_HEIGHT = 30;
    const PROCESS_MARGIN = 20;

    // 상세 견적서 페이지 생성 함수 수정
    const generateDetailPages = () => {
      let pages = [];
      let currentPage = [];
      let currentHeight = 0;
      let isFirstDetailPage = true;

      // 필터링된 데이터 사용
      filteredProcessesForPDF.forEach((process, processIndex) => {
        const itemsCount = process.items.length;
        const processHeight = PROCESS_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + 
                             (itemsCount * ROW_HEIGHT) + PROCESS_TOTAL_HEIGHT;

        // 현재 페이지에 공정이 들어갈 공간이 있는지 확인
        if (currentHeight + processHeight > USABLE_HEIGHT) {
          // 새 페이지 시작
          pages.push(
            <Page key={pages.length} size="A4" style={detailStyles.page}>
              {currentPage}
            </Page>
          );
          currentPage = [];
          currentHeight = 0;
        }

        // 첫 번째 상세 페이지의 첫 번째 공정인 경우에만 제목 추가
        if (isFirstDetailPage && currentHeight === 0) {
          currentPage.push(
            <View>
              <Text style={[detailStyles.sectionTitle, { marginBottom: 50 }]}>상세 견적서</Text>
            </View>
          );
          currentHeight += SECTION_TITLE_HEIGHT + 50; // 제목 높이 + 추가 여백
          isFirstDetailPage = false;
        }

        // 공정 섹션 추��
        currentPage.push(
          <View key={process.id} style={[
            detailStyles.processSection,
            { marginBottom: processIndex < filteredProcessesForPDF.length - 1 ? PROCESS_MARGIN : 0 }
          ]}>
            <Text style={detailStyles.processName}>
              {getProcessName(process.id)}
            </Text>
            
            <View style={detailStyles.table}>
              <View style={detailStyles.tableHeader}>
                <View style={detailStyles.colItem}>
                  <Text style={detailStyles.headerCell}>품목</Text>
                </View>
                <View style={detailStyles.colQuantity}>
                  <Text style={detailStyles.headerCell}>수량</Text>
                </View>
                <View style={detailStyles.colUnit}>
                  <Text style={detailStyles.headerCell}>단위</Text>
                </View>
                <View style={detailStyles.colUnitPrice}>
                  <Text style={detailStyles.headerCell}>단가</Text>
                </View>
                <View style={detailStyles.colAmount}>
                  <Text style={detailStyles.headerCell}>금액</Text>
                </View>
                <View style={detailStyles.colNote}>
                  <Text style={detailStyles.headerCell}>비고</Text>
                </View>
              </View>

              {process.items.map((item, itemIndex) => (
                <View key={itemIndex} style={detailStyles.tableRow}>
                  <View style={detailStyles.colItem}>
                    <Text style={detailStyles.cell}>{item.name || ''}</Text>
                  </View>
                  <View style={detailStyles.colQuantity}>
                    <Text style={detailStyles.cell}>{item.quantity || ''}</Text>
                  </View>
                  <View style={detailStyles.colUnit}>
                    <Text style={detailStyles.cell}>{item.unit || ''}</Text>
                  </View>
                  <View style={detailStyles.colUnitPrice}>
                    <Text style={detailStyles.cell}>
                      {item.unitPrice ? formatNumber(item.unitPrice) : ''}
                    </Text>
                  </View>
                  <View style={detailStyles.colAmount}>
                    <Text style={detailStyles.cell}>
                      {item.totalPrice ? formatNumber(item.totalPrice) : ''}
                    </Text>
                  </View>
                  <View style={detailStyles.colNote}>
                    <Text style={detailStyles.cell}>{item.note || ''}</Text>
                  </View>
                </View>
              ))}
            </View>

            <View style={detailStyles.processTotal}>
              <Text style={detailStyles.processTotalLabel}>
                {getProcessName(process.id)} 소계 :
              </Text>
              <Text style={detailStyles.processTotalAmount}>
                {formatNumber(process.total)}원
              </Text>
            </View>
          </View>
        );

        currentHeight += processHeight + PROCESS_MARGIN;
      });

      // 마지막 페이지 추가
      if (currentPage.length > 0) {
        pages.push(
          <Page key={pages.length} size="A4" style={detailStyles.page}>
            {currentPage}
          </Page>
        );
      }

      return pages;
    };

    // Document 반환부 수정
    return (
      <Document>
        {firstPage}
        {generateDetailPages()}
      </Document>
    );
  };

  // handleGeneratePDF 함수 수정
  const handleGeneratePDF = async () => {
    try {
      const blob = await pdf(<MyDocument />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `아마레디자인 (${selectedAddress}) - 견적서.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('PDF 생성 중 오류:', error);
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  // 비고 입력 중간 상태를 위한 state 추가
  const [tempNote, setTempNote] = useState('');
  const [originalNote, setOriginalNote] = useState('');

  // 비고 입력 시작 시 본 값 저
  const handleNoteStart = (processId) => {
    setEditingNote(processId);
    setTempNote(notes[processId] || '');
    setOriginalNote(notes[processId] || '');
  };

  // 비고 입력 중 임시 장
  const handleNoteInput = (processId, value) => {
    setTempNote(value);
    setNotes(prev => ({
      ...prev,
      [processId]: value
    }));
  };

  // 비고 입력 완료 시 수��이력 추가
  const handleNoteComplete = (processId) => {
    if (isEditingMode) {
      console.group('📝 비고 수정 완료');
      console.log('Process ID:', processId);
      console.log('이전 값:', originalNote);
      console.log('새로운 값:', tempNote);
      
      // 빈 문자��로 변경되는 경우도 처리
      if (originalNote !== tempNote) {
        // 이전에 수정이력이 있었는지 확인
        const existingModification = Object.entries(pendingModifications)
          .find(([key, mod]) => mod.type === 'note' && mod.processId === processId);

        if (existingModification) {
          // 원래 값으로 돌아간 경우 수정이력 제거
          if (tempNote === existingModification[1].originalValue) {
            setPendingModifications(prev => {
              const newMods = { ...prev };
              delete newMods[existingModification[0]];
              return newMods;
            });
            setModificationCount(prev => prev - 1);
            console.log('수정 카운터 감소됨');
          }
        } else {
          // 새로 수정이력 추가
          setPendingModifications(prev => ({
            ...prev,
            [`note_${processId}`]: {
              type: 'note',
              processId: processId,
              originalValue: originalNote,
              modifiedValue: tempNote,
              modifiedAt: new Date().toISOString()
            }
          }));
          setModificationCount(prev => prev + 1);
          console.log('수정 카운터 증가됨');
        }
      }
      
      console.groupEnd();
    }
    
    setEditingNote(null);
    setTempNote('');
    setOriginalNote('');
  };

  const startLoadingProcess = async (callback) => {
    setIsLoading(true);
    setLoadingProgress(0);

    // 프로그레스 바 애니이션
    const startTime = Date.now();
    const duration = 2000; // 2초

    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min((elapsed / duration) * 100, 100);
      setLoadingProgress(progress);

      if (progress < 100) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);

    // 실제 작업 수행
    try {
      await new Promise(resolve => setTimeout(resolve, 500)); // 초기 지연
      await callback();
    } catch (error) {
      console.error('로딩  오류:', error);
    }

    // 최소 2초 보장
    const elapsed = Date.now() - startTime;
    if (elapsed < duration) {
      await new Promise(resolve => setTimeout(resolve, duration - elapsed));
    }

    setIsLoading(false);
    setLoadingProgress(0);
  };

  const LoadingProgress = () => {
    if (!isLoading) return null;

    return (
      <div className="loading-overlay">
        <div className="loading-container">
          <div className="loading-bar">
            <div 
              className="loading-progress" 
              style={{ width: `${loadingProgress}%` }} 
            />
          </div>
          <div className="loading-text">데이터를 불러오는 중...</div>
        </div>
      </div>
    );
  };

  // handleSendEmail 함수 수정
  const handleSendEmail = async (email, subject, content) => {
    try {
      // PDF 생성
      const pdfBlob = await pdf(<MyDocument />).toBlob();
      
      // PDF를 Base64로 변환
      const arrayBuffer = await pdfBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      let binaryString = '';
      uint8Array.forEach(byte => {
        binaryString += String.fromCharCode(byte);
      });
      const base64Pdf = btoa(binaryString);

      // 이메일 전송 요청
      const response = await fetch('https://us-central1-interior-one-click.cloudfunctions.net/sendEstimatePdfHttp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: email,
          subject: subject,
          html: content,
          attachments: [{
            filename: `아마레디자인 (${selectedAddress}) - 견적서.pdf`,
            content: base64Pdf,
            encoding: 'base64'
          }]
        })
      });

      if (!response.ok) {
        throw new Error('이메일 전송에 실패했습니다.');
      }

      return true;
    } catch (error) {
      console.error('이메일 전송 중 오류:', error);
      throw error;
    }
  };

  // 삭제된 항목을 관리하기 위한 state 추가
  const [deletedItems, setDeletedItems] = useState({});

  // 항목 삭제 처리 함수 추가
  const handleItemDelete = (process, item) => {
    if (!isEditMode) return;
    if (!selectedAddress) {
      if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으로 이��하시겠습니까?')) {
        const addressComponent = document.querySelector('.desktop-ongoing-container');
        if (addressComponent) {
          addressComponent.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
      return;
    }
    
    if (!isEditingMode) {
      setPendingEditItem({ process, item });
      setShowSelectVersionModal(true);  // EditStartModal 대신 SelectVersionModal 표시
      return;
    }

    if (window.confirm('이 항목을 삭제하시습니까?')) {
      console.group('🗑️ 항목 삭제');
      console.log('삭제할 항목:', {
        processId: process.id,
        uniqueId: item.uniqueId,
        name: item.name
      });

      // 삭제된 항목 상태 업데이트
      setDeletedItems(prev => ({
        ...prev,
        [item.uniqueId]: true
      }));

      // 수정 이력에 삭제 기록 추가
      setPendingModifications(prev => ({
        ...prev,
        [`delete_${item.uniqueId}`]: {
          type: 'delete',
          processId: process.id,
          itemId: item.uniqueId,
          originalData: {
            name: item.name,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            unit: item.unit,
            note: item.note || '',
            totalPrice: item.totalPrice
          },
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);

      // UI 업데이트
      setProcessData(prevData => {
        return prevData.map(p => {
          if (p.id !== process.id) return p;

          const updatedItems = p.items.filter(i => i.uniqueId !== item.uniqueId);
          return {
            ...p,
            items: updatedItems,
            total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
          };
        });
      });

      console.log('✅ 항목 삭제 료');
      console.groupEnd();
    }
  };

  // 삭제된 항목 복원 함수 추가
  const restoreDeletedItem = (processId, itemId) => {
    if (!isEditingMode) return;

    const modification = pendingModifications[`delete_${itemId}`];
    if (!modification) return;

    setDeletedItems(prev => {
      const newDeletedItems = { ...prev };
      delete newDeletedItems[itemId];
      return newDeletedItems;
    });

    setPendingModifications(prev => {
      const newModifications = { ...prev };
      delete newModifications[`delete_${itemId}`];
      return newModifications;
    });
    setModificationCount(prev => prev - 1);

    // UI 업데이트
    setProcessData(prevData => {
      return prevData.map(p => {
        if (p.id !== processId) return p;

        const restoredItem = {
          ...modification.originalData,
          uniqueId: itemId
        };

        const updatedItems = [...p.items, restoredItem];
        return {
          ...p,
          items: updatedItems,
          total: updatedItems.reduce((sum, i) => sum + (i.totalPrice || 0), 0)
        };
      });
    });
  };

  // 필터 변경 핸들러 추가
  const handleDesignChange = (value) => {
    setSelectedDesign(value);
    fetchProcessData(); // 즉시 데이터 다시 로드
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'design',
          originalValue: selectedDesign,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }
  };

  const handleAddItem = (processId) => {
    if (!isEditMode || !isEditingMode) return;
    setSelectedProcessId(processId);
    setShowAddItemModal(true);
  };

  const saveNewItem = (processId, newItem) => {
    console.group('➕ 품목 추가');
    console.log('공정:', processId);
    console.log('새 품목:', newItem);

    const uniqueId = `new_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const itemWithId = {
      ...newItem,
      uniqueId,
      processId
    };

    setPendingModifications(prev => ({
      ...prev,
      [`add_${uniqueId}`]: {
        type: 'add',
        processId,
        itemId: uniqueId,
        newData: itemWithId,
        modifiedAt: new Date().toISOString()
      }
    }));
    setModificationCount(prev => prev + 1);

    setProcessData(prevData => {
      return prevData.map(p => {
        if (p.id !== processId) return p;

        const updatedItems = [...p.items, itemWithId];
        return {
          ...p,
          items: updatedItems,
          total: updatedItems.reduce((sum, i) => {
            const itemTotal = parseInt(i.totalPrice) || 0;
            return sum + itemTotal;
          }, 0)
        };
      });
    });

    console.log('✅ 품목 추가 완료');
    console.groupEnd();
  };

  // handleInterimSave 함수 수정
  const handleInterimSave = useCallback(async () => {
    try {
      if (!selectedAddress || !currentVersion) {
        alert('현재 수정 중인 버전이 없습니다.');
        return;
      }

      // 현재 상태 수집
      const currentState = {
        processData: processData,
        filters: {
          size: selectedSize,
          grade: selectedGrade,
          extra: selectedExtra,
          window: selectedWindow,
          painting: selectedPainting,
          furniture: selectedFurniture,
          ac: selectedAC,
          design: selectedDesign,
          floor_tile: selectedFloorTile
        },
        notes,
        processOrder: collectCurrentOrder()
      };

      const updatedVersion = {
        ...currentVersion,
        filters: currentState.filters,
        notes: currentState.notes,
        modifications: {
          ...(currentVersion.modifications || {}),
          ...pendingModifications
        },
        processOrder: currentState.processOrder,
        lastSaved: new Date().toISOString()
      };

      // Firebase에만 저장
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      await updateDoc(docRef, {
        versions: versionHistory.map(v => 
          v.versionId === currentVersion.versionId ? updatedVersion : v
        ),
        lastModified: new Date().toISOString(),
        currentState: currentState
      });

      alert('중간저장이 완료되었습니다.');

    } catch (error) {
      console.error('중간저장 오류:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  }, [
    selectedAddress,
    currentVersion,
    processData,
    selectedSize,
    selectedGrade,
    selectedExtra,
    selectedWindow,
    selectedPainting,
    selectedFurniture,
    selectedAC,
    selectedDesign,
    selectedFloorTile,
    notes,
    pendingModifications,
    versionHistory,
    collectCurrentOrder
  ]); // 필요한 의존성들 추가

  // useEffect 수정
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Ctrl + S 또는 Cmd + S (Mac)
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
        // 브라우저 기본 동작 방지
        event.preventDefault();
        event.stopPropagation();
        
        // 수정 모드이고 현재 버전이 있을 때만 저장
        if (isEditingMode && currentVersion) {
          console.log('중간저장 단축키 감지:', {
            isEditingMode,
            hasCurrentVersion: !!currentVersion
          });
          handleInterimSave();
        }
        
        // false 반환으로 이벤트 전파 중��
        return false;
      }
    };

    // 이벤트 캡처링 페이즈에서 실행되도록 true 설정
    document.addEventListener('keydown', handleKeyPress, { 
      capture: true,  // 캡처링 페이즈
      passive: false  // preventDefault() 허용
    });
    
    return () => {
      document.removeEventListener('keydown', handleKeyPress, {
        capture: true,
        passive: false
      });
    };
  }, [isEditingMode, currentVersion, handleInterimSave]);

  // 6. 수정 완료 시 저장 함수 수
  const handleSaveVersion = async (versionName) => {
    try {
      console.group('💾 버전 저장');
      console.log('버전:', versionName);
      console.log('현재 수정이력:', pendingModifications);

      if (!selectedAddress) {
        console.warn('주소가 선택되지 않음');
        return;
      }

      const currentFilters = {
        size: selectedSize,
        grade: selectedGrade,
        extra: selectedExtra,
        window: selectedWindow,
        painting: selectedPainting,
        furniture: selectedFurniture,
        ac: selectedAC,
        design: selectedDesign,
        floor_tile: selectedFloorTile
      };

      console.log('현재 필터:', currentFilters);

      const processOrderData = collectCurrentOrder();
      console.log('프로세스 순서:', processOrderData);

      const newVersion = {
        versionId: `v_${Date.now()}`,
        versionName: versionName,
        filters: currentFilters,
        notes: notes,
        modifications: pendingModifications,
        processOrder: processOrderData,
        createdAt: new Date().toISOString()
      };

      console.log('새 버전 데이터:', newVersion);

      const docRef = doc(db, 'estimateModifications', selectedAddress);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          versions: arrayUnion(newVersion),
          currentVersion: newVersion.versionId,
          lastModified: new Date().toISOString()
        });
      } else {
        await setDoc(docRef, {
          versions: [newVersion],
          currentVersion: newVersion.versionId,
          lastModified: new Date().toISOString()
        });
      }

      setVersionHistory(prev => [...prev, newVersion]);
      setPendingModifications({});
      setModificationCount(0);
      setIsEditingMode(false);
      setNewVersionName(null);
      setModalType(null);
      
      console.log('✅ 버전 장 완료');
      alert('저장이 ���료되었습니다.');

      console.groupEnd();
    } catch (error) {
      console.error('❌ 버전 저장 중 오류:', error);
      alert('저장 중 오류가 발생했습니다.');
      console.groupEnd();
    }
  };

  // 상단에 import 추가
  const handleAiSuggest = async (processId) => {
    if (!isEditMode || !isEditingMode) return;
    
    try {
      const process = processData.find(p => p.id === processId);
      if (!process) return;

      setSelectedProcessForAI(process);
      setShowAITemplateModal(true);
      
    } catch (error) {
      console.error('AI 추천 중 오류:', error);
      alert('AI 추천 중 오류가 발생했습니다.');
    }
  };

  // handleSaveAITemplate 함수 수정
  const handleSaveAITemplate = async (templateData, action) => {
    try {
      console.group('🤖 AI 템플릿 적용');
      console.log('Action:', action);
      console.log('Template Data:', templateData);

      if (action === 'replace') {
        // 수정 이력에 가할 설명 생성
        const templateDescription = `${getProcessName(templateData.processId)} 공정에 AI 템플릿 적용`;
        const itemsDescription = templateData.items.map(item => 
          `${item.name} (${item.quantity}${item.unit}, ${item.unitPrice.toLocaleString()}원)`
        ).join(', ');

        console.log('생성된 설명:', {
          templateDescription,
          itemsDescription
        });

        // 현재 수정이력 상태 확인
        console.log('현재 수정이력:', pendingModifications);

        const modificationKey = `template_${templateData.processId}_${Date.now()}`;
        const newModification = {
          type: 'template_apply',
          processId: templateData.processId,
          templateId: templateData.templateId,
          items: templateData.items,
          modifiedAt: new Date().toISOString(),
          description: templateDescription,
          itemsDescription: itemsDescription,
          changeDescription: `AI 템플릿 적용: ${templateData.items.length}개 품목 변경`
        };

        console.log('새로운 수정이력:', {
          key: modificationKey,
          modification: newModification
        });

        // 수정 이력에 추가
        setPendingModifications(prev => {
          const updated = {
            ...prev,
            [modificationKey]: newModification
          };
          console.log('업데이트된 수정이력:', updated);
          return updated;
        });

        setModificationCount(prev => {
          const newCount = prev + 1;
          console.log('수정 카운트 업데이트:', prev, '->', newCount);
          return newCount;
        });

        // 견적서 데이터 업데이트
        setProcessData(prevData => {
          const updatedData = prevData.map(process => {
            if (process.id === templateData.processId) {
              const updatedItems = templateData.items.map(item => ({
                ...item,
                totalPrice: parseInt(item.quantity) * parseInt(item.unitPrice)
              }));

              return {
                ...process,
                items: updatedItems,
                total: updatedItems.reduce((sum, item) => 
                  sum + (parseInt(item.totalPrice) || 0), 0)
              };
            }
            return process;
          });

          console.log('견적서 데이터 업데이트:', {
            before: prevData.find(p => p.id === templateData.processId),
            after: updatedData.find(p => p.id === templateData.processId)
          });

          return updatedData;
        });

        console.log('✅ AI 템플릿 적용 완료');
      }

      console.groupEnd();
    } catch (error) {
      console.error('❌ AI 템플릿 처 중 오류:', error);
      alert('템플릿 처리 중 오류가 발생했습니다.');
      console.groupEnd();
    }
  };

  // 컴포넌트 마운트 시 소계가 0인 공정들을 자동으�� 숨김 처리
  useEffect(() => {
    const autoHideZeroTotalProcesses = () => {
      const newHiddenProcesses = { ...hiddenProcesses };
      processData.forEach(process => {
        // 수동으로 설정된 상태가 없는 경우에만 자동 숨김 적용
        if (!newHiddenProcesses[process.id]?.type) {
          newHiddenProcesses[process.id] = {
            hidden: process.total === 0,
            type: 'auto'
          };
        }
      });
      setHiddenProcesses(newHiddenProcesses);
    };

    autoHideZeroTotalProcesses();
  }, [processData]); // processData가 변경될 때마다 실행

  // processData 필터링 함수 수정
  const filteredProcessData = processData.filter(process => 
    !hiddenProcesses[process.id]?.hidden
  );

  // toggleProcessVisibility 함수 수정
  const toggleProcessVisibility = async (processId) => {
    try {
      const process = processData.find(p => p.id === processId);
      const newHiddenProcesses = {
        ...hiddenProcesses,
        [processId]: {
          hidden: !hiddenProcesses[processId]?.hidden,
          type: 'manual'  // 수동 설정임을 표시
        }
      };
      
      // Firebase에 저장
      if (selectedAddress) {
        const docRef = doc(db, 'estimateModifications', selectedAddress);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            hiddenProcesses: newHiddenProcesses,
            lastModified: new Date().toISOString()
          });
        } else {
          await setDoc(docRef, {
            hiddenProcesses: newHiddenProcesses,
            lastModified: new Date().toISOString()
          });
        }
      }
      
      setHiddenProcesses(newHiddenProcesses);
    } catch (error) {
      console.error('공정 숨김 상태 저장 중 오류:', error);
    }
  };

  // 버전 선택 시 상태 복원 함수 추가
  const restoreVersionState = async (version) => {
    try {
      console.group('🔄 버전 상태 복원');
      
      const docRef = doc(db, 'estimateModifications', selectedAddress);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        console.warn('문서가 존재하지 않음');
        console.groupEnd();
        return;
      }

      const data = docSnap.data();
      const currentState = data.currentState;

      if (currentState) {
        // 필터 상태 ���원
        await updateFilters(currentState.filters);
        
        // 노트 ��원
        setNotes(currentState.notes);
        
        // 프로세스 데이터 복원
        setProcessData(currentState.processData);
        
        // 프로세스 순서 복원
        if (currentState.processOrder) {
          setProcessData(prevData => applyOrder(prevData, currentState.processOrder));
        }
      }

      console.log('✅ 상태 복원 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 상태 복원 중 오류:', error);
      console.groupEnd();
    }
  };

  // useEffect 수정 - 버전 변경 시 상태 복원
  useEffect(() => {
    if (currentVersion && !isEditingMode && !skipRestore) {
      // skipRestore가 false일 때만 상태 복원
      restoreVersionState(currentVersion);
    }
  }, [currentVersion?.versionId, isEditingMode, skipRestore]);

  // 키보드 단축키 이벤트 리스너 수정
  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        
        if (isEditingMode && currentVersion) {
          handleInterimSave();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isEditingMode, currentVersion, handleInterimSave]);

  // 데이터 변경 감지를 위한 useEffect 추가
  useEffect(() => {
    console.group('🔄 processData 변경 감지');
    console.log('변경된 processData:', processData);
    console.log('현재 버전:', currentVersion);
    console.log('대기 중인 수정사항:', pendingModifications);
    console.groupEnd();
  }, [processData]);

  // 버전 관리 관련 로그 추가
  useEffect(() => {
    console.group('📝 버전 상태 변경');
    console.log('현재 버전:', currentVersion);
    console.log('버전 히스토리:', versionHistory);
    console.groupEnd();
  }, [currentVersion, versionHistory]);

  // handleEditItem 함수 추가 (saveModification 함수 근처에 위치시키면 좋습니다)
  const handleEditItem = (updatedItem) => {
    if (!isEditMode) return;
    
    const processId = editingItem.processId;
    const itemId = editingItem.item.uniqueId;
    
    saveModification(processId, itemId, {
      ...updatedItem,
      uniqueId: itemId
    });
    
    setModalType(null);
    setEditingItem(null);
  };

  return (
    <div className="desktop-estimate">
      <div className="desktop-ongoing-container">
        {isEditMode && (
          <div className="admin-buttons">
            <div className="edit-buttons-group">
              {!isEditingMode ? (
                <>
                  {/* 현장주소입력 버튼을 수정 시작 버튼 위에 배치 */}
                  <button 
                    className="edit-complete-button"
                    onClick={() => navigate('/address-management')}
                  >
                    현장주소입력
                  </button>
                  <button 
                    className="edit-complete-button"
                    onClick={handleStartEdit}
                  >
                    수정 시작
                  </button>
                </>
              ) : (
                <>
                  <button 
                    className="edit-complete-button"
                    onClick={() => navigate('/address-management')}
                  >
                    현장주소입력
                  </button>
                  <button 
                    className="edit-complete-button"
                    onClick={() => {
                      if (newVersionName) {
                        handleSaveVersion(newVersionName);
                      } else {
                        setModalType('saveVersion');
                      }
                    }}
                    disabled={modificationCount === 0}
                  >
                    수정 완료 ({modificationCount})
                  </button>
                  <button 
                    className="edit-cancel-button"
                    onClick={() => {
                      if (window.confirm('진행 중인 수정사항이 모두 취소됩니다. 계속하시겠습니까?')) {
                        setIsEditingMode(false);
                        setPendingModifications({});
                        setModificationCount(0);
                        setNewVersionName(null);
                        loadModifications();
                      }
                    }}
                  >
                    수정 취소
                  </button>
                  <HiddenProcessList 
                    hiddenProcesses={hiddenProcesses}
                    onToggle={toggleProcessVisibility}
                    getProcessName={getProcessName}
                    showModal={showHiddenProcessModal}
                    onShowModal={() => setShowHiddenProcessModal(true)}
                    onCloseModal={() => setShowHiddenProcessModal(false)}
                  />
                </>
              )}
            </div>
            <div className="management-buttons-group">
              <button 
                className="history-button"
                onClick={() => {
                  loadModificationHistory();
                  setShowHistoryModal(true);
                }}
              >
                수정 이력
              </button>
              {isEditingMode && currentVersion && (
                <button 
                  className="interim-save-button"
                  onClick={handleInterimSave}
                >
                  중간저장
                </button>
              )}
              <button 
                className="database-button"
                onClick={() => setShowDatabaseManager(true)}
              >
                데이터베이스 관리
              </button>
              <button 
                className="pdf-button"
                onClick={handleGeneratePDF}
              >
                PDF 견적서
              </button>
              <button 
                className="pdf-button"
                onClick={() => {
                  if (!selectedAddress) {
                    if (window.confirm('주소를 먼저 선택해주세요. 주소 선택 화면으 이동하시겠습니까?')) {
                      const addressComponent = document.querySelector('.desktop-ongoing-container');
                      if (addressComponent) {
                        addressComponent.scrollIntoView({ 
                          behavior: 'smooth',
                          block: 'center'
                        });
                      }
                    }
                    return;
                  }
                  setShowEmailModal(true);
                }}
              >
                이메일로 보내기
              </button>
            </div>
          </div>
        )}
        <OngoingAddressesToggle 
          onSelectAddress={handleSelectAddress}
          onAdminLogin={handleAdminLogin}
        />
      </div>
      
      <div className="filter-container">
        <div className="filter-row-1">
          <div className="size-filter">
            <SizeFilter
              selectedSize={selectedSize}
              isEditMode={isEditMode}
              isEditingMode={isEditingMode}
              selectedAddress={selectedAddress}
              onSizeChange={setSelectedSize}
              onShowSelectVersionModal={setShowSelectVersionModal}
              setPendingModifications={setPendingModifications}
              setModificationCount={setModificationCount}
              onFetchData={fetchProcessData}
            />
          </div>
          <div className="grade-filter">
            <GradeFilter
              selectedGrade={selectedGrade}
              isEditMode={isEditMode}
              isEditingMode={isEditingMode}
              selectedAddress={selectedAddress}
              onGradeChange={setSelectedGrade}
              onShowSelectVersionModal={setShowSelectVersionModal}
              setPendingModifications={setPendingModifications}
              setModificationCount={setModificationCount}
              onFetchData={fetchProcessData}
            />
          </div>
          <ExtraFilter
            selectedExtra={selectedExtra}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onExtraChange={setSelectedExtra}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
          <WindowFilter
            selectedWindow={selectedWindow}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onWindowChange={setSelectedWindow}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
          <PaintingFilter
            selectedPainting={selectedPainting}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onPaintingChange={setSelectedPainting}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
        </div>
        
        <div className="filter-row-2">
          <FurnitureFilter
            selectedFurniture={selectedFurniture}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onFurnitureChange={setSelectedFurniture}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
          <ACFilter
            selectedAC={selectedAC}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onACChange={setSelectedAC}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
          <DesignFilter
            selectedDesign={selectedDesign}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onDesignChange={setSelectedDesign}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
          <FloorTileFilter
            selectedFloorTile={selectedFloorTile}
            isEditMode={isEditMode}
            isEditingMode={isEditingMode}
            selectedAddress={selectedAddress}
            onFloorTileChange={setSelectedFloorTile}
            onShowSelectVersionModal={setShowSelectVersionModal}
            setPendingModifications={setPendingModifications}
            setModificationCount={setModificationCount}
            onFetchData={fetchProcessData}
          />
        </div>
      </div>

      <div className="estimate-header">
        <div className="header-content">
          <div className="header-left">
            <h2>견적서</h2>
            <h3>Estimate</h3>
          </div>
          <div className="info-item logo">
            <div className="logo-container">
              <img 
                src="/images/logo.png" 
                alt="로고"
                style={{
                  width: '90px',
                  height: 'auto'
                }}
              />
            </div>
          </div>
          <div className="info-item">
            <p className="label">업체정보</p>
            <p>Amare design, 아마레디자인</p>
            <p>712.17.01551</p>
            <p>수성구 들안로 215</p>
            <p>010. 8694.4078</p>
            <p>대표 권경대</p>
          </div>
          <div className="info-item address">
            <p className="label">주소</p>
            <p>{selectedAddress || '주소를 선택해주세요'}</p>
          </div>
          <div className="info-item total">
            <p className="label">TOTAL</p>
            <p className="amount">
              {formatNumber(calculateTotal(filteredProcessData))}원  {/* processData를 filteredProcessData로 변경 */}
              <span></span>
            </p>
            <p className="vat-notice">(VAT 별도)</p>
          </div>
        </div>
      </div>

      <div className="estimate-content">
        <div className="estimate-table-title">
          <h3>공정별 합계</h3>
        </div>
        <div className="process-summary-table">
          <div className="table-header">
            <div className="col process">공정</div>
            <div className="col price">가격</div>
            <div className="col note">비고</div>
          </div>
          
          {/* 0원인 공정 필터링 */}
          {filteredProcessData.map((process) => (
            <div key={process.id} className="table-row">
              <div className="col process">{getProcessName(process.id)}</div>
              <div className="col price">
                {formatNumber(process.total)}
              </div>
              <div 
                className="col note"
                onClick={() => isEditingMode && handleNoteStart(process.id)}
              >
                {editingNote === process.id ? (
                  <input
                    type="text"
                    value={notes[process.id] || ''}
                    onChange={(e) => handleNoteInput(process.id, e.target.value)}
                    onBlur={() => handleNoteComplete(process.id)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleNoteComplete(process.id);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <span className={notes[process.id] ? '' : 'empty-note'}>
                    {notes[process.id] || (isEditingMode ? '클릭하여 입력' : '-')}
                  </span>
                )}
              </div>
            </div>
          ))}

          <div className="process-total">
            <span className="total-label">총 합계 :</span>
            <span className="total-amount">
              {formatNumber(calculateTotal(filteredProcessData))}원
            </span>
          </div>
        </div>

        <div className="estimate-table">
          <div className="estimate-table-title">
            <h3>상세 견적서</h3>
          </div>
          
          <div className="table-header">
            <div className="col item">품목</div>
            <div className="col quantity">수량</div>
            <div className="col unit">단위</div>
            <div className="col unit-price">단가</div>
            <div className="col amount">금액</div>
            <div className="col note">비고</div>
          </div>

          {/* 상세 견적서에서도 0원인 공정 필터링 */}
          {filteredProcessData.map((process, processIndex) => (
            <div key={processIndex} className="process-section">
              <div className="process-name">
                <HideProcessButton 
                  processId={process.id}
                  isHidden={hiddenProcesses[process.id]?.hidden}
                  onToggle={toggleProcessVisibility}
                />
                <span>{getProcessName(process.id)}</span>
                {isEditMode && isEditingMode && (
                  <>
                    <AISuggestButton 
                      onClick={handleAiSuggest}
                      processId={process.id}
                      processName={getProcessName(process.id)}
                    />
                    <button 
                      className="add-item-button"
                      onClick={() => handleAddItem(process.id)}
                      title="품목 추가"
                    >
                      +
                    </button>
                  </>
                )}
              </div>
              
              <DragHandleItemList
                items={process.items}
                onReorder={(processId, newItems, moveInfo) => {
                  setProcessData(prevData => 
                    prevData.map(p => 
                      p.id === processId 
                        ? { ...p, items: newItems }
                        : p
                    )
                  );
                  
                  if (isEditingMode) {
                    setPendingModifications(prev => ({
                      ...prev,
                      [`move_${process.id}_${Date.now()}`]: {
                        type: 'move',
                        processId: process.id,
                        originalOrder: process.items.map(item => item.uniqueId),
                        newOrder: newItems.map(item => item.uniqueId),
                        description: moveInfo.description,  // 이동 설명 추가
                        modifiedAt: new Date().toISOString()
                      }
                    }));
                    setModificationCount(prev => prev + 1);
                  }
                }}
                processId={process.id}
                isEditMode={isEditMode && isEditingMode}
                onEdit={(item) => handleItemEdit(process, item)}
                onDelete={(item) => handleItemDelete(process, item)}
                formatNumber={formatNumber}
                getProcessName={getProcessName}  // 추가
              />
              
              <div className="process-subtotal">
                <span className="subtotal-label">
                  {getProcessName(process.id)} 소계 :
                </span>
                <span className="subtotal-amount">
                  {formatNumber(process.total)}원
                </span>
              </div>
            </div>
          ))}
        </div>
        
        <div className="estimate-footer">
          <div className="total-sum-container">
            <div className="total-sum">
              <span className="label">총 합계(VAT별도) :</span>
              <span className="amount">
                {formatNumber(calculateTotal(filteredProcessData))}
                <span className="currency">원</span>
              </span>
            </div>
            <div className="total-sum vat-included">
              <span className="label">총 합계(VAT포함) :</span>
              <span className="amount">
                {formatNumber(Math.round(calculateTotal(filteredProcessData) * 1.1))}
                <span className="currency">원</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {editingItem && (
        <EditItemModal
          item={editingItem.item}
          processId={editingItem.processId} // processId 추가
          onSave={(updatedItem) => {
            handleEditItem(updatedItem);
            setEditingItem(null);
          }}
          onClose={() => setEditingItem(null)}
        />
      )}

      {modalType === 'saveVersion' && (
        <VersionSaveModal
          onSave={handleSaveVersion}
          onClose={() => setModalType(null)}
          currentVersion={currentVersion}  // currentVersion 전달
        />
      )}

      {showHistoryModal && (
        <VersionHistory
          versions={versionHistory}
          onClose={() => setShowHistoryModal(false)}
          onRestore={restoreVersion}
          onReset={resetModifications}
          onDeleteVersion={handleDeleteVersion}
          getProcessName={getProcessName}  // prop 전달
        />
      )}

      {showDatabaseManager && (
        <DatabaseManager
          onClose={() => setShowDatabaseManager(false)}
        />
      )}

      {showSelectVersionModal && (
        <SelectVersionModal
          versions={[...versionHistory].reverse()}
          onSelectVersion={handleVersionSelect}
          onNewVersion={handleNewVersion}
          onClose={() => {
            setShowSelectVersionModal(false);
            setPendingEditItem(null);  // 보류 중인 편집 항목도 초기화
            setPendingModifications({});  // 보류 중인 수정사항도 초기화
          }}
        />
      )}

      {showEmailModal && (
        <EmailPdfModal
          onClose={() => setShowEmailModal(false)}
          onSend={async (email, subject, content) => {
            try {
              // PDF 생성
              const pdfBlob = await pdf(<MyDocument />).toBlob();
              
              // PDF를 Base64로 변환
              const arrayBuffer = await pdfBlob.arrayBuffer();
              const uint8Array = new Uint8Array(arrayBuffer);
              let binaryString = '';
              uint8Array.forEach(byte => {
                binaryString += String.fromCharCode(byte);
              });
              const base64Pdf = btoa(binaryString);

              // 이메일 전송 요청
              const response = await fetch('https://us-central1-interior-one-click.cloudfunctions.net/sendEstimatePdfHttp', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  to: email,
                  subject: subject,
                  html: content,
                  attachments: [{
                    filename: `아마레디자인 (${selectedAddress}) - 견적서.pdf`,
                    content: base64Pdf,
                    encoding: 'base64'
                  }]
                })
              });

              if (!response.ok) {
                throw new Error('이메일 전송에 실패했습니다.');
              }

              return true;
            } catch (error) {
              console.error('이메일 전송 중 오류:', error);
              throw error;
            }
          }}
        />
      )}

      {showAddItemModal && (
        <AddItemModal
          processId={selectedProcessId}
          onSave={(newItem) => {
            saveNewItem(selectedProcessId, newItem);
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
          onClose={() => {
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
        />
      )}

      {showAITemplateModal && selectedProcessForAI && (
        <AITemplateModal
          processId={selectedProcessForAI.id}
          processName={getProcessName(selectedProcessForAI.id)}
          currentItems={selectedProcessForAI.items}
          onSave={handleSaveAITemplate}
          onClose={() => {
            setShowAITemplateModal(false);
            setSelectedProcessForAI(null);
          }}
        />
      )}

      <LoadingProgress />
    </div>
  );
};

export default DesktopEstimate; 