import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentHistory.css';
import OngoingAddressesToggle from './OngoingAddressesToggle';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp, getDoc, updateDoc, deleteField } from 'firebase/firestore';

const formatNumber = (num) => {
  if (num === null || num === undefined) return '';
  const numStr = num.toString().replace(/[^\d]/g, '');
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 확인 메시지 컴포넌트
const ConfirmMessage = ({ message }) => {
  return (
    <div className="confirm-message">
      <p>{message}</p>
    </div>
  );
};

// ConfirmModal 컴포넌트 수정
const ConfirmModal = ({ message, onConfirm, onCancel, showCancelButton }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === ' ' || e.key === 'Spacebar') {
        e.preventDefault();
        onConfirm();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onConfirm]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>확인</button>
          {showCancelButton && <button onClick={onCancel}>취소</button>}
        </div>
      </div>
    </div>
  );
};

const PaymentHistoryItem = ({ onDelete, index, onSave, showMessage, initialData = {}, totalAmount, isAdmin, onUpdateTotalAmount }) => {
  const [date, setDate] = useState(initialData.date || '');
  const [amount, setAmount] = useState(formatNumber(initialData.amount) || '');
  const [isPaymentComplete, setIsPaymentComplete] = useState(initialData.isPaymentComplete || false);
  const [isTaxBillIssued, setIsTaxBillIssued] = useState(initialData.isTaxBillIssued || false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmMessage] = useState(false);
  const [confirmMessage] = useState('');

  const handleAmountChange = (e) => {
    const formattedValue = formatNumber(e.target.value);
    setAmount(formattedValue);
  };

  const handleSave = () => {
    const paymentData = {
      date,
      amount: amount.replace(/,/g, ''),
      isPaymentComplete,
      isTaxBillIssued
    };
    onSave(paymentData, index);
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleCopy = () => {
    const formattedAmount = formatNumber(amount);
    const formattedTotalAmount = formatNumber(totalAmount.toString());
    const copyText = `${date} 아마레디자인에 ${formattedAmount}원 결제 해주셨습니다. 현재 ${formattedTotalAmount}원 결제 해주셨습니다. 감사합니다.`;
    
    navigator.clipboard.writeText(copyText).then(() => {
      console.log('Copying to clipboard was successful!');
      showMessage('복사되었습니다.');
    }, function(err) {
      console.error('Could not copy text: ', err);
      showMessage('복사 중 오류가 발생했습니다.');
    });
  };

  const handleConfirm = () => {
    onDelete(index);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handlePaymentCompleteChange = (e) => {
    const newIsPaymentComplete = e.target.checked;
    setIsPaymentComplete(newIsPaymentComplete);
    const paymentData = {
      date,
      amount: amount.replace(/,/g, ''),
      isPaymentComplete: newIsPaymentComplete,
      isTaxBillIssued
    };
    onSave(paymentData, index);
    onUpdateTotalAmount(); // 총 금액 업데이트
  };

  return (
    <div className="payment-item-container">
      {/* 1행: 날짜와 금액 입력 */}
      <div className="payment-item-row">
        <div className="flex-center w-full gap-2">
          <div className="input-group">
            <input
              type="text"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="date-input"
              placeholder="24년 10월 23일"
              readOnly={!isAdmin}
            />
            <input
              type="text"
              value={amount}
              onChange={handleAmountChange}
              className="amount-input"
              placeholder="0"
              readOnly={!isAdmin}
            />
          </div>
        </div>
      </div>
      
      {/* 2행: 체크박스들 */}
      <div className="payment-item-row">
        <div className="flex-center w-full">
          <div className="checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isPaymentComplete}
                onChange={handlePaymentCompleteChange}
                disabled={!isAdmin}
              />
              <span>결제 완료</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isTaxBillIssued}
                onChange={(e) => setIsTaxBillIssued(e.target.checked)}
                disabled={!isAdmin}
              />
              <span>세금계산서</span>
            </label>
          </div>
        </div>
      </div>

      {/* 3행: 버튼들 */}
      {isAdmin && (
        <div className="payment-item-row">
          <div className="flex-center w-full gap-2">
            <div className="button-group">
              <button onClick={handleSave} className="action-button">저장</button>
              <button onClick={handleDelete} className="action-button">삭제</button>
              <button onClick={handleCopy} className="action-button">Copy</button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <ConfirmModal
          message="삭제하시겠습니까?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          showCancelButton={true}
        />
      )}
      {showConfirmMessage && <ConfirmMessage message={confirmMessage} />}
    </div>
  );
};

const PaymentHistory = () => {
  const navigate = useNavigate();
  const [paymentItems, setPaymentItems] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');

  useEffect(() => {
    // 세션 스토리지에서 관리자 상태 확인
    const adminStatus = sessionStorage.getItem('isAdmin');
    setIsAdmin(adminStatus === 'true');

    // 컴포넌트가 언마운트될 때 관리자 모드 해제
    return () => {
      sessionStorage.removeItem('isAdmin');
      console.log('Admin mode deactivated');
    };
  }, []);

  const calculateTotalAmount = (items) => {
    return items.reduce((sum, item) => {
      if (item.isPaymentComplete) {
        const amount = parseInt(item.amount.replace(/,/g, '')) || 0;
        return sum + amount;
      }
      return sum;
    }, 0);
  };

  const loadPaymentData = async (addressId) => {
    if (!addressId) {
      setPaymentItems([]);
      setTotalAmount(0);
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', addressId);
      const paymentDocSnap = await getDoc(paymentDocRef);
      
      if (paymentDocSnap.exists()) {
        const paymentData = paymentDocSnap.data();
        console.log('Loaded payment data:', paymentData);
        setPaymentItems(paymentData.items || [{}]);
        
        // 총 결제 금액 계산 (결제 완료된 항목만)
        const total = calculateTotalAmount(paymentData.items || []);
        setTotalAmount(total);

        // addressInfo 필드 제거
        if (paymentData.addressInfo) {
          await updateDoc(paymentDocRef, {
            addressInfo: deleteField()
          });
          console.log('addressInfo field removed from the document');
        }
      } else {
        console.log('No payment data found for this address');
        setPaymentItems([{}]);
        setTotalAmount(0);
      }
    } catch (error) {
      console.error('Error loading payment data:', error);
      setPaymentItems([]);
      setTotalAmount(0);
    }
  };

  const handleAddItem = async () => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해세요.');
      return;
    }

    const newItem = {
      date: '',
      amount: '',
      isPaymentComplete: false,
      isTaxBillIssued: false
    };

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      let newPaymentItems;
      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        newPaymentItems = existingData.items ? [...existingData.items, newItem] : [newItem];
      } else {
        newPaymentItems = [newItem];
      }

      await setDoc(paymentDocRef, {
        items: newPaymentItems,
        addressId: selectedAddressId,
        lastUpdated: serverTimestamp()
      }, { merge: true });

      console.log('Empty item added to database');
      setPaymentItems(newPaymentItems);
      showMessage('새 항목 추가되었습니다.');
    } catch (error) {
      console.error('Error adding empty item: ', error);
      showMessage('항목 추가 중 오류가 발생했습니다: ' + error.message);
    }
  };

  const handleDeleteItem = async (index) => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해주세요.');
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        const newPaymentItems = existingData.items ? [...existingData.items] : [];
        
        // 해당 인덱스의 항목 삭제
        newPaymentItems.splice(index, 1);

        // 데이터베이스 업데이트
        await updateDoc(paymentDocRef, {
          items: newPaymentItems,
          lastUpdated: serverTimestamp()
        });

        console.log('Item deleted from database');
        setPaymentItems(newPaymentItems);
        showMessage('항목이 삭제되었습니다.');

        // 총 결제 금액 다시 계산
        const newTotal = newPaymentItems.reduce((sum, item) => {
          const amount = parseInt(item.amount.replace(/,/g, '')) || 0;
          return sum + amount;
        }, 0);
        setTotalAmount(newTotal);
      } else {
        console.log('No payment data found for this address');
        showMessage('삭제할 데이터가 없습니다.');
      }
    } catch (error) {
      console.error('Error deleting item: ', error);
      showMessage('항목 삭제 중 오류가 발생했습니다: ' + error.message);
    }
  };

  const showMessage = (message) => {
    setConfirmMessage(message);
    setShowConfirmMessage(true);
    setTimeout(() => setShowConfirmMessage(false), 3000);
  };

  const handleSaveItem = async (paymentData, index) => {
    if (!selectedAddressId) {
      showMessage('현장을 선택해주세요.');
      return;
    }

    try {
      const paymentDocRef = doc(db, 'payments', selectedAddressId);
      const paymentDocSnap = await getDoc(paymentDocRef);

      let newPaymentItems;
      if (paymentDocSnap.exists()) {
        const existingData = paymentDocSnap.data();
        newPaymentItems = existingData.items ? [...existingData.items] : [];
      } else {
        newPaymentItems = [];
      }

      if (index >= newPaymentItems.length) {
        newPaymentItems.push(paymentData);
      } else {
        newPaymentItems[index] = paymentData;
      }

      await setDoc(paymentDocRef, {
        items: newPaymentItems,
        addressId: selectedAddressId,
        lastUpdated: serverTimestamp()
      }, { merge: true });

      console.log('Document updated with ID: ', selectedAddressId);
      showMessage('저장되었습니다.');
      setPaymentItems(newPaymentItems);

      // 총 결제 금액 다시 계산 (결제 완료된 항목만)
      const newTotal = calculateTotalAmount(newPaymentItems);
      setTotalAmount(newTotal);
    } catch (error) {
      console.error('Error updating document: ', error);
      showMessage('저장 중 오류가 발생했습니다: ' + error.message);
    }
  };

  const handleSelectAddress = (addressId, paymentData) => {
    setSelectedAddressId(addressId);
    if (addressId) {
      loadPaymentData(addressId);
    } else {
      setPaymentItems([]);
      setTotalAmount(0);
    }
  };

  const handleAdminLogin = (status) => {
    setIsAdmin(status);
    sessionStorage.setItem('isAdmin', status.toString());
    console.log('Admin status updated:', status);
  };

  const handleUpdateTotalAmount = () => {
    const newTotal = calculateTotalAmount(paymentItems);
    setTotalAmount(newTotal);
  };

  return (
    <div className="payment-history-container">
      <div className="payment-history">
        <div className="header-space"></div>
        <OngoingAddressesToggle 
          onSelectAddress={handleSelectAddress}
          onAdminLogin={handleAdminLogin}
        />
        {selectedAddressId ? (
          <div className="payment-list">
            {paymentItems.map((item, index) => (
              <PaymentHistoryItem 
                key={index} 
                index={index}
                onDelete={handleDeleteItem}
                onSave={handleSaveItem}
                showMessage={showMessage}
                initialData={item}
                totalAmount={totalAmount}
                isAdmin={isAdmin}
                onUpdateTotalAmount={handleUpdateTotalAmount}
              />
            ))}
          </div>
        ) : (
          <div className="no-address-selected">
            <p>현장을 선택해주세요.</p>
          </div>
        )}
      </div>
      {isAdmin && selectedAddressId && (
        <>
          <div className="total-amount-container">
            <p className="total-amount-text">현재 {formatNumber(totalAmount)}원을 결제받았습니다.</p>
          </div>
          <div className="add-item-button-container-centered">
            <button onClick={handleAddItem} className="add-item-button">+ 결제 추가</button>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentHistory;
