import React, { useEffect } from 'react';
import './HiddenProcessList.css';

const HiddenProcessList = ({ 
  hiddenProcesses, 
  onToggle, 
  getProcessName,
  showModal,
  onShowModal,
  onCloseModal 
}) => {
  const handleRestore = (processId) => {
    onToggle(processId);
    onCloseModal();
  };

  useEffect(() => {
    if (showModal) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();  // 이벤트 전파 중단
          e.stopPropagation(); // 이벤트 버블링 중단
          onCloseModal();  // 확인 메시지 없이 바로 닫기
        }
      };

      window.addEventListener('keydown', handleKeyDown, true);  // 캡처링 페이즈에서 이벤트 처리
      return () => {
        window.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, [showModal, onCloseModal]);

  return (
    <>
      <button 
        className="hidden-process-button"
        onClick={onShowModal}
      >
        숨긴 공정 보기
      </button>

      {showModal && (
        <div className="hidden-process-modal">
          <div className="hidden-process-modal-content">
            <h3>숨긴 공정 목록</h3>
            <div className="hidden-process-list">
              {Object.entries(hiddenProcesses)
                .filter(([_, status]) => status.hidden)
                .map(([processId, status]) => (
                  <div key={processId} className="hidden-process-item">
                    <span>{getProcessName(processId)}</span>
                    <span className="hide-type">
                      {status.type === 'auto' ? '(자동)' : '(수동)'}
                    </span>
                    <button 
                      onClick={() => handleRestore(processId)}
                      className="restore-button"
                    >
                      복원
                    </button>
                  </div>
                ))}
            </div>
            <button 
              className="close-button"
              onClick={onCloseModal}
            >
              닫기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HiddenProcessList; 