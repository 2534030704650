import { db } from '../../../firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';

class AIService {
  static async getApiKeys() {
    try {
      const docRef = doc(db, 'config', 'apiKeys');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      }
      throw new Error('API 키가 설정되지 않았습니다.');
    } catch (error) {
      throw error;
    }
  }

  static async searchTemplatesWithAI(searchText, templates) {
    try {
      if (!searchText.trim() || !templates.length) {
        return templates;
      }

      const apiKeys = await this.getApiKeys();
      const geminiKey = apiKeys.gemini;

      if (!geminiKey) {
        throw new Error('Gemini API 키가 설정되지 않았습니다.');
      }

      const prompt = `검색어:"${searchText}"
템플릿:${templates.map((t, i) => `
${i + 1}.ID:${t.id} 공정:${t.processName} 요구:${t.userInput?.requirement || '-'} 예산:${t.userInput?.budget || '-'} 키워드:${t.userInput?.keywords || '-'}`).join('')}

위 템플릿 중 검색어와 관련된 템플릿의 ID와 점수(0-100)를 JSON으로 응답:
{"results":[{"id":"ID","score":점수}]}`;

      const response = await fetch(
        `https://generativelanguage.googleapis.com/v1/models/gemini-pro:generateContent?key=${geminiKey}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            contents: [{ parts: [{ text: prompt }] }],
            generationConfig: {
              temperature: 0.3,
              topP: 0.8,
              topK: 40
            }
          })
        }
      );

      if (!response.ok) {
        throw new Error(`API 오류: ${response.status}`);
      }

      const data = await response.json();
      const aiResponse = data.candidates[0].content.parts[0].text;
      
      const jsonMatch = aiResponse.match(/\{[\s\S]*\}/);
      if (!jsonMatch) {
        throw new Error('AI 응답 형식 오류');
      }

      const { results } = JSON.parse(jsonMatch[0]);
      
      return templates
        .map(template => {
          const result = results.find(r => r.id === template.id);
          if (!result) return null;
          return {
            ...template,
            score: result.score
          };
        })
        .filter(template => template && template.score > 0)
        .sort((a, b) => b.score - a.score);

    } catch (error) {
      return [];
    }
  }

  static searchTemplatesWithKeyword(searchText, templates) {
    try {
      if (!searchText.trim() || !templates.length) {
        return templates;
      }

      const searchTerms = searchText.toLowerCase().split(/\s+/);
      
      const scoredTemplates = templates.map(template => {
        let score = 0;
        
        searchTerms.forEach(term => {
          if (template.userInput?.requirement?.toLowerCase().includes(term)) score += 3;
          if (template.userInput?.budget?.toLowerCase().includes(term)) score += 3;
          if (template.userInput?.keywords?.toLowerCase().includes(term)) score += 2;
          if (template.userInput?.specialNotes?.toLowerCase().includes(term)) score += 1;
          if (template.items) {
            template.items.forEach(item => {
              if (item.name.toLowerCase().includes(term)) score += 2;
            });
          }
        });

        return {
          ...template,
          score
        };
      });

      return scoredTemplates
        .filter(template => template.score > 0)
        .sort((a, b) => b.score - a.score);

    } catch (error) {
      return [];
    }
  }
}

export default AIService; 