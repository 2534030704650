import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './VendorPrice.css';
import { db } from '../firebase';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { processWithAI, saveAiAnalysis } from '../firebase';

const VENDOR_CATEGORIES = [
  { id: 'design', name: '디자인', icon: '🎨' },
  { id: 'demolition', name: '철거폐기물', icon: '🏗️' },
  { id: 'window', name: '창호금속', icon: '🪟' },
  { id: 'plumbing', name: '배관 공사', icon: '🔧' },
  { id: 'carpentry', name: '목공사', icon: '🪚' },
  { id: 'door', name: '문공사', icon: '🚪' },
  { id: 'tile', name: '타일 공사', icon: '🔲' },
  { id: 'film', name: '필름공사', icon: '📜' },
  { id: 'floor_protection', name: '바닥 보양 공사', icon: '🛡️' },
  { id: 'wood_flooring', name: '마루 공사', icon: '🪵' },
  { id: 'floor_tile', name: '바닥 타일 공사', icon: '⬜' },
  { id: 'painting', name: '도장공사', icon: '🎨' },
  { id: 'wallpaper', name: '도배공사', icon: '📋' },
  { id: 'bathroom', name: '욕실시공', icon: '🚿' },
  { id: 'hardware', name: '하드웨어, 악세서리', icon: '🔨' },
  { id: 'electrical', name: '전기공사', icon: '⚡' },
  { id: 'furniture', name: '가구공사', icon: '🪑' },
  { id: 'silicon', name: '실리콘 공사', icon: '💧' },
  { id: 'ac', name: '에어컨공사', icon: '❄️' },
  { id: 'supervision_utilities', name: '현장감리/공과잡비', icon: '👷' },
  { id: 'misc_additional', name: '기타잡비/별도공사', icon: '🔧' }
];

// CSV 데이터 처리 및 AI 분석 함수
const processCSVWithAI = async (csvData) => {
  const prompt = `
    다음 CSV 데이터를 분석하여 각 공정별로 분류해주세요.
    
    CSV 데이터의 구조:
    - '00 공정' 컬럼의 값을 기준으로 분류
    - '02 품명', '03 수량', '04 단위', '05 단가', '06 금액' 컬럼을 사용
    - '09 평형대'와 '10 서비스등급'을 참고하여 분류
    
    각 항목을 다음 형식의 JSON으로 반환해주세요:
    {
      "categoryId": {
        "prices": [
          {
            "size": "평형대 값",
            "name": "품명",
            "quantity": "수량(숫자)",
            "unit": "단위",
            "unitPrice": "단가(숫자)",
            "totalPrice": "금액(숫자)",
            "grade": "서비스등급",
            "note": "비고"
          }
        ]
      }
    }

    categoryId는 다음 중 하나여야 합니다:
    ${VENDOR_CATEGORIES.map(cat => `"${cat.id}": "${cat.name}"`).join('\n')}
  `;

  try {
    const result = await processWithAI('gemini', prompt, csvData);
    return result;
  } catch (error) {
    console.error('AI 처리 중 오류:', error);
    return null;
  }
};

// CSV 데이터 처리 및 매핑을 위한 상수 정의
const CATEGORY_MAPPING = {
  "디자인": "design",
  "철거폐기물": "demolition",
  "창호금속": "window",
  "배관 공사": "plumbing",
  "목공사": "carpentry",
  "문공사": "door",
  "타일 공사": "tile",
  "필름공사": "film",
  "바닥 보양 공사": "floor_protection",
  "마루 공사": "wood_flooring",
  "바닥 타일 공사": "floor_tile",
  "도장공사": "painting",
  "도배공사": "wallpaper",
  "욕실시공": "bathroom",
  "하드웨어, 악세서리": "hardware",
  "전기공사": "electrical",
  "가구공사": "furniture",
  "실리콘 공사": "silicon",
  "에어컨공사": "ac",
  "현감감리n공과잡비": "supervision_utilities",
  "기타잡비n별도": "misc_additional",
  "현장감리/공과잡비": "supervision_utilities",
  "기타잡비/별도공사": "misc_additional"
};

// 데이터 정제 유틸리티 함수들
const cleanupUtils = {
  // 숫자 문자열에서 콤마 제거하고 숫자로 변환
  parseNumber: (value) => {
    if (!value) return 0;
    return parseInt(value.toString().replace(/,/g, '')) || 0;
  },

  // 문자열 트림 및 빈 문자열 처리
  cleanString: (value) => {
    return value ? value.toString().trim() : '';
  },

  // 필수 여부 변환
  parseRequired: (value) => {
    return value?.toLowerCase() === 'yes';
  },

  cleanSize: (value) => {
    if (!value) return '전체';
    return value.includes('평') ? value : `${value}평`;
  },
  
  cleanGrade: (value) => {
    if (!value) return '전체';
    return ['하', '중', '상'].includes(value) ? value : '전체';
  }
};

// CSV 파일 업로드 모달 컴포넌트 수정
const CSVUploadModal = ({ onClose, onUpload, showMessageWithTimeout }) => {
  const [uploading, setUploading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleFileProcess(files[0]);
    }
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileProcess(file);
    }
  };

  const handleFileProcess = (file) => {
    if (!file) return;
    setUploading(true);
    
    console.log('📁 파일 처리 시작:', file.name);
    
    Papa.parse(file, {
      header: true,
      encoding: "UTF-8",
      complete: async (results) => {
        try {
          console.log('📊 CSV 파싱 결과:', {
            총행수: results.data.length,
            샘플데이터: results.data.slice(0, 2)
          });

          // 데이터 처리 및 구조화
          const processedData = results.data.reduce((acc, row, index) => {
            console.log(`\n🔄 행 ${index + 1} 처리 중:`, {
              공정: row['00 공정'],
              품명: row['02 품명']
            });
            
            const processed = processCSVRow(row);
            if (processed) {
              const { categoryId, item } = processed;
              
              if (!acc[categoryId]) {
                acc[categoryId] = {
                  prices: [],
                  processOrder: item.processOrder
                };
                console.log(`📌 새 카테고리 생성: ${categoryId}`);
              }
              
              acc[categoryId].prices.push(item);
              console.log(`➕ 항목 추가됨: ${categoryId} - ${item.name}`);
            }
            return acc;
          }, {});

          console.log('\n📝 처리된 카테고리:', Object.keys(processedData));
          console.log('📊 카테고리별 항목 수:', Object.entries(processedData).map(([id, data]) => ({
            id,
            items: data.prices.length
          })));

          // Firestore에 저장
          for (const [categoryId, data] of Object.entries(processedData)) {
            try {
              const docRef = doc(db, 'categoryPrices', categoryId);
              await setDoc(docRef, {
                prices: data.prices,
                processOrder: data.processOrder,
                updatedAt: new Date()
              });
              console.log(`✅ ${categoryId} 저장 완료:`, {
                항목수: data.prices.length,
                processOrder: data.processOrder
              });
            } catch (error) {
              console.error(`❌ ${categoryId} 저장 실패:`, error);
            }
          }

          showMessageWithTimeout('모든 데이터가 성공적으로 저장되었습니다.');
          onUpload();

        } catch (error) {
          console.error('❌ 처리 중 오류:', error);
          showMessageWithTimeout('데이터 처리 중 오류가 발생했습니다.');
        } finally {
          setUploading(false);
        }
      },
      error: (error) => {
        console.error('❌ CSV 파싱 오류:', error);
        showMessageWithTimeout('CSV 파일 형식이 올바르지 않습니다.');
        setUploading(false);
      }
    });
  };

  // 모든 카테고리 가격 데이터 삭제 함수
  const handleDeleteAllData = async () => {
    try {
      // 각 카테고리에 대해 문서 자체를 삭제
      for (const category of VENDOR_CATEGORIES) {
        const docRef = doc(db, 'categoryPrices', category.id);
        await deleteDoc(docRef); // setDoc 대신 deleteDoc 사용
      }
      showMessageWithTimeout('모든 카테고리의 가격 데이터가 삭제되었습니다.');
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('데이터 삭제 중 오류:', error);
      showMessageWithTimeout('데이터 삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="csv-upload-modal" onClick={e => e.stopPropagation()}>
        <h2>CSV 파일 업로드</h2>
        <p className="upload-description">
          CSV 파일을 업로드하면 데이터가 자동으로 처리됩니다.
        </p>
        <div 
          className={`upload-container ${isDragging ? 'dragging' : ''}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="upload-content">
            <i className="upload-icon">📁</i>
            <p>CSV 파일을 여기에 드래그하거나</p>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileInput}
              disabled={uploading}
              className="file-input"
            />
            <p className="upload-text">클릭하여 선택하세요</p>
          </div>
          {uploading && <div className="loading-spinner">처리중...</div>}
        </div>

        {/* 삭제 섹션 추가 */}
        <div className="delete-container">
          {!showDeleteConfirm ? (
            <button 
              onClick={() => setShowDeleteConfirm(true)}
              className="delete-all-button"
            >
              모든 카테고리 가격 데이터 삭제
            </button>
          ) : (
            <>
              <p className="delete-confirm-message">
                정말로 모든 카테고리의 가격 데이터를 삭제하시겠습니까?
                이 작업은 되돌릴 수 없습니다.
              </p>
              <div className="delete-confirm-buttons">
                <button 
                  onClick={handleDeleteAllData}
                  className="delete-confirm-button"
                >
                  삭제 확인
                </button>
                <button 
                  onClick={() => setShowDeleteConfirm(false)}
                  className="delete-cancel-button"
                >
                  취소
                </button>
              </div>
            </>
          )}
        </div>

        <button onClick={onClose} className="close-button">
          닫기
        </button>
      </div>
    </div>
  );
};

// 카테고리 가격 입력 모달 컴포넌트 수정
const CategoryPriceModal = ({ category, onClose, onSave, onView, showMessageWithTimeout, initialItem, isEditing }) => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState(initialItem || {
    size: '',
    name: '',
    quantity: '',
    unit: '',
    unitPrice: '',
    totalPrice: '',
    grade: '',
    option: '',
    status: '',
    note: ''
  });
  const [csvError, setCsvError] = useState('');

  const handleInputChange = (field, value) => {
    setNewItem(prev => {
      const updated = { ...prev };

      switch (field) {
        case 'size':
          // 평형: 숫자만 입력 가능
          const sizeNum = value.replace(/[^0-9]/g, '');
          updated.size = sizeNum;
          break;

        case 'quantity':
          // 수량: 숫자만 입력 가
          const quantityNum = value.replace(/[^0-9]/g, '');
          updated.quantity = quantityNum;
          break;

        case 'unitPrice':
          // 단가: 숫자만 입력 가능, 1000단위 콤마
          const unitPriceNum = value.replace(/[^0-9]/g, '');
          updated.unitPrice = unitPriceNum;
          // 콤마가 있는 형식으로 표시
          if (unitPriceNum) {
            updated.unitPriceDisplay = parseInt(unitPriceNum).toLocaleString();
          }
          break;

        default:
          updated[field] = value;
      }

      // 수량과 단가가 있을 때 자동으로 금액 계산
      if ((field === 'quantity' || field === 'unitPrice') && updated.quantity && updated.unitPrice) {
        const total = parseInt(updated.quantity) * parseInt(updated.unitPrice);
        updated.totalPrice = total.toString();
        updated.totalPriceDisplay = total.toLocaleString();
      }

      return updated;
    });
  };

  const handleSave = async () => {
    try {
      const itemToSave = {
        size: newItem.size ? parseInt(newItem.size) : '',
        name: newItem.name,
        quantity: newItem.quantity ? parseInt(newItem.quantity) : '',
        unit: newItem.unit,
        unitPrice: newItem.unitPrice ? parseInt(newItem.unitPrice) : '',
        totalPrice: newItem.totalPrice ? parseInt(newItem.totalPrice) : '',
        note: newItem.note
      };

      if (isEditing) {
        // 수정 모드일 경우 바로 onSave 호출
        await onSave(itemToSave);
      } else {
        // 새로운 항목 추가 모드
        const docRef = doc(db, 'categoryPrices', category.id);
        const docSnap = await getDoc(docRef);
        const existingPrices = docSnap.exists() ? docSnap.data().prices || [] : [];
        const updatedPrices = [...existingPrices, itemToSave];
        await onSave(category.id, updatedPrices);
        
        setNewItem({
          size: '',
          name: '',
          quantity: '',
          unit: '',
          unitPrice: '',
          totalPrice: '',
          grade: '',
          option: '',
          status: '',
          note: ''
        });
      }
      
      showMessageWithTimeout(isEditing ? '수정었습니다.' : '저장되었습니다.');
    } catch (error) {
      console.error('Error saving/updating item:', error);
      showMessageWithTimeout(isEditing ? '수정에 실패했습니다.' : '저장에 실패했습니다.');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          try {
            // 한글 필드명을 영문 필드명으로 매핑
            const fieldMapping = {
              '평': 'size',
              '품명': 'name',
              '수량': 'quantity',
              '단위': 'unit',
              '단가': 'unitPrice',
              '금액': 'totalPrice',
              '비고': 'note'
            };

            const validatedData = results.data
              .filter(item => {
                // 한글 필드명으로도 체크
                return (item.name || item['품명']) && 
                       (item.quantity || item['수량']) && 
                       (item.unitPrice || item['단가']);
              })
              .map(item => {
                // 한글 필드명을 영문 필드명으 변환
                const mappedItem = {};
                Object.entries(fieldMapping).forEach(([korKey, engKey]) => {
                  mappedItem[engKey] = item[engKey] || item[korKey] || '';
                });

                return {
                  size: mappedItem.size ? parseInt(mappedItem.size) : '',
                  name: mappedItem.name,
                  quantity: parseInt(mappedItem.quantity),
                  unit: mappedItem.unit || '',
                  unitPrice: parseInt(mappedItem.unitPrice),
                  totalPrice: (parseInt(mappedItem.quantity) * parseInt(mappedItem.unitPrice)).toString(),
                  note: mappedItem.note || ''
                };
              });

            // 현재 카테고리의 기존 데이터를 져옵니다
            const docRef = doc(db, 'categoryPrices', category.id);
            const docSnap = await getDoc(docRef);
            
            // 기존 데이터와 새로운 데이터를 합칩니다
            const existingPrices = docSnap.exists() ? docSnap.data().prices || [] : [];
            const updatedPrices = [...existingPrices, ...validatedData];
            
            // 합쳐진 데이터를 저장합니다
            await onSave(category.id, updatedPrices);
            
            setCsvError('');
            showMessageWithTimeout('CSV 파일이 성공적으로 업로드되었습니다.');
            
            // 파일 입력 초기화
            event.target.value = '';
          } catch (error) {
            console.error('Error processing CSV:', error);
            setCsvError('CSV 파일 처리 중 오류가 발생했니다.');
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          setCsvError('CSV 파일 형식이 올바르지 않습니다.');
        }
      });
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="price-modal" onClick={e => e.stopPropagation()}>
        <h2>{isEditing ? `${category.name} 가격 수정` : `${category.name} 가격 설정`}</h2>
        
        {!isEditing && (
          <div className="csv-upload">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="csv-input"
            />
            {csvError && <p className="csv-error">{csvError}</p>}
          </div>
        )}

        <div className="price-input-form">
          <div className="input-row">
            <label>평형</label>
            <input
              type="text"
              value={newItem.size}
              onChange={(e) => handleInputChange('size', e.target.value)}
              placeholder="숫자만 입력"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>품명</label>
            <input
              type="text"
              value={newItem.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              placeholder="품명"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>수량</label>
            <input
              type="text"
              value={newItem.quantity}
              onChange={(e) => handleInputChange('quantity', e.target.value)}
              placeholder="숫자만 입력"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>단위</label>
            <input
              type="text"
              value={newItem.unit}
              onChange={(e) => handleInputChange('unit', e.target.value)}
              placeholder="단위"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>단가</label>
            <input
              type="text"
              value={newItem.unitPriceDisplay || newItem.unitPrice}
              onChange={(e) => handleInputChange('unitPrice', e.target.value)}
              placeholder="숫자만 입력"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>금액</label>
            <input
              type="text"
              value={newItem.totalPriceDisplay || newItem.totalPrice}
              readOnly
              placeholder="자동계산"
              className="form-input readonly"
            />
          </div>

          <div className="input-row">
            <label>비고</label>
            <input
              type="text"
              value={newItem.note}
              onChange={(e) => handleInputChange('note', e.target.value)}
              placeholder="비고"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>서비스등급</label>
            <select
              value={newItem.grade}
              onChange={(e) => handleInputChange('grade', e.target.value)}
              className="form-select"
            >
              <option value="">선택세요</option>
              <option value="하">하</option>
              <option value="중">중</option>
              <option value="상">상</option>
            </select>
          </div>

          <div className="input-row">
            <label>옵션사항</label>
            <input
              type="text"
              value={newItem.option}
              onChange={(e) => handleInputChange('option', e.target.value)}
              placeholder="옵션사항 입력"
              className="form-input"
            />
          </div>

          <div className="input-row">
            <label>상태</label>
            <select
              value={newItem.status}
              onChange={(e) => handleInputChange('status', e.target.value)}
              className="form-select"
            >
              <option value="">선택하세요</option>
              <option value="별도">별도</option>
              <option value="서비스">서비스</option>
              <option value="미정">미정</option>
            </select>
          </div>
        </div>

        <div className="modal-buttons">
          <button onClick={handleSave} className="save-button">
            {isEditing ? '수정' : '저장'}
          </button>
          <button onClick={onClose} className="cancel-button">
            취소
          </button>
        </div>

        {!isEditing && (
          <div className="view-button-container">
            <button onClick={onView} className="view-button">
              조회하기
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// 조회 모달 컴포넌트 추가
const ViewPriceModal = ({ category, onClose, onEdit, onDelete }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  // 데이터 로드 함수를 useCallback 밖으로 분리
  const loadCategoryPrices = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, 'categoryPrices', category.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log('Loaded data:', data); // 데이터 확인용 로그
        setItems(data.prices || []);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error('가격 정보를 불러오는데 실패했습니다:', error);
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    loadCategoryPrices();
  }, [category.id]);

  // 삭제 핸들러 수정
  const handleDelete = async (index) => {
    try {
      const docRef = doc(db, 'categoryPrices', category.id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const currentPrices = docSnap.data().prices || [];
        const newPrices = currentPrices.filter((_, i) => i !== index);
        
        await setDoc(docRef, {
          prices: newPrices,
          updatedAt: new Date()
        });
        
        // 삭제 후 데이터 다시 로드
        await loadCategoryPrices();
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // CSV 데이터 준비
  const headers = [
    { label: '평형', key: 'size' },
    { label: '품명', key: 'name' },
    { label: '수량', key: 'quantity' },
    { label: '단위', key: 'unit' },
    { label: '단가', key: 'unitPrice' },
    { label: '금액', key: 'totalPrice' },
    { label: '고', key: 'note' }
  ];

  const csvData = items.map(item => ({
    ...item,
    unitPrice: parseInt(item.unitPrice).toString(),
    totalPrice: parseInt(item.totalPrice).toString()
  }));

  const filename = `${category.name}_가격표_${new Date().toLocaleDateString()}.csv`;

  // 로딩 상태 표시
  if (loading) {
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="price-modal" onClick={e => e.stopPropagation()}>
          <h2>데이터 로딩중...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="price-modal" onClick={e => e.stopPropagation()}>
        <h2>{category.name} 가격 목록</h2>
        
        <div className="csv-buttons">
          <CSVLink 
            data={csvData}
            headers={headers}
            filename={filename}
            className="csv-button"
          >
            CSV 내보내기
          </CSVLink>
        </div>

        <div className="items-list">
          {items.length === 0 ? (
            <p className="no-data-message">등록된 가격 정보가 없습니다.</p>
          ) : (
            items.map((item, index) => (
              <div key={index} className="item-row">
                <div className="item-details">
                  <div className="item-header">
                    <div className="item-actions">
                      <button 
                        onClick={() => onEdit(index)}
                        className="edit-button"
                      >
                        수정
                      </button>
                      <button 
                        onClick={() => handleDelete(index)}
                        className="delete-item-button"
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                  <div className="item-fields">
                    <div className="field-row">
                      <span className="field-label">평형:</span>
                      <span className="field-value">{item.size}</span>
                    </div>
                    <div className="field-row">
                      <span className="field-label">품명:</span>
                      <span className="field-value">{item.name}</span>
                    </div>
                    <div className="field-row">
                      <span className="field-label">수량:</span>
                      <span className="field-value">{item.quantity} {item.unit}</span>
                    </div>
                    <div className="field-row">
                      <span className="field-label">단가:</span>
                      <span className="field-value">{parseInt(item.unitPrice).toLocaleString()} 원</span>
                    </div>
                    <div className="field-row">
                      <span className="field-label">금액:</span>
                      <span className="field-value">{parseInt(item.totalPrice).toLocaleString()} 원</span>
                    </div>
                    {item.note && (
                      <div className="field-row">
                        <span className="field-label">비고:</span>
                        <span className="field-value">{item.note}</span>
                      </div>
                    )}
                    {item.grade && (
                      <div className="field-row">
                        <span className="field-label">서비스등급:</span>
                        <span className="field-value">{item.grade}</span>
                      </div>
                    )}
                    {item.option && (
                      <div className="field-row">
                        <span className="field-label">옵션사항:</span>
                        <span className="field-value">{item.option}</span>
                      </div>
                    )}
                    {item.status && (
                      <div className="field-row">
                        <span className="field-label">상태:</span>
                        <span className="field-value">{item.status}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="modal-buttons">
          <button onClick={onClose} className="cancel-button">
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

// 선택 모달 컴포넌트 추가
const SelectActionModal = ({ category, onClose, onInput, onView }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="select-modal" onClick={e => e.stopPropagation()}>
        <h2>{category.name}</h2>
        <div className="modal-buttons">
          <button onClick={onInput} className="save-button">
            입력하기
          </button>
          <button onClick={onView} className="view-button">
            조회하기
          </button>
          <button onClick={onClose} className="cancel-button">
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

// AI 프롬프트 모달 컴포넌트 추가
const AIPromptModal = ({ onClose, showMessageWithTimeout }) => {
  const [prompt, setPrompt] = useState('');
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState(null);

  // ESC 키 이벤트 핸들러 추가
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  // handleSubmit 함수 추가
  const handleSubmit = async () => {
    if (!prompt.trim()) {
      showMessageWithTimeout('프롬프트를 입력해주세요.');
      return;
    }

    setProcessing(true);
    try {
      // 1. 모든 카테고리의 데이터를 가져옵니다
      const allCategoryData = {};
      for (const category of VENDOR_CATEGORIES) {
        const docRef = doc(db, 'categoryPrices', category.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          allCategoryData[category.id] = {
            name: category.name,
            ...docSnap.data()
          };
        }
      }

      console.log('AI에 전달할 데이터:', allCategoryData);

      // 2. AI에 롬프트와 데이터를 전송
      const result = await processWithAI('gemini', prompt, allCategoryData);
      
      // 3. AI 응답 처리
      if (result?.candidates?.[0]?.content?.parts?.[0]?.text) {
        try {
          // JSON 형식이면 파싱
          const parsedResult = JSON.parse(result.candidates[0].content.parts[0].text);
          setResult(parsedResult);
          
          // 수정된 데이터가 있으면 Firestore에 장
          if (parsedResult.updatedData) {
            for (const [categoryId, data] of Object.entries(parsedResult.updatedData)) {
              await setDoc(doc(db, 'categoryPrices', categoryId), data);
            }
            showMessageWithTimeout('데이터가 성공적으로 업데이트되었습니다.');
          }
        } catch {
          // 일반 텍스트면 그대로 표시
          setResult(result.candidates[0].content.parts[0].text);
        }
      }

    } catch (error) {
      console.error('AI 처리 중 오류:', error);
      showMessageWithTimeout('AI 처리 중 오류가 발생했습니다.');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="ai-prompt-modal" onClick={e => e.stopPropagation()}>
        <h2>AI 프롬프트</h2>

        <div className="prompt-input-container">
          <div className="prompt-guide">
            <p>AI에게 다음과 같은 명령을 내릴 수 있습니다:</p>
            <ul>
              <li>"디자인 카테고리의 모든 데이터에서 평형대가 비어있는 항목을 아 '전체'로 변해주세요."</li>
              <li>"철거폐기물 카테고리의 데이터를 평형대별로 분류하고 평균 가격을 계산해주세요."</li>
              <li>"목공사 카테고리에서 서비스등급이 '상'인 항목들의 총 금액을 계산해주세요."</li>
              <li>"모든 카테고리에서 status가 '별도'인 항목들을 찾아서 보여주세요."</li>
            </ul>
          </div>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="위 예시처럼 원하는 작업을 자연어로 입력하세."
            className="prompt-input"
            disabled={processing}
          />
          <button 
            onClick={handleSubmit}
            className="submit-button"
            disabled={processing}
          >
            {processing ? '처리중...' : '실행'}
          </button>
        </div>

        {result && (
          <div className="result-container">
            <h3>결과</h3>
            <pre className="result-text">
              {JSON.stringify(result, null, 2)}
            </pre>
          </div>
        )}

        <button onClick={onClose} className="close-button">
          닫기
        </button>
      </div>
    </div>
  );
};

// processCSVRow 함수 수정
const processCSVRow = (row) => {
  // 필수 필드 검증
  if (!row['00 공정'] || !row['02 품명']) {
    console.log('❌ 필수 필드 누락:', row);
    return null;
  }

  // CSV 컬럼 매핑 정의
  const columnMapping = {
    '00공정순서': 'processOrder',
    '00 공정': 'category',
    '01 품명번호': 'itemNumber',
    '02 품명': 'name',
    '03 수량': 'quantity',
    '04 단위': 'unit',
    '05 단가': 'unitPrice',
    '06 금액': 'totalPrice',
    '07 필수여부': 'required',
    '08 전체적용': 'applyAll',
    '09 평형대': 'size',
    '10 서비스등급': 'grade',
    '11 옵션사항': 'option',
    '12 별도/서비스/미정': 'status',
    '13 비고': 'note'
  };

  // '00.1 현장넘버' 컬럼은 매핑에서 제외됨

  const categoryName = row['00 공정'].trim();
  const categoryId = CATEGORY_MAPPING[categoryName];
  const processOrder = cleanupUtils.parseNumber(row['00공정순서']);

  console.log('🔍 카테고리 매핑 시도:', {
    원본카테고리: categoryName,
    매핑ID: categoryId,
    공정순서: processOrder,
    품명: row['02 품명']
  });

  if (!categoryId) {
    console.warn(`⚠️ 알 수 없는 카테고리:`, {
      카테고리명: categoryName,
      가능한카테고리: Object.keys(CATEGORY_MAPPING)
    });
    return null;
  }

  // 매핑된 데이터만 사용하여 item 객체 생성
  const item = {
    id: `${processOrder}_${categoryId}`,
    name: cleanupUtils.cleanString(row['02 품명']),
    quantity: cleanupUtils.parseNumber(row['03 수량']),
    unit: cleanupUtils.cleanString(row['04 단위']),
    unitPrice: cleanupUtils.parseNumber(row['05 단가']),
    totalPrice: cleanupUtils.parseNumber(row['06 금액']),
    size: cleanupUtils.cleanSize(row['09 평형대']),
    grade: cleanupUtils.cleanGrade(row['10 서비스등급']),
    option: cleanupUtils.cleanString(row['11 옵션사항']),
    status: cleanupUtils.cleanString(row['12 별도/서비스/미정']),
    note: cleanupUtils.cleanString(row['13 비고']),
    required: cleanupUtils.parseRequired(row['07 필수여부']),
    processOrder: processOrder
  };

  console.log('✅ 처리된 아이템:', {
    categoryId,
    id: item.id,
    name: item.name,
    processOrder: item.processOrder
  });

  return { categoryId, item };
};

const VendorPrice = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showMessage, setShowMessage] = useState('');
  const [modalType, setModalType] = useState(null);
  const [categoryItems, setCategoryItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAIPromptModal, setShowAIPromptModal] = useState(false);

  useEffect(() => {
    if (selectedCategory) {
      loadCategoryPrices(selectedCategory.id);
    }
  }, [selectedCategory]);

  const loadCategoryPrices = async (categoryId) => {
    try {
      const docRef = doc(db, 'categoryPrices', categoryId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCategoryItems(data.prices || []);
      } else {
        setCategoryItems([]);
      }
    } catch (error) {
      console.error('Error loading prices:', error);
      showMessageWithTimeout('가격 정보를 불러오는데 실패했습니다.');
    }
  };

  const showMessageWithTimeout = (message) => {
    setShowMessage(message);
    setTimeout(() => setShowMessage(''), 3000);
  };

  // 카테고리 클릭 핸들러
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setModalType('select');
  };

  const handleInputSelect = () => {
    setModalType('input');
  };

  const handleViewSelect = () => {
    loadCategoryPrices(selectedCategory.id);
    setModalType('view');
  };

  const handleEditItem = (index) => {
    const itemToEdit = categoryItems[index];
    setEditingItem({ ...itemToEdit, index });
    setModalType('edit');
  };

  const handleDeleteItem = async (index) => {
    if (window.confirm('이 항목을 삭제하시겠습니까?')) {
      try {
        const docRef = doc(db, 'categoryPrices', selectedCategory.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const currentPrices = docSnap.data().prices || [];
          const newPrices = currentPrices.filter((_, i) => i !== index);
          
          await setDoc(docRef, {
            prices: newPrices,
            updatedAt: new Date()
          });
          
          // 현재 보여지는 목록 업데이트
          setCategoryItems(newPrices);
          showMessageWithTimeout('항목이 삭제되었습니다.');
        }
      } catch (error) {
        console.error('Error deleting item:', error);
        showMessageWithTimeout('삭제에 실패했습니다.');
      }
    }
  };

  const closeModal = () => {
    setModalType(null);
    setSelectedCategory(null);
  };

  // 카테고리 가격 저장 함수
  const handleSaveCategoryPrices = async (categoryId, prices) => {
    try {
      // 기존 문서의 order 값을 유지
      const docRef = doc(db, 'categoryPrices', categoryId);
      const docSnap = await getDoc(docRef);
      const existingOrder = docSnap.exists() ? docSnap.data().order : null;

      await setDoc(doc(db, 'categoryPrices', categoryId), {
        prices,
        order: existingOrder, // 기존 order 값 유지
        updatedAt: new Date()
      });
      showMessageWithTimeout('가격이 저장되었습니다.');
    } catch (error) {
      console.error('Error saving category prices:', error);
      showMessageWithTimeout('가격 저장에 실패했습니다.');
    }
  };

  // 수정 완료 핸들러 추가
  const handleUpdateItem = async (updatedItem) => {
    try {
      const docRef = doc(db, 'categoryPrices', selectedCategory.id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const currentPrices = docSnap.data().prices || [];
        const newPrices = [...currentPrices];
        newPrices[editingItem.index] = updatedItem;  // 해당 인덱스의 아이템 업데이트
        
        await setDoc(docRef, {
          prices: newPrices,
          updatedAt: new Date()
        });
        
        setCategoryItems(newPrices);
        setEditingItem(null);  // 수정 모드 종료
        setModalType('view');  // 조회 모드로 돌아가기
        showMessageWithTimeout('수이 완료었습니다.');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      showMessageWithTimeout('수정에 실패했습니다.');
    }
  };

  // CSV 업로드 버튼 추가
  const renderHeader = () => (
    <div className="vendor-price-header">
      <h1>거래처 가격입력</h1>
      <div className="header-buttons">
        <button 
          onClick={() => setShowAIPromptModal(true)}
          className="ai-prompt-button"
        >
          AI 프롬프트
        </button>
        <button 
          onClick={() => setShowUploadModal(true)}
          className="csv-upload-button"
        >
          CSV 일괄 업로드
        </button>
      </div>
    </div>
  );

  // ESC 키 핸들러 수정
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        
        // 모달이 열려있는 경우 모달 닫기
        if (showUploadModal) {
          setShowUploadModal(false);
        } else if (showAIPromptModal) {
          setShowAIPromptModal(false);
        } else if (modalType) {
          closeModal();
        } else {
          // 모달이 모두 닫혀있는 경우 메인 페이지로 동
          navigate('/');
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [modalType, showUploadModal, showAIPromptModal, navigate]);

  return (
    <div className="vendor-price-container">
      {renderHeader()}
      
      <div className="category-grid">
        {VENDOR_CATEGORIES.map(category => (
          <div
            key={category.id}
            className="category-item"
            onClick={() => handleCategoryClick(category)}
          >
            <span className="category-icon">{category.icon}</span>
            <span className="category-name">{category.name}</span>
          </div>
        ))}
      </div>

      {/* 택 모달 */}
      {modalType === 'select' && selectedCategory && (
        <SelectActionModal
          category={selectedCategory}
          onClose={closeModal}
          onInput={handleInputSelect}
          onView={handleViewSelect}
        />
      )}

      {/* 입력 모달 */}
      {modalType === 'input' && selectedCategory && (
        <CategoryPriceModal
          category={selectedCategory}
          onClose={closeModal}
          onSave={handleSaveCategoryPrices}
          onView={() => {
            loadCategoryPrices(selectedCategory.id);
            setModalType('view');
          }}
          showMessageWithTimeout={showMessageWithTimeout}
        />
      )}

      {/* 조회 모달 */}
      {modalType === 'view' && selectedCategory && (
        <ViewPriceModal
          category={selectedCategory}
          onClose={closeModal}
          onEdit={handleEditItem}
          onDelete={handleDeleteItem}
        />
      )}

      {/* 수정 모달 추가 */}
      {modalType === 'edit' && selectedCategory && editingItem && (
        <CategoryPriceModal
          category={selectedCategory}
          onClose={() => {
            setEditingItem(null);
            setModalType('view');
          }}
          onSave={handleUpdateItem}
          initialItem={editingItem}
          isEditing={true}
          showMessageWithTimeout={showMessageWithTimeout}
        />
      )}

      {showUploadModal && (
        <CSVUploadModal
          onClose={() => setShowUploadModal(false)}
          onUpload={() => {
            setShowUploadModal(false);
            // 필요한 경우 데이터 새로고침
          }}
          showMessageWithTimeout={showMessageWithTimeout}
        />
      )}

      {showAIPromptModal && (
        <AIPromptModal
          onClose={() => setShowAIPromptModal(false)}
          showMessageWithTimeout={showMessageWithTimeout}
        />
      )}

      {showMessage && (
        <div className="message-popup">
          {showMessage}
        </div>
      )}
    </div>
  );
};

export default VendorPrice; 