import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, setDoc, deleteDoc, doc, updateDoc, query, orderBy, getDoc } from 'firebase/firestore';

// 모달 컴포넌트 수정
const Modal = ({ message, onClose, onConfirm, showCancelButton }) => (
  <div className="modal-overlay">
    <div className="modal-content">
      <p>{message}</p>
      <div className="modal-buttons">
        {showCancelButton ? (
          <>
            <button onClick={onConfirm}>예</button>
            <button onClick={onClose}>아니오</button>
          </>
        ) : (
          <button onClick={onClose}>확인</button>
        )}
      </div>
    </div>
  </div>
);

const AddressManagement = () => {
  const [addresses, setAddresses] = useState([]);
  const [adminPassword, setAdminPassword] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingSiteNumber, setEditingSiteNumber] = useState(null);
  const [tempSiteNumber, setTempSiteNumber] = useState('');

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    const addressesCollection = collection(db, 'addresses');
    const q = query(addressesCollection, orderBy('createdAt', 'desc'));
    const addressesSnapshot = await getDocs(q);
    const addressesList = addressesSnapshot.docs.map((doc, index) => ({
      id: doc.id,
      ...doc.data(),
      siteNumber: doc.data().siteNumber || addressesSnapshot.size - index
    }));
    setAddresses(addressesList);
  };

  const deleteAddress = async (id) => {
    setDeleteId(id);
    setModalMessage('정말로 삭제하시겠습니까?');
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (deleteId) {
      try {
        // 1. addresses 컬렉션에서 해당 문서 정보 가져오기
        const addressDoc = await getDoc(doc(db, 'addresses', deleteId));
        const addressData = addressDoc.data();

        // 2. 주소 문서 삭제
        await deleteDoc(doc(db, 'addresses', deleteId));

        // 3. estimateModifications 문서 삭제 (description을 문서 ID로 사용)
        await deleteDoc(doc(db, 'estimateModifications', addressData.description));

        // 4. payments 문서 삭제
        await deleteDoc(doc(db, 'payments', deleteId));

        fetchAddresses();
        setModalMessage('삭제되었습니다.');
        setShowModal(true);
        setDeleteId(null);
      } catch (error) {
        console.error("Error deleting documents: ", error);
        setModalMessage('삭제 중 오류가 발생했습니다.');
        setShowModal(true);
      }
    }
  };

  const handleAdminLogin = () => {
    if (adminPassword === '1234') {
      setIsAdmin(true);
      setModalMessage('관리자 모드로 전환되었습니다.');
      setShowModal(true);
    } else {
      setModalMessage('비밀번호가 올바르지 않습니다.');
      setShowModal(true);
    }
  };

  const handleAddAddress = async () => {
    if (newAddress.trim()) {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      
      // 가장 큰 현장 번호 찾기
      let maxSiteNumber = 0;
      addressesSnapshot.docs.forEach(doc => {
        const siteNum = doc.data().siteNumber;
        if (siteNum > maxSiteNumber) maxSiteNumber = siteNum;
      });
      
      const newSiteNumber = maxSiteNumber + 1;
      
      try {
        // 새로운 문서 ID 생성 (타임스탬프 사용)
        const newDocId = Date.now().toString();
        
        await setDoc(doc(db, 'addresses', newDocId), {
          address: newAddress.trim(),
          date: '',
          description: newAddress.trim(),
          firstFloorPassword: '',
          unitPassword: '',
          supervisorName: '',
          contractorContact: '',
          contractAmount: '',
          contractDate: '',
          phoneLastFourDigits: '',
          email: '',
          isCompleted: true,
          createdAt: new Date(),
          siteNumber: newSiteNumber
        });
        
        setNewAddress('');
        fetchAddresses();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const handleToggleConstruction = async (id, isCompleted) => {
    await updateDoc(doc(db, 'addresses', id), { isCompleted: !isCompleted });
    fetchAddresses();
  };

  const handleSaveAddress = async (id) => {
    await updateDoc(doc(db, 'addresses', id), addresses.find(addr => addr.id === id));
    setModalMessage('저장이 완료되었습니다.');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage('');
    setDeleteId(null);
  };

  const handleInputChange = (e, field, id = null) => {
    const value = e.target.value;
    if (id) {
      setAddresses(addresses.map(addr => 
        addr.id === id ? { ...addr, [field]: value } : addr
      ));
    } else {
      if (field === 'newAddress') {
        setNewAddress(value);
      } else if (field === 'adminPassword') {
        setAdminPassword(value);
      }
    }
  };

  // 현장 번호 수정 함수 추가
  const handleSiteNumberChange = async (address, newSiteNumber) => {
    try {
      // 1. 중복 체크
      const existingAddress = addresses.find(
        addr => addr.siteNumber === parseInt(newSiteNumber) && addr.id !== address.id
      );

      if (existingAddress) {
        setModalMessage('이미 사용 중인 현장 번호입니다.');
        setShowModal(true);
        return;
      }

      // 2. 현장 번호 업데이트
      await updateDoc(doc(db, 'addresses', address.id), {
        siteNumber: parseInt(newSiteNumber)
      });

      // 3. 데이터 다시 불러오기
      fetchAddresses();
      
      setModalMessage('현장 번호가 수정되었습니다.');
      setShowModal(true);
    } catch (error) {
      console.error("Error updating site number: ", error);
      setModalMessage('현장 번호 수정 중 오류가 발생했습니다.');
      setShowModal(true);
    }
  };

  // 수정 시작 함수
  const handleStartEditSiteNumber = (address) => {
    setEditingSiteNumber(address.id);
    setTempSiteNumber(address.siteNumber.toString());
  };

  // 수정 완료 함수
  const handleCompleteSiteNumber = async (address) => {
    if (tempSiteNumber === address.siteNumber.toString()) {
      setEditingSiteNumber(null);
      return;
    }

    try {
      await handleSiteNumberChange(address, tempSiteNumber);
    } finally {
      setEditingSiteNumber(null);
    }
  };

  return (
    <div className="address-management">
      <div className="header-space"></div>
      <h1>현장 주소 관리</h1>
      <div className="input-section">
        <div className="input-group">
          <input
            type="password"
            value={adminPassword}
            onChange={(e) => handleInputChange(e, 'adminPassword')}
            placeholder="관리자 비밀번호"
          />
          <button onClick={handleAdminLogin}>관리자 모드</button>
        </div>
        <div className="input-group">
          <input
            type="text"
            value={newAddress}
            onChange={(e) => handleInputChange(e, 'newAddress')}
            placeholder="현장 주소 입력"
          />
          <button onClick={handleAddAddress}>추가하기</button>
        </div>
      </div>
      <div className="site-info-list">
        {addresses.map((address) => (
          <div key={address.id} className="site-info-section">
            <h2 onClick={() => isAdmin && handleStartEditSiteNumber(address)}>
              {editingSiteNumber === address.id ? (
                <input
                  type="number"
                  value={tempSiteNumber}
                  onChange={(e) => setTempSiteNumber(e.target.value)}
                  onBlur={() => handleCompleteSiteNumber(address)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleCompleteSiteNumber(address);
                    } else if (e.key === 'Escape') {
                      setEditingSiteNumber(null);
                    }
                  }}
                  autoFocus
                  className="site-number-edit"
                />
              ) : (
                <>
                  현장 번호: {address.siteNumber}
                  {isAdmin && <span className="edit-hint"> (클릭하여 수정)</span>}
                </>
              )}
            </h2>
            <input
              type="text"
              value={address.date}
              onInput={(e) => handleInputChange(e, 'date', address.id)}
              placeholder="시작 날짜"
              style={{ caretColor: 'transparent' }}
            />
            <input
              type="text"
              value={address.description}
              onInput={(e) => handleInputChange(e, 'description', address.id)}
              placeholder="현장 주소"
              style={{ caretColor: 'transparent' }}
            />
            <input
              type="text"
              value={address.firstFloorPassword}
              onInput={(e) => handleInputChange(e, 'firstFloorPassword', address.id)}
              placeholder="1층 비번"
              style={{ caretColor: 'transparent' }}
            />
            <input
              type="text"
              value={address.unitPassword}
              onInput={(e) => handleInputChange(e, 'unitPassword', address.id)}
              placeholder="세대 비번"
              style={{ caretColor: 'transparent' }}
            />
            {isAdmin && (
              <>
                <input
                  type="text"
                  value={address.supervisorName}
                  onInput={(e) => handleInputChange(e, 'supervisorName', address.id)}
                  placeholder="계약자 성함"
                  style={{ caretColor: 'transparent' }}
                />
                <input
                  type="text"
                  value={address.contractorContact}
                  onInput={(e) => handleInputChange(e, 'contractorContact', address.id)}
                  placeholder="계약자 연락처"
                  style={{ caretColor: 'transparent' }}
                />
                <input
                  type="text"
                  value={address.contractAmount}
                  onInput={(e) => handleInputChange(e, 'contractAmount', address.id)}
                  placeholder="계약 금액"
                  style={{ caretColor: 'transparent' }}
                />
                <input
                  type="text"
                  value={address.contractDate}
                  onInput={(e) => handleInputChange(e, 'contractDate', address.id)}
                  placeholder="계약 날짜"
                  style={{ caretColor: 'transparent' }}
                />
                <input
                  type="text"
                  value={address.phoneLastFourDigits}
                  onInput={(e) => handleInputChange(e, 'phoneLastFourDigits', address.id)}
                  placeholder="전화번호 뒷자리"
                  style={{ caretColor: 'transparent' }}
                />
                <input
                  type="email"
                  value={address.email}
                  onInput={(e) => handleInputChange(e, 'email', address.id)}
                  placeholder="Email"
                  style={{ caretColor: 'transparent' }}
                />
                <button onClick={() => handleToggleConstruction(address.id, address.isCompleted)}>
                  {address.isCompleted ? '공사중' : '공사완료'}
                </button>
                <button onClick={() => handleSaveAddress(address.id)}>저장하기</button>
                <button onClick={() => deleteAddress(address.id)}>삭제</button>
              </>
            )}
          </div>
        ))}
      </div>
      {showModal && (
        <Modal 
          message={modalMessage} 
          onClose={closeModal} 
          onConfirm={deleteId ? confirmDelete : closeModal}
          showCancelButton={!!deleteId}
        />
      )}
    </div>
  );
};

export default AddressManagement;
