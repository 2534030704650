import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, deleteDoc, doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDGpgPwXTJXRp8BtNHHh_WlpBgFcQVXwxY",
  authDomain: "interior-one-click.firebaseapp.com",
  projectId: "interior-one-click",
  storageBucket: "interior-one-click.firebasestorage.app",
  messagingSenderId: "114412898136",
  appId: "1:114412898136:web:5f8b9b8f8b9b8f8b9b8f8b",
  measurementId: "G-XXXXXXXXXX"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const messaging = getMessaging(app);
const storage = getStorage(app);

export const saveThumbnailImage = async (imageDataUrl, timestamp) => {
  try {
    // base64 데이터를 Blob으로 변환
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();
    
    // 파일 크기 체크
    if (blob.size > 5 * 1024 * 1024) { // 5MB 제한
      throw new Error('이미지 크기가 너무 큽니다 (최대 5MB)');
    }

    // 파일 경로 수정
    const storageRef = ref(storage, `thumbnails/${timestamp}.png`); // .png 확장자 추가
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('이미지 저장 실패:', error);
    throw new Error(`이미지 저장 실패: ${error.message}`);
  }
};

const testAiConnection = async () => {
  // API 연결 테스트 로직
};

const saveApiKeys = async (keys) => {
  await setDoc(doc(db, 'settings', 'apiKeys'), keys);
};

const saveAccessToken = async (token) => {
  await setDoc(doc(db, 'settings', 'instagram'), { accessToken: token });
};

const getAccessToken = async () => {
  const docSnap = await getDoc(doc(db, 'settings', 'instagram'));
  return docSnap.exists() ? docSnap.data().accessToken : null;
};

const processWithAI = async (data) => {
  // AI 처리 로직
};

const getAreaPrices = async (area) => {
  const docSnap = await getDoc(doc(db, 'settings', 'areaPrices'));
  if (docSnap.exists()) {
    const data = docSnap.data();
    return data[area] || {};
  }
  return {};
};

const saveAreaPrices = async (area, prices) => {
  const docRef = doc(db, 'settings', 'areaPrices');
  const currentData = (await getDoc(docRef)).data() || {};
  
  await setDoc(docRef, {
    ...currentData,
    [area]: prices
  });
};

const saveProcesses = async (processes) => {
  try {
    await setDoc(doc(db, 'settings', 'processes'), {
      processList: processes
    });
  } catch (error) {
    console.error('Error saving processes:', error);
    throw error;
  }
};

const getProcesses = async () => {
  try {
    const docSnap = await getDoc(doc(db, 'settings', 'processes'));
    if (docSnap.exists()) {
      return docSnap.data().processList || [];
    }
    return [];
  } catch (error) {
    console.error('Error getting processes:', error);
    return [];
  }
};

const getEstimates = async () => {
  const querySnapshot = await getDocs(collection(db, 'estimates'));
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const deleteEstimates = async (ids) => {
  for (const id of ids) {
    await deleteDoc(doc(db, 'estimates', id));
  }
};

const saveTextLayers = async (timestamp, textLayers, imageDataUrl) => {
  try {
    if (!imageDataUrl) {
      throw new Error('이미지 데이터가 없습니다.');
    }

    const thumbnailRef = doc(db, 'thumbnails', timestamp);
    
    // 이미지 URL 저장 시도
    let imageUrl;
    try {
      imageUrl = await saveThumbnailImage(imageDataUrl, timestamp);
    } catch (error) {
      console.error('이미지 저장 중 오류:', error);
      throw new Error(`이미지 저장 실패: ${error.message}`);
    }
    
    // Firestore 문서 저장
    await setDoc(thumbnailRef, {
      id: timestamp,
      imageUrl,
      textLayers,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    console.error('텍스트 레이어 저장 실패:', error);
    throw new Error(`텍스트 레이어 저장 실패: ${error.message}`);
  }
};

const getTextLayers = async (imageId) => {
  try {
    const docSnap = await getDoc(doc(db, 'thumbnails', imageId));
    if (docSnap.exists()) {
      return docSnap.data().textLayers;
    }
    return [];
  } catch (error) {
    console.error('Error getting text layers:', error);
    return [];
  }
};

const getThumbnails = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'thumbnails'));
    const thumbnails = [];
    
    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      try {
        // 이미지 URL이 이미 저장되어 있으면 그대로 사용
        const imageUrl = data.imageUrl || await getDownloadURL(ref(storage, `thumbnails/${doc.id}.png`));
        
        thumbnails.push({
          id: doc.id,
          imageUrl,
          textLayers: data.textLayers || [],
          createdAt: data.createdAt
        });
      } catch (error) {
        console.error(`썸네일 ${doc.id} 이미지 URL 가져오기 실패:`, error);
      }
    }
    
    return thumbnails.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return b.createdAt.seconds - a.createdAt.seconds;
    });
  } catch (error) {
    console.error('썸네일 목록 가져오기 실패:', error);
    return [];
  }
};

const deleteThumbnail = async (thumbnailId) => {
  try {
    // Firestore 문서 삭제
    await deleteDoc(doc(db, 'thumbnails', thumbnailId));
    
    // Storage 파일 삭제
    try {
      const storageRef = ref(storage, `thumbnails/${thumbnailId}.png`);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('스토리지 파일 삭제 실패:', error);
    }
    
    return true;
  } catch (error) {
    console.error('썸네일 삭제 실패:', error);
    throw error;
  }
};

export {
  db,
  messaging,
  storage,
  testAiConnection,
  saveApiKeys,
  saveAccessToken,
  getAccessToken,
  processWithAI,
  getAreaPrices,
  saveAreaPrices,
  saveProcesses,
  getProcesses,
  getEstimates,
  deleteEstimates,
  saveTextLayers,
  getTextLayers,
  getThumbnails,
  deleteThumbnail
};
