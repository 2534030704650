import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, setDoc, updateDoc } from 'firebase/firestore';
import './DatabaseManager.css';

// 공정별 코드 매핑
const PROCESS_CODES = {
  demolition: 'DEMO',
  window: 'WIND',
  plumbing: 'PLUM',
  carpentry: 'CARP',
  door: 'DOOR',
  tile: 'TILE',
  film: 'FILM',
  floor_protection: 'FLPR',
  wood_flooring: 'WOOD',
  floor_tile: 'FLTL',
  painting: 'PAINT',
  wallpaper: 'WALL',
  bathroom: 'BATH',
  hardware: 'HARD',
  electrical: 'ELEC',
  furniture: 'FURN',
  silicon: 'SILC',
  ac: 'AIRC',
  supervision_utilities: 'SUPV',
  misc_additional: 'MISC'
};

const DatabaseManager = ({ onClose }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingCell, setEditingCell] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [log, setLog] = useState([]);

  useEffect(() => {
    fetchAllData();

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const fetchAllData = async () => {
    try {
      setLoading(true);
      const categoryPricesRef = collection(db, 'categoryPrices');
      const q = query(categoryPricesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setCategories(data);
    } catch (error) {
      console.error('데이터 로드 중 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    if (!number) return '';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const COLUMNS = [
    { id: 'uniqueId', label: '고유ID', editable: false },
    { id: 'name', label: '품명', editable: true },
    { id: 'quantity', label: '수량', editable: true, type: 'number' },
    { id: 'unit', label: '단위', editable: true },
    { id: 'unitPrice', label: '단가', editable: true, type: 'number', format: true },
    { id: 'totalPrice', label: '금액', editable: true, type: 'number', format: true },
    { id: 'size', label: '평형', editable: true },
    { id: 'grade', label: '등급', editable: true },
    { id: 'required', label: '필수', editable: true, type: 'boolean' },
    { id: 'option', label: '옵션', editable: true },
    { id: 'status', label: '상태', editable: true },
    { id: 'note', label: '비고', editable: true }
  ];

  const handleCellEdit = async (categoryId, itemIndex, field, value) => {
    try {
      const originalCategory = categories.find(c => c.id === categoryId);
      if (!originalCategory) return;

      const originalItem = originalCategory.prices[itemIndex];
      const realIndex = originalCategory.prices.findIndex(item => 
        JSON.stringify(item) === JSON.stringify(originalItem)
      );

      const updatedCategories = categories.map(c => {
        if (c.id === categoryId) {
          const updatedPrices = [...c.prices];
          const updatedItem = { ...updatedPrices[realIndex] };

          if (field === 'required') {
            updatedItem[field] = value === 'true';
          } else if (['quantity', 'unitPrice'].includes(field)) {
            const numValue = parseInt(value.replace(/,/g, '')) || 0;
            updatedItem[field] = numValue;
            updatedItem.totalPrice = updatedItem.quantity * updatedItem.unitPrice;
          } else {
            updatedItem[field] = value;
          }

          updatedPrices[realIndex] = updatedItem;
          return { ...c, prices: updatedPrices };
        }
        return c;
      });

      setCategories(updatedCategories);

      const updatedCategory = updatedCategories.find(c => c.id === categoryId);
      await setDoc(doc(db, 'categoryPrices', categoryId), {
        ...updatedCategory,
        updatedAt: new Date()
      });

    } catch (error) {
      console.error('데이터 수정 중 오류:', error);
    }
  };

  const filteredCategories = categories.map(category => {
    if (selectedCategory !== 'all' && category.id !== selectedCategory) {
      return null;
    }

    const filteredPrices = category.prices.filter(item => {
      if (!searchTerm) return true;

      const searchKey = searchTerm.toLowerCase();

      // 1. 고유 ID로 검색 (정확한 매칭)
      if (item.uniqueId && searchKey.startsWith('demo_') || 
          searchKey.startsWith('wind_') || 
          searchKey.startsWith('plum_') ||
          // ... 다른 공정 코드들
          searchKey.startsWith('misc_')) {
        return item.uniqueId.toLowerCase() === searchKey;
      }

      // 2. 고유 ID로 부분 검색
      if (item.uniqueId?.toLowerCase().includes(searchKey)) {
        return true;
      }

      // 3. 품명으로 검색
      if (item.name.toLowerCase().includes(searchKey)) {
        return true;
      }

      // 4. 정확한 매칭이 필요한 필드들
      const exactMatchFields = {
        name: '품명',
        size: '평형',
        grade: '등급',
        status: '상태',
        option: '옵션'
      };

      // 정확한 매칭 검사
      for (const [field, label] of Object.entries(exactMatchFields)) {
        if (item[field]?.toLowerCase() === searchKey) {
          return true;
        }
      }

      // 5. 부분 매칭이 허용되는 필드들
      const partialMatchFields = {
        note: '비고',
        unit: '단위'
      };

      // 부분 매칭 검사
      for (const [field, label] of Object.entries(partialMatchFields)) {
        if (item[field]?.toLowerCase().includes(searchKey)) {
          return true;
        }
      }

      // 6. 숫자 필드 검색
      if (['quantity', 'unitPrice', 'totalPrice'].some(field => {
        const numStr = item[field]?.toString();
        const numWithComma = item[field]?.toLocaleString();
        return numStr?.includes(searchKey) || numWithComma?.includes(searchKey);
      })) {
        return true;
      }

      // 7. 필수 여부 검색
      if (typeof item.required === 'boolean') {
        const boolStr = item.required ? '예' : '아니오';
        if (boolStr.includes(searchKey)) {
          return true;
        }
      }

      return false;
    });

    return filteredPrices.length > 0 ? {
      ...category,
      prices: filteredPrices
    } : null;
  }).filter(Boolean);

  const getProcessName = (processId) => {
    const processNames = {
      design: '디자인',
      demolition: '철거폐기물',
      window: '창호금속',
      plumbing: '배관 공사',
      carpentry: '목공사',
      door: '문공사',
      tile: '타일 공사',
      film: '필름공사',
      floor_protection: '바닥 보양 공사',
      wood_flooring: '마루 공사',
      floor_tile: '바닥 타일 공사',
      painting: '도장공사',
      wallpaper: '도배공사',
      bathroom: '욕실시공',
      hardware: '하드웨어, 악세서리',
      electrical: '전기공사',
      furniture: '가구공사',
      silicon: '실리콘 공사',
      ac: '에어컨공사',
      supervision_utilities: '현장감리/공과잡비',
      misc_additional: '기타잡비/별도공사'
    };
    return processNames[processId] || processId;
  };

  const formatValue = (value, column, item) => {
    if (!value && value !== 0) {
      return '';
    }

    if ((column.id === 'size' || column.id === 'grade') && (value === '전체' || value === '')) {
      return '';
    }

    if (column.type === 'boolean') {
      return item[column.id] ? '예' : '아니오';
    }
    
    if (column.format) {
      return formatNumber(item[column.id]);
    }
    
    return value;
  };

  const addLog = (message) => {
    setLog(prev => [...prev, `${new Date().toLocaleTimeString()}: ${message}`]);
  };

  const generateUniqueId = (processCode, index) => {
    const year = new Date().getFullYear().toString().slice(-2);
    const number = (index + 1).toString().padStart(3, '0');
    return `${processCode}_${number}_${year}`;
  };

  const migrateData = async () => {
    if (!window.confirm('기존 데이터를 새로운 구조로 변환하시겠습니까?')) return;
    
    setIsProcessing(true);
    setProgress(0);
    setLog([]);
    addLog('데이터 마이그레이션 시작');

    try {
      // 1. 기존 데이터 가져오기
      const categoryPricesRef = collection(db, 'categoryPrices');
      const q = query(categoryPricesRef, orderBy('processOrder', 'asc'));
      const querySnapshot = await getDocs(q);
      const totalDocs = querySnapshot.size;
      let currentDoc = 0;

      // 2. 각 공정별로 처리
      for (const docSnapshot of querySnapshot.docs) {
        const processId = docSnapshot.id;
        const processCode = PROCESS_CODES[processId];
        const data = docSnapshot.data();
        
        addLog(`${processId} 공정 처리 중...`);

        // 3. prices 배열의 각 항목에 uniqueId 추가
        if (data.prices && Array.isArray(data.prices)) {
          const updatedPrices = data.prices.map((item, index) => ({
            ...item,
            uniqueId: generateUniqueId(processCode, index)
          }));

          // 4. 업데이트된 데이터 저장
          const docRef = doc(db, 'categoryPrices', processId);
          await updateDoc(docRef, {
            prices: updatedPrices
          });

          addLog(`${processId}: ${updatedPrices.length}개 항목 업데이트 완료`);
        }

        currentDoc++;
        setProgress((currentDoc / totalDocs) * 100);
      }

      addLog('모든 데이터 마이그레이션 완료');
      alert('데이터 마이그레이션이 완료되었습니다.');

    } catch (error) {
      console.error('마이그레이션 오류:', error);
      addLog(`오류 발생: ${error.message}`);
      alert('마이그레이션 중 오류가 발생했습니다.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="database-manager">
      <div className="database-header">
        <h2>데이터베이스 관리</h2>
        <div className="header-controls">
          <select 
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="category-select"
          >
            <option value="all">전체 카테고리</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {getProcessName(cat.id)}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="검색..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <button 
            onClick={migrateData} 
            disabled={isProcessing}
            className="migrate-button"
          >
            데이터 마이그레이션
          </button>
          <button onClick={onClose} className="close-button">
            닫기
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loading">데이터 로딩 중...</div>
      ) : (
        <div className="database-content">
          {filteredCategories.map(category => (
            <div key={category.id} className="category-section">
              <h3>{getProcessName(category.id)}</h3>
              <table className="data-table">
                <thead>
                  <tr>
                    {COLUMNS.map(column => (
                      <th key={column.id}>{column.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {category.prices.map((item, index) => (
                    <tr key={index}>
                      {COLUMNS.map(column => (
                        <td 
                          key={column.id}
                          onClick={() => column.editable && setEditingCell({ 
                            categoryId: category.id, 
                            index, 
                            field: column.id 
                          })}
                          className={editingCell?.categoryId === category.id && 
                                    editingCell?.index === index && 
                                    editingCell?.field === column.id ? 'editing' : ''}
                        >
                          {editingCell?.categoryId === category.id && 
                           editingCell?.index === index && 
                           editingCell?.field === column.id ? (
                            column.type === 'boolean' ? (
                              <select
                                value={item[column.id]?.toString()}
                                onChange={(e) => {
                                  handleCellEdit(category.id, index, column.id, e.target.value);
                                }}
                                onBlur={() => setEditingCell(null)}
                                autoFocus
                              >
                                <option value="true">예</option>
                                <option value="false">아니오</option>
                              </select>
                            ) : (
                              <input
                                type={column.type || 'text'}
                                value={column.format ? formatNumber(item[column.id]) : (item[column.id] || '')}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  handleCellEdit(category.id, index, column.id, newValue);
                                }}
                                onBlur={() => setEditingCell(null)}
                                autoFocus
                              />
                            )
                          ) : (
                            formatValue(item[column.id], column, item)
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}

      {isProcessing && (
        <div className="progress-bar">
          <div 
            className="progress" 
            style={{ width: `${progress}%` }}
          />
          <span>{Math.round(progress)}%</span>
        </div>
      )}

      <div className="log-container">
        {log.map((message, index) => (
          <div key={index} className="log-entry">
            {message}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatabaseManager; 